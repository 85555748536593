import React, { useEffect } from 'react';
import { useStateMachine } from 'little-state-machine';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import cloneDeep from 'lodash/fp/cloneDeep';

import Grid from '@mui/material/Unstable_Grid2';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';

import { CellSticky, StyledTableCell } from './styled';

import {
  IExpertiseModulesWorksInfo,
  IModuleOfProduct,
  IWorkTypesOfProduct,
} from 'store/types/categoryProducts';

import updateAction from 'store/actions/updateAction';

import SeniorityLevel from 'components/SeniorityLevel';
import { Step } from 'react-joyride';

interface IModulesSettings {
  modules: IModuleOfProduct[];
  modulesWorks: IExpertiseModulesWorksInfo[];
  name: string;
  works: IWorkTypesOfProduct[] | null;
  setSteps?: React.Dispatch<React.SetStateAction<Step[]>>;
  setRun?: React.Dispatch<React.SetStateAction<boolean>>;
  showIntro?: boolean;
}

const ModulesSettings: React.FunctionComponent<IModulesSettings> = ({
  modules,
  modulesWorks,
  name,
  works,
  showIntro,
  setSteps,
  setRun,
}) => {
  const { actions } = useStateMachine({ updateAction });

  const { control, getValues } = useFormContext();
  const { replace } = useFieldArray({
    control,
    name: name,
    keyName: '_id',
  });

  useEffect(() => {
    if (showIntro) {
      let stepsList: Step[] = [];
      stepsList.push({
        target: `.proficiency-matrix-input`,
        title: 'Proficiency Matrix',
        content:
          'What is your proficiency level? This provides a quick, visual representation of your abilities.',
        placement: 'right',
        disableBeacon: true,
      });
      stepsList.push({
        target: '.submit-button',
        title: 'Save',
        content: 'Click Save button to save your changes.',
        placement: 'right',
        disableBeacon: true,
      });
      setSteps && setSteps(stepsList);
      if (stepsList.length) {
        setRun && setRun(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showIntro]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Grid container spacing={4}>
          <TableContainer
            className="proficiency-matrix-input"
            sx={{ height: 'calc(100vh - 434px)' }}
          >
            <Table
              stickyHeader
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <CellSticky padding="normal" sx={{ width: 240, zIndex: 3 }}>
                    <Typography variant="h6">Module</Typography>
                  </CellSticky>
                  {works?.map(work => {
                    const selectedWorks = modulesWorks?.filter(i => i.workId === work.id);
                    return (
                      <TableCell
                        key={work.id}
                        align="center"
                        padding="normal"
                        sx={{
                          borderBottom: 'none',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Typography
                          variant="body1"
                          color={(selectedWorks.length && 'text.primary') || 'text.secondary'}
                        >
                          <b>{work.name}</b>
                        </Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {modules?.map(module => {
                  const selectedModules = modulesWorks?.filter(i => i.moduleId === module.id);
                  return (
                    <TableRow key={module.id}>
                      <CellSticky
                        padding="normal"
                        sx={{ backgroundColor: { xs: 'tertiary.main', md: 'white' } }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            color: (selectedModules.length && 'text.primary') || 'text.secondary',
                          }}
                        >
                          {module.name}
                        </Typography>
                      </CellSticky>
                      {works?.map(work => {
                        const selectedWork = selectedModules?.find(i => i.workId === work.id);
                        return (
                          <StyledTableCell
                            key={`module-${module.id}-work-${work.id}`}
                            align="center"
                            padding="normal"
                          >
                            <SeniorityLevel
                              onChange={({ level, levelId, moduleId, workId }) => {
                                const expMod =
                                  field.value?.filter(
                                    (item: any) =>
                                      item.moduleId !== moduleId || item.workId !== workId,
                                  ) || [];
                                if (moduleId && workId && levelId) {
                                  expMod.push({ moduleId, workId, levelId, level });
                                }
                                replace(expMod);
                                const values = cloneDeep(getValues());
                                actions.updateAction(values);
                              }}
                              moduleId={module.id}
                              workId={work.id}
                              value={selectedWork ? selectedWork.level : undefined}
                            />
                          </StyledTableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
      rules={{ required: 'This field is required' }}
    />
  );
};

export default ModulesSettings;
