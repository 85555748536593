/**
 *   New refactor Selectors 12.23
 */
import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';

import get from 'lodash/fp/get';

import { ERole, keyStateByRoleName } from 'models/consts';
import { UserState } from '../reducers/user';
import { IBasicCustomer } from '../types/customer';

const userState = (state: DefaultRootState) => state.user;
const consultantState = (state: DefaultRootState) => state.consultant;

export const getMeSelector = createSelector(userState, ({ user }) => user);
export const getMeLoadingSelector = createSelector(userState, ({ loading }) => loading);
export const getMeLoadedSelector = createSelector(userState, ({ loaded }) => loaded);
export const getExtendMeByRoleSelector = createSelector(
  userState,
  state => state[keyStateByRoleName[state?.user?.role.name as ERole] as keyof UserState],
);
export const getMeRoleSelector = createSelector(userState, ({ user }) => user?.role);
export const getPipedriveSelector = createSelector(
  userState,
  ({ consultant }) => consultant?.pipedrive,
);

export const isAuthorized = createSelector(userState, ({ user }) => !!user);
export const isAgencySelector = createSelector(
  userState,
  consultantState,
  ({ user }, { consultantById }) =>
    user?.role?.name === ERole.AGENCY_ADMIN ||
    user?.role?.name === ERole.RESOURCE_MANAGER ||
    !!consultantById[user.id]?.consultant?.agencyId,
);
export const isAgencyAdministratorsSelector = createSelector(userState, ({ user }) =>
  [ERole.AGENCY_ADMIN, ERole.RESOURCE_MANAGER].includes(user?.role?.name),
);
export const isAgencyAdminSelector = createSelector(
  userState,
  ({ user }) => user?.role?.name === ERole.AGENCY_ADMIN,
);
export const isAgencyResourceManagerSelector = createSelector(
  userState,
  ({ user }) => user?.role?.name === ERole.RESOURCE_MANAGER,
);
export const isConsultantSelector = createSelector(
  userState,
  ({ user }) => user?.role.name === ERole.CONSULTANT,
);
export const isSelfCustomerSelector = createSelector(
  userState,
  ({ user }) => user?.role.name === ERole.SELF_CUSTOMER,
);
export const isLeadManagerSelector = createSelector(
  userState,
  ({ user }) => user?.role.name === ERole.LEAD_MANAGER,
);
export const getLeadManagerSourcesSelector = createSelector(
  userState,
  ({ user }) => get('leadManager.leadSources', user) || [],
);
export const getDefaultLeadSourceSelector = createSelector(
  userState,
  ({ user }) => get('leadManager.leadSources[0]', user) || null,
);
export const isEnterpriseCustomerSelector = createSelector(userState, ({ user }) => {
  const allowedRoles = [ERole.CUSTOMER_ADMIN, ERole.CUSTOMER];
  const userRole = user?.role.name;
  return !!userRole && allowedRoles.includes(userRole);
});

export const isInternalSelector = createSelector(
  userState,
  ({ consultant, user }) => !!consultant?.becameInternalAt || user.agencyId,
);
export const hasVerifiedCustomerSelector = createSelector(
  userState,
  ({ customer, user }: UserState): boolean => {
    const allowedRoles = [ERole.SELF_CUSTOMER, ERole.CUSTOMER_ADMIN, ERole.CUSTOMER];
    const userRole = user?.role?.name;
    return (
      !!userRole &&
      allowedRoles.includes(userRole) &&
      (customer as IBasicCustomer).isConfirm &&
      !!(customer as IBasicCustomer).companies?.length
    );
  },
);

export const getSignUpTypeSelector = createSelector(userState, ({ signUpType }) => signUpType);
