export const ENV_LOCAL = 'local';
export const ENV_DEV = 'development';
export const ENV_STAGING = 'staging';
export const ENV_PROD = 'production';
export const ENV_TEST = 'test';

export function isProd(): boolean {
  return isEnv(ENV_PROD);
}

export function isStaging(): boolean {
  return isEnv(ENV_STAGING);
}

export default function isDev(): boolean {
  return isEnv(ENV_DEV);
}

export function isTest(): boolean {
  return isEnv(ENV_TEST);
}

export function isLocal(): boolean {
  return !process.env.REACT_APP_ENV || isEnv(ENV_LOCAL);
}

function isEnv(env: string): boolean {
  return process.env.REACT_APP_ENV === env;
}
