import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, IconButton, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMeRoleSelector } from 'store/selectors/getUserSelector';
import fetchPreOrderById from 'store/query/preorder/fetchPreOrderById';
import {
  currentPreOrderLoadedSelector,
  currentPreOrderSelector,
} from 'store/selectors/getPreOrderSelector';

import ReviewPreorderForm from './ReviewPreorderForm';
import EditPreorderFormAgency from './EditPreorderFormAgency';
import SetPurchaseOrderForm from './SetPurchaseOrderForm';
import ArrowLeftIcon from 'styles/icons/ArrowLeft';
import { EPreOrderStatus, ERole } from 'models/consts';
import ROUTES from 'router/constants';

const ReviewPreorder = () => {
  const { id = '' } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const role = useAppSelector(getMeRoleSelector);
  const order = useAppSelector(currentPreOrderSelector(id));
  const loaded = useAppSelector(currentPreOrderLoadedSelector(id));

  useEffect(() => {
    if (id && !order) {
      dispatch(fetchPreOrderById({ id, role }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, order]);

  const getPageTitle = useMemo(() => {
    switch (role.name) {
      case ERole.LEAD_MANAGER:
        return 'Review Preorder';
      case ERole.CUSTOMER_ADMIN:
        return 'Set Order Form';
      default:
        return 'Edit Opportunity';
    }
  }, [role.name]);

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" mb={3} mt={2}>
        <Grid>
          <Button
            color="secondary"
            onClick={() => navigate(`${ROUTES.PRE_ORDER}/${id}`)}
            sx={{ display: { xs: 'none', md: 'inline-flex' } }}
            variant="outlined"
          >
            Back to Preorders
          </Button>
          <IconButton
            onClick={() => navigate(`${ROUTES.PRE_ORDER}/${id}`)}
            sx={{ display: { md: 'none' }, ml: -2.5 }}
          >
            <ArrowLeftIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box>
        <Typography component="div" variant="h1" mb={2}>
          {getPageTitle}
        </Typography>
        {loaded && !!order && role.name === ERole.LEAD_MANAGER && (
          <ReviewPreorderForm order={order} role={role} />
        )}
        {loaded &&
          !!order &&
          (role.name === ERole.AGENCY_ADMIN || role.name === ERole.RESOURCE_MANAGER) && (
            <EditPreorderFormAgency order={order} role={role} />
          )}
        {loaded &&
          !!order &&
          role.name === ERole.CUSTOMER_ADMIN &&
          order.status === EPreOrderStatus.FINALIZE_PAPERWORK &&
          order.billingType === 'customer' && <SetPurchaseOrderForm order={order} role={role} />}
      </Box>
    </>
  );
};

export default ReviewPreorder;
