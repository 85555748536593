import './wdyr';

import React from 'react';
import * as Sentry from '@sentry/react';
import { HelmetProvider } from 'react-helmet-async';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Cookies, CookiesProvider } from 'react-cookie';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import TagManager from 'react-gtm-module';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import config from './app.config';

import store from './store/store';
import { setupResponseInterceptor } from 'store/middlewares/api';
import muiTheme from './theme';

import routes from './routes';

setupResponseInterceptor(store);

const rootElement = document?.getElementById('root');
const root = createRoot(rootElement!);
const shouldSendToSentry = ['test', 'production', 'staging'].includes(process.env.NODE_ENV);
const gtmAuth = config.REACT_APP_GTM_AUTH || '';
const gtmPreview = config.REACT_APP_GTM_PREVIEW || '';

const tagManagerArgs = {
  gtmId: config.REACT_APP_GA_GTM,
  auth: gtmAuth,
  preview: gtmPreview,
  dataLayer: {
    user: {
      role: {
        name: 'Guest',
      },
    },
  },
};

TagManager.initialize(tagManagerArgs);

if (shouldSendToSentry) {
  config.SENTRY_DSN &&
    Sentry.init({
      dsn: config.SENTRY_DSN,
      environment: config.SENTRY_ENV,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
        new Sentry.Replay(),
      ],

      // Set  tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      tracesSampleRate: 1.0,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
}

console.info('theme ', muiTheme);

const router = createBrowserRouter(routes);

const cookies = new Cookies(null, { path: '/', sameSite: 'lax' });

root.render(
  <HelmetProvider>
    <ThemeProvider theme={muiTheme}>
      <CookiesProvider cookies={cookies}>
        <Provider store={store}>
          <CssBaseline />
          <RouterProvider router={router} fallbackElement={null} />
        </Provider>
      </CookiesProvider>
    </ThemeProvider>
  </HelmetProvider>,
);
