import React, {FC, useMemo} from "react";

import find from "lodash/fp/find";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";

import { useAppSelector } from 'store/hooks';
import { isInternalSelector } from 'store/selectors/getUserSelector';
import {SeniorityDurationLevel} from "models/consts";

import {calculateExperiencesDuration, calculateValueProgressBar} from "utils/transformers";

import ExperiencesTrack from "../ExperiencesTrack";
import CustomTooltip from "../../CustomTooltip";
import {ISeniorityDurationLevel} from "../../../store/types/common";
import {IProductStripe} from "../../../store/types/categoryProducts";

interface IExperienceLevel {
  periods: any[];
  productPrices: IProductStripe[];
}

const ExperienceLevel: FC<IExperienceLevel> = ({ periods, productPrices }) => {
  const { duration, durationFormatted } = useMemo(() => calculateExperiencesDuration(periods), [periods]);
  const isInternal = useAppSelector(isInternalSelector);

  const price = useMemo(() => {
    const seniority = find((level: ISeniorityDurationLevel) => level.value <= duration)(SeniorityDurationLevel)
    return find((product: IProductStripe) => product.levelId === seniority?.levelId)(productPrices)
  }, [duration, productPrices])

  const progess = useMemo(() => calculateValueProgressBar(duration), [duration]);

  return (
    <Grid container spacing={4} mb={{md: 2}}>
      <Grid alignSelf="center" xs={6} md="auto" order={{xs: 1}}>
        <Typography mb={1} variant="h6">
          Seniority
          <CustomTooltip
            placement="bottom"
            title="Your work history determines your seniority and directly impacts your hourly earnings. Please ensure the information provided is accurate as we perform reference checks for verification. This important step allows us to fairly evaluate and compensate you for your time."
          />
        </Typography>
        <Typography mb={{md: 3}}>{durationFormatted}</Typography>
      </Grid>
      <Grid container xs={true} order={{xs: 3, md: 1}} spacing={0}>
        <Grid xs alignSelf="center">
          <Typography mb={{xs: 2, md: 0}} sx={{height: {md: 21}}}>
            {duration < 12 && (
              <>You need {12 - duration} more months of experience to qualify</>
            )}
          </Typography>
          <Box mx={1.25}>
            <ExperiencesTrack progress={progess} />
          </Box>
        </Grid>
      </Grid>
      <Grid alignSelf="center" xs={6} md="auto" order={{xs: 2, md: 1}}>
        {!isInternal && (price?.stripePriceCents &&
          <Typography variant="h1" mt={{md: 1.5}} textAlign="end">${price.stripePriceCents / 100}/h</Typography> ||
          <Typography variant="h1" mt={{md: 1.5}} textAlign="end">N/A</Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default ExperienceLevel
