import { createAsyncThunk} from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { EOrderCreation } from 'store/constants';
import { IMatching } from 'models';

const checkMatching = createAsyncThunk(
  EOrderCreation.checkMatching,
  async ({allHours, ...payload}: IMatching) => {
    const { data } = await instance.post('check-matchingV5', payload);
    return data;
  }
)

export default checkMatching;
