import React, { useEffect } from 'react';
import dayjs from 'dayjs';

import Divider from "@mui/material/Divider";
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import EditIcon from 'styles/icons/EditIcon';
import CloseIcon from 'styles/icons/CloseIcon';

import { FeatureTitleContainer, FeatureDataContainer, FeatureDataText, StyledActionsGrid, StyledGridCertificateContainer } from './styled';

import { ICertificateItem } from "store/types/certificates";

import CertificatePreview from './CertificatePreview';


interface ICertificateListItem {
  item: ICertificateItem;
  isEditable?: boolean;
  onDelete?: (id: number) => void;
  onEdit?: (data: any) => void;
};

const CertificateListItem: React.FunctionComponent<ICertificateListItem> = ({ item, isEditable, onDelete, onEdit }) => {

  const handleDelete = () => {
    if (onDelete) {
      onDelete(item.id);
    }
  };

  const handleEdit = () => {
    if (onEdit) {
      onEdit(item.id);
    }
  }; 

  return (
    <StyledGridCertificateContainer container isEditable={isEditable} spacing={2}>
      <Grid xs={12} md="auto">
        <CertificatePreview
          src={item?.files[0].publicUrl}
          name={item?.files[0].originalName || 'file'}
          isPdf={item?.files[0].mimeType === 'application/pdf'}
          isEditable={isEditable}
        />
      </Grid>
      <Grid container xs={12} md alignItems="center">
        <Grid container xs={12} md spacing={0}>
          <Grid xs={12}>
            <Typography variant="h6">
              {item.name}
            </Typography>
          </Grid>
          <Grid container xs={12} lg={6} alignItems="center" spacing={0}>
            <FeatureTitleContainer>
              <Typography variant="body2">Issuing organisation</Typography>
            </FeatureTitleContainer>
            <FeatureDataContainer flexGrow={1}>
              <FeatureDataText>{item.organization.name}</FeatureDataText>
            </FeatureDataContainer>
          </Grid>
          <Grid container xs={12} lg={6} alignItems="center" spacing={0}>
            <FeatureTitleContainer>
              <Typography variant="body2">Issue Date</Typography>
            </FeatureTitleContainer>
            <FeatureDataContainer flexGrow={1}>
              <FeatureDataText>{dayjs(item.issueDate).format('MMM YYYY')}</FeatureDataText>
            </FeatureDataContainer>
          </Grid>
          <Grid container xs={12} lg={6} order={{ xs: 1, lg: 0 }} alignItems="center" spacing={0}>
            <FeatureTitleContainer>
              <Typography variant="body2">Credentials ID or URL</Typography>
            </FeatureTitleContainer>
            <FeatureDataContainer>
              <FeatureDataText>
                {item.certificateId || 'Not specified'}
              </FeatureDataText>
            </FeatureDataContainer>
          </Grid>
          <Grid container xs={12} lg={6} alignItems="center" spacing={0}>
            <FeatureTitleContainer>
              <Typography variant="body2">Expiration Date</Typography>
            </FeatureTitleContainer>
            <FeatureDataContainer flexGrow={1}>
              <FeatureDataText>{item.expirationDate ? dayjs(item.expirationDate).format('MMM YYYY') : 'Not specified'}</FeatureDataText>
            </FeatureDataContainer>
          </Grid>
          <Grid container xs={12} lg={6} alignItems="center" spacing={0}>
            <FeatureTitleContainer>
              <Typography variant="body2">Product</Typography>
            </FeatureTitleContainer>
            <FeatureDataContainer flexGrow={1}>
              <FeatureDataText>{item.product?.name || 'Not specified'}</FeatureDataText>
            </FeatureDataContainer>
          </Grid>
          <Grid container xs={12} lg={6} alignItems="center" spacing={0}>
            <FeatureTitleContainer>
              <Typography variant="body2">Modules</Typography>
            </FeatureTitleContainer>
            <FeatureDataContainer flexGrow={1}>
              <FeatureDataText>{item.modulesString || 'Not specified'}</FeatureDataText>
            </FeatureDataContainer>
          </Grid>
          <Grid container xs={12} lg={6} alignItems="center" spacing={0}>
            <FeatureTitleContainer>
              <Typography variant="body2">Type of Work</Typography>
            </FeatureTitleContainer>
            <FeatureDataContainer flexGrow={1}>
              <FeatureDataText>{item.worksString || 'Not specified'}</FeatureDataText>
            </FeatureDataContainer>
          </Grid>
        </Grid>
        {isEditable && (
          <StyledActionsGrid xs="auto">
            <IconButton color="tertiary" size="small" onClick={handleEdit}>
              <EditIcon fontSize="inherit" sx={{ fill: 'none' }} />
            </IconButton>
            <IconButton color="tertiary" size="small" onClick={handleDelete}>
              <CloseIcon />
            </IconButton>
          </StyledActionsGrid>
        )}
        {!isEditable && (
          <Grid xs={12}>
            <Divider sx={{ my: 0 }} />
          </Grid>
        )}
      </Grid>
    </StyledGridCertificateContainer>
  );
};

export default CertificateListItem;
