import { Components, darken } from '@mui/material/styles';

const MuiChip: Components['MuiChip'] = {
  styleOverrides: {
    root: {
      fontSize: '0.75rem',
      fontWeight: 500,
      height: 33,
      borderRadius: 100,
      lineHeight: '15px',
      '&.Opt-out': {
        backgroundColor: '#ffe8c0',
      },
      '&.Experienced': {
        backgroundColor: '#ffe1ac',
      },
      '&.Advanced': {
        backgroundColor: '#ffca69',
      },
      '&.Expert': {
        backgroundColor: '#FFB300',
      },
      '&.created, &.waiting_for_payment, &.ready_to_start, &.finalized-enterprise': {
        backgroundColor: '#FFF1C0'
      },
      '&.expired, &.canceled': {
        backgroundColor: '#DB204D'
      },
      '&.active, &.created-enterprise': {
        backgroundColor: '#C0FFC7'
      },
      '&.on_hold': {
        backgroundColor: '#F9F9F9'
      },
      '&.completed, &.terminated, &.terminated_during_trial': {
        backgroundColor: '#D8E3FD'
      },
    },
    label: ({ theme }: any) => theme.unstable_sx({
      px: 2
    }),
    deleteIcon: {
      // margin: '0 0 0 -6px'
    },
    deleteIconColorSecondary: ({ theme }: any) => ({
      color: '#FFF',
      transition: theme.transitions.create('color', {
        duration: theme.transitions.duration.standard,
      }),
      '&:hover': {
        color: darken('#FFF', .2)
      }
    }),
    filledSecondary: ({ theme }: any) => ({
      border: '1px solid #171717',
      transition: theme.transitions.create('borderColor', {
        duration: theme.transitions.duration.standard,
      }),
    }),
    outlined: {
      border: '1px solid #171717'
    },
  }
}

export default MuiChip
