import React, { FC, useMemo } from 'react';
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';
import pick from 'lodash/fp/pick';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { StyledButton, StyledButtonWrap, StyledIconButton, StyledRoot } from './styled';

import ArrowLeftIcon from 'styles/icons/ArrowLeft';

import { useAppSelector } from 'store/hooks';

import { IBasicUser } from 'store/types/user';
import { IBasicConsultant } from 'store/types/consultant';

import { getMeSelector } from 'store/selectors/getUserSelector';
import { getBasicConsultantSelector } from 'store/selectors/getConsultantSelector';

import Stepper from './Components/Stepper';
import ROUTES from 'router/constants';
import { useStateMachine } from 'little-state-machine';
import updateAction from '../../store/actions/updateAction';
import { EConsultantRegStep } from '../../models/consts';

const pickBy = pick([
  'workIds',
  'moduleIds',
  'primaryModule',
  'primaryWorkType',
  'productId',
  'purchaseOrderId',
]);

const Sidebar: FC<{ steps: any[] }> = ({ steps }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const isiPad = useMediaQuery(theme.breakpoints.between('sm', 'xxl'));

  const {
    state: { searchParams },
  } = useStateMachine({ updateAction });

  const { step = 0 } = useParams();

  const user = useAppSelector(getMeSelector) as IBasicUser;
  const consultant = useAppSelector(getBasicConsultantSelector(user?.id)) as IBasicConsultant;
  const isOrderCreationFlow = location.pathname?.includes(ROUTES.ORDER_CREATION);

  const path = useMemo(() => {
    return (
      (typeof window !== 'undefined' && new URL('.', window.origin + location.pathname)) ||
      undefined
    );
  }, [location.pathname]);

  const goBack = () => {
    if (+step === 1) {
      {mobile && isOrderCreationFlow ? navigate('/') : navigate(-1) };
    } else if (searchParams.isQuick) {
      navigate({
        pathname: '/order-flow/2',
        search: createSearchParams(pickBy(searchParams)).toString(),
      });
    } else {
      const { pathname } = new URL('.', window.origin + location.pathname);
      const destinationStep = Math.min(+step - 1, steps.length - 1);
      navigate(
        {
          pathname: `${pathname}${destinationStep}`,
          search: location.search,
        },
        consultant?.agencyId ? { state: { isAgencyAddingUser: true } } : {},
      );
    }
  };

  return (
    <StyledRoot>
      <StyledButtonWrap>
        {+step > (mobile || (isiPad && isOrderCreationFlow) ? 0 : 1) && ( //path &&
          <>
            <StyledIconButton
              data-test="back-button"
              color="secondary"
              edge="start"
              onClick={goBack}
            >
              <ArrowLeftIcon />
            </StyledIconButton>
            <StyledButton
              data-test="back-button"
              color="tertiary"
              variant="bgPrimary"
              onClick={goBack}
            >
              Back
            </StyledButton>
          </>
        )}
      </StyledButtonWrap>
      <Stepper steps={steps} />
    </StyledRoot>
  );
};

export default Sidebar;
