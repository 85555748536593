import React, {FC} from 'react'

import PlusIcon from "styles/icons/PlusIcon";

import { IConsultantExperience } from "store/types/experiences";

import { StyledCloseButton, StyledTab, StyledTabAdd, StyledTabs } from "../../../Customized/Tabs";

interface ITabsList {
  disabled?: boolean;
  onAddProduct?: () => void,
  onHandleChange: (event: React.SyntheticEvent, newValue: string) => void,
  onRemoveProduct?: (index: number, experiences: IConsultantExperience[]) => void,
  readOnly?: true,
  tab?: string,
  tabsList: any[],
}

const TabsList: FC<ITabsList> = ({ disabled, onAddProduct, onHandleChange, onRemoveProduct, readOnly, tab, tabsList }) => (
  <StyledTabs
    onChange={onHandleChange}
    variant="scrollable"
    // scrollButtons={false}
    aria-label="scrollable auto tabs example"
  >
    {tabsList.map((item, index) =>
      <StyledTab
        key={`experience-tab-${index}`}
        label={item.product?.name || 'New product'}
        iconPosition="end"
        icon={!readOnly && (
          <StyledCloseButton onClick={() => onRemoveProduct && onRemoveProduct(index, item.experiences)} />
        ) || undefined}
        value={`${index}`}
        disabled={disabled && tab !== `${index}`}
        data-test="proficiency-product"
      />
    )}
    {!readOnly && (
      <StyledTabAdd
        icon={<PlusIcon height={20} width={20} />}
        iconPosition="start"
        disabled={disabled}
        label="Add Product"
        onClick={onAddProduct}
        value={`${tabsList.length}`}
        data-test="add-product"
      />
    )}
  </StyledTabs>
)

export default TabsList
