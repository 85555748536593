import React, { FunctionComponent, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { ToastService } from 'services';

import { IEnterpriseUser } from 'store/types/enterprise';

import changeUserRole from 'store/query/enterprise/changeUserRole';
import changeUserStatus from 'store/query/enterprise/changeUserStatus';
import usersTeam from 'store/query/enterprise/usersTeam';
import resendInviteUser from 'store/query/enterprise/resendInviteUser';
import {
  getEnterpriseUsersByPageSelector,
  hasMoreEnterpriseUsersSelector,
  loadingEnterpriseUsersSelector,
} from 'store/selectors/getEnterpriseSelector';

import PlusIcon from 'styles/icons/PlusIcon';

import FilterTeam from 'components/TeamComponents/FilterTeam';
import ModalWrapper from 'components/Modal/Modal';
import AddMember from 'components/TeamComponents/AddMember';
import ManageTable from 'components/TeamComponents/ManageTable';
import ManageListMobile from 'components/TeamComponents/ManageListMobile';
import ToastMessage from 'components/Toast/ToastMessage';
import { outputQuery } from 'helpers/decorators';
import { LIMITS } from 'models/consts';

const TeamList: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [page, setPage] = React.useState(0);
  const [firstTimeOpen, setFirstTimeOpen] = React.useState<boolean>(true);

  const users = useAppSelector(getEnterpriseUsersByPageSelector(page + 1));
  const loading = useAppSelector(loadingEnterpriseUsersSelector);
  const hasMore = useAppSelector(hasMoreEnterpriseUsersSelector);

  const methods = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      filterByEmail: undefined,
      filterByStatus: undefined,
      filterByRole: undefined,
      property: undefined,
      type: 'asc',
    },
  });

  const { getValues } = methods;
  const { filterByEmail, filterByRole, filterByStatus, property, type } = getValues();

  useEffect(() => {
    if ((firstTimeOpen || hasMore) && !users.length && !loading) {
      dispatch(
        usersTeam({
          filter: outputQuery({
            filterByEmail,
            filterByRole,
            filterByStatus,
            property,
            type,
          }),
          limit: LIMITS.team,
          offset: LIMITS.team * page,
        }),
      )
        .unwrap()
        .then(() => {
          setFirstTimeOpen(false);
        });
    }
  }, [
    dispatch,
    filterByEmail,
    filterByRole,
    filterByStatus,
    hasMore,
    loading,
    property,
    type,
    page,
    users?.length,
    firstTimeOpen,
  ]);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeRole = (event: SelectChangeEvent, userId: number) => {
    dispatch(changeUserRole([{ roleId: parseInt(event.target.value), userId }]))
      .unwrap()
      .then(() => {
        ToastService.success(ToastMessage({ title: 'Success', body: 'Role is changed!' }), {
          toastId: userId,
        });
      });
  };

  const handleChangeStatus = (user: IEnterpriseUser, status?: any) => {
    if (status !== 'refresh') {
      dispatch(changeUserStatus([{ userId: user.id }]))
        .unwrap()
        .then(() => {
          ToastService.success(ToastMessage({ title: 'Success', body: 'Status is changed!' }), {
            toastId: user.id,
          });
        });
    } else {
      dispatch(resendInviteUser({ email: user.email, roleId: user.role.id }))
        .unwrap()
        .then(() => {
          ToastService.success(ToastMessage({ title: 'Success', body: 'Resend invite!' }), {
            toastId: user.id,
          });
        });
    }
  };

  return (
    <Box data-test="team-page">
      <Box
        sx={{
          display: 'flex',
          mt: { xs: 2.5, md: 7 },
          mb: 3,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h1">Team Roster</Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClickListItem}
          startIcon={<PlusIcon />}
          size={(mobile && 'small') || 'medium'}
          data-test="add-team-member-button"
        >
          Add Team Member
        </Button>
      </Box>

      <FormProvider {...methods}>
        <FilterTeam setPage={setPage} />
        {!mobile && (
          <ManageTable
            {...{ page, setPage }}
            onChangeRole={handleChangeRole}
            onChangeStatus={handleChangeStatus}
          />
        )}
        {mobile && (
          <ManageListMobile
            {...{ setPage }}
            onChangeRole={handleChangeRole}
            onChangeStatus={handleChangeStatus}
          />
        )}
      </FormProvider>

      <ModalWrapper
        dialogProps={{
          open,
          onClose: handleClose,
        }}
        title={{ title: 'Add Team Member' }}
      >
        <AddMember onClose={() => handleClose()} />
      </ModalWrapper>
    </Box>
  );
};

export default TeamList;
