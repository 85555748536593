import React from 'react';
import { ToastContainer } from 'react-toastify';
import { styled } from "@mui/material/styles";
import { Capacitor } from '@capacitor/core';

const StyledToastContainer = styled(ToastContainer)({
  ['&.Toastify__toast-container']: {
    maxWidth: 480,
    minWidth: '33%',
    ['@media only screen and (max-width: 576px)']: Capacitor.isNativePlatform() ? {
      width: 'auto',
      maxWidth: '100%',
      padding: 0,
      margin: 20,
      left: 0,
      right: 0,
      top: '550px !important',
    } : {
      width: '100vw',
      maxWidth: 'none',
      padding: 0,
      left: 0,
    }
  },
  ['@media only screen and (max-width: 576px)']: {
    ['&.Toastify__toast-container--top-center']: {
      top: 0,
      transform: 'translateX(0)'
    }
  },
  ['& .Toastify__toast--error ']: {
    backgroundColor: '#FFDBE4',
    color: '#DB204D',
    ['& .Toastify__close-button']: {
      color: '#DB204D',
    }
  },
  ['& .Toastify__toast--success ']: {
    backgroundColor: '#CDF2CE',
    color: '#07BC0C',
    ['& .Toastify__close-button']: {
      color: '#07BC0C',
    }
  },
  ['& .Toastify__toast-body']: {
    alignItems: 'start'
  },
})

const CustomToastContainer = () => <StyledToastContainer />;

export default CustomToastContainer;
