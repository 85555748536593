import { createSlice } from '@reduxjs/toolkit';

import union from 'lodash/fp/union';

import { DEFAULT_ENTITY_IDS, LIMITS } from '../../models/consts';

import { IChatItem, IChatResponse, IChatTokens } from '../types/chat';
import { IDynamicKey, IEntitiesIds } from '../types/common';

import fetchChatList from '../query/chat/fetchChatList';
import getChatToken from '../query/chat/getChatToken';

export interface ChatState {
  chatList: IEntitiesIds;
  chatsById: IDynamicKey<IChatItem>;
  tokens: IChatTokens;
  messageList: IDynamicKey<string[]>;
  messagesById: IDynamicKey<any>;
  serviceSId: string;

  chats: IChatItem[];
  currentChat: IChatItem | undefined;
  loading: boolean;
  currentConversation: any;
}

const initialState: ChatState = {
  chatList: DEFAULT_ENTITY_IDS,
  chatsById: {},
  tokens: { token: '', oldToken: '' },
  messageList: {},
  messagesById: {},
  serviceSId: '',

  chats: [],
  currentChat: undefined,
  loading: false,
  currentConversation: undefined,
};

const addChats = (chats: any[]) => (chatsMap: IDynamicKey<IChatItem>) =>
  chats.reduce((chatMap, chat) => {
    if (!chatMap[chat.chatId]) {
      chatMap[chat.chatId] = {
        ...chat.chat,
        loading: false,
        loaded: true,
      };
    }
    return chatMap;
  }, chatsMap);

const addMessages = (messages: any[]) => (messagesMap: IDynamicKey<IChatItem>) =>
  messages.reduce((messageMap, message) => {
    if (!messageMap[message.chatId]) {
      messageMap[message.sid] = message;
    }
    return messageMap;
  }, messagesMap);

const { actions, reducer } = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setMessages: (state, action) => {
      const { payload } = action;

      if (payload.messages) {
        state.messageList[payload.key] = union(
          payload.messages.map((message: any) => message.sid) as string[],
          (state.messageList[payload.key] && state.messageList[payload.key]) || [],
        );
        addMessages(payload.messages)(state.messagesById);
      }
    },
    setCurrentConversation: (state, { payload }) => {
      state.currentConversation = payload.conversation;
    },
  },
  extraReducers: builder =>
    builder
      /**
       *  Fetch Chat List
       */
      .addCase(fetchChatList.pending, state => {
        state.chatList.loading = true;
      })
      .addCase(fetchChatList.fulfilled, (state, action) => {
        const { meta, payload } = action;

        if (payload.length) {
          state.chatList.ids = union(
            payload.map((chat: IChatResponse) => chat.chatId),
            state.chatList.ids,
          );
          addChats(payload)(state.chatsById);
          state.serviceSId = payload[0].chat.serviceSId;
        }

        state.chatList.loaded = true;
        state.chatList.loading = false;
        state.chatList.hasMore =
          !!payload.length && payload.length >= (meta.arg.limit || LIMITS.chat);
      })
      /**
       *  Get Chat Token
       */
      .addCase(getChatToken.fulfilled, (state, action) => {
        const { payload } = action;

        state.tokens = {
          token: payload.newToken,
          oldToken: payload.token,
        };
      }),
});

export const { setCurrentConversation, setMessages } = actions;

export default reducer;
