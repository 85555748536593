import { createSlice } from "@reduxjs/toolkit";

import flowRight from "lodash/fp/flowRight";
import filter from "lodash/fp/filter";
import find from "lodash/fp/find";
import mergeWith from "lodash/fp/mergeWith";
import groupBy from "lodash/fp/groupBy";
import keyBy from "lodash/fp/keyBy";
import map from "lodash/fp/map";
import pick from "lodash/fp/pick";

import { DEFAULT_ENTITY, ERole } from "models/consts";

import { CommonEntities, IDynamicKey } from "../types/common";
import { IFullConsultant, IMatchedCard } from "../types/consultant";
import { IConsultantExperience } from "../types/experiences";

import fetchConsultantCertificates from "../query/consultant/fetchConsultantCertificates";
import fetchMe from "../query/user/fetchMe";
import getConsultantProductsFull from "../query/consultant/getConsultantProductsFull";
import fetchConsultant from "../query/consultant/fetchConsultant";
import addUserLanguages from "../query/user/addUserLanguages";
import removeUserLanguages from "../query/user/removeUserLanguages";
import addConsultantExperience from "../query/consultant/addConsultantExperience";
import updateConsultantExpertise from "../query/consultant/updateConsultantExpertise";
import addAvailability from "../query/consultant/addAvailability";
import updateAvailability from "../query/consultant/updateAvailability";
import addOutDates from "../query/consultant/addOutDates";
import createAgreement from "../query/consultant/createAgreement";
import searchWithMatching from "../query/order-creation/searchWithMatching";
import updateConsultantExperience from "../query/consultant/updateConsultantExperience";
import deleteSubExperience from "../query/consultant/deleteSubExperience";
import deleteProductExperiences from "../query/consultant/deleteProductExperiences";
import updateOutDates from "../query/consultant/updateOutDates";
import {
  createConsultantCertificate,
  deleteConsultantCertificate,
  updateConsultantCertificate
} from "../query/consultant";
import addConsultantTags from "../query/tags/addConsultantTags";
import saveAvatar from "../query/consultant/saveAvatar";
import updateConsultantInfo from "../query/consultant/updateConsultantInfo";
import updateUserLanguages from "../query/user/updateUserLanguages";
import {IUserLanguages} from "../types/language";
import updateBasicInfo from "../query/consultant/updateBasicInfo";
import setUserFromSSR from "../query/user/setUserFromSSR";
import setConsultantProductsFull from "../query/consultant/setConsultantProductsFull";
import requestNewProduct from "../query/productAndExperience/requestProduct";

const pickMatching = pick([ 'cardType', 'id', 'matching', 'price', 'scores' ])
const pickUser = pick([ 'email', 'firstName', 'id', 'isActive', 'isVirtual', 'lastChangeActiveAt', 'lastName', 'role' ])

const consultantFlow = flowRight(
  keyBy('user.id'),
  map((item: IFullConsultant) => ({
    user: pickUser(item),
    consultant: item.consultant,
    loading: false, // temp
    loaded: true, // temp
  })),
  filter({'cardType': 'individual'})
)

export interface ConsultantState {
  consultantById: IDynamicKey<IFullConsultant>;
  matchedResults: CommonEntities<IMatchedCard[]>;
}

const initialState: ConsultantState = {
  consultantById: {},
  matchedResults: DEFAULT_ENTITY,
}

const { actions, reducer } = createSlice({
  name: 'consultant',
  initialState,
  reducers: {
    setConsultant: (state, action) => {
      const { payload: { consultant, key, ...user } } = action;

      state.consultantById[key] = {
        ...state.consultantById[key],
        consultant,
        productExperienceFull: [],
        user,
        loading: false,
        loaded: true,
      }
    },

    /**
     * TODO: Will be refactored
     * @param state
     */
    resetMatchedResults: (state) => {
      state.matchedResults = DEFAULT_ENTITY
    }
  },
  extraReducers: (builder) => builder
    /**
     *  Add/Update Consultant Availability/OutDates
     */
    .addCase(addAvailability.fulfilled, (state, action) => {
      const { meta, payload } = action
      state.consultantById[meta.arg.userId].consultant = {
        ...state.consultantById[meta.arg.userId].consultant,
        availabilities: payload,
        registrationStep: 4
      };
    })
    .addCase(updateAvailability.fulfilled, (state, action) => {
      const { meta, payload } = action
      state.consultantById[meta.arg.userId].consultant.availabilities = payload;
    })
    .addCase(addOutDates.fulfilled, (state, action) => {
      const { meta, payload } = action

      state.consultantById[meta.arg.userId].consultant.outDates = payload;
    })
    .addCase(updateOutDates.fulfilled, (state, action) => {
      const { meta, payload } = action

      state.consultantById[meta.arg.userId].consultant.outDates = payload;
    })

    /**
     *  Add Consultant avatar
     */
    .addCase(saveAvatar.fulfilled, (state, action) => {
      const { meta, payload } = action
      state.consultantById[meta.arg.key].consultant.avatarUrl = payload.avatarUrl
    })

    /**
     *  Add Consultant Experience
     */
    .addCase(addConsultantExperience.fulfilled, (state, action) => {
      const { meta, payload } = action
      state.consultantById[meta.arg.userId].productExperienceFull.map((product) => payload
        .find((experience: IConsultantExperience) =>
          product.productId === experience.productId && product.experiences.push(experience)
        )
      )
    })

    /**
     *  Add Consultant tags
     */
    .addCase(addConsultantTags.fulfilled, (state, action) => {
      const { meta, payload } = action
      state.consultantById[meta.arg.key].consultant.tags = payload
    })

    /**
     *  Delete Consultant Product
     */
    .addCase(deleteProductExperiences.fulfilled, (state, action) => {
      const { meta, payload } = action
      state.consultantById[meta.arg.userId].productExperienceFull.splice(payload.index, 1)
    })

    /**
     *  Delete Consultant Experience
     */
    .addCase(deleteSubExperience.fulfilled, (state, action) => {
      const { meta, payload } = action
      state.consultantById[meta.arg.userId].productExperienceFull[payload.indexProduct].experiences.splice(payload.index, 1)
    })

    /**
     *  Update Consultant Experience
     */
    .addCase(updateConsultantExperience.fulfilled, (state, action) => {
      const { meta, payload } = action
      const grouped = groupBy('productId')(payload)

      state.consultantById[meta.arg.userId].productExperienceFull.map((product) => Object.entries(grouped).map(([productId, experiences]) =>
        product.productId === parseInt(productId) &&
        product.experiences.map((item, index) => {
          const matchingItem = find({id: item.id})(experiences);
          if (matchingItem) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return product.experiences[index] = mergeWith({}, item, matchingItem);
          }
          return item;
        })
      ))
    })

    /**
     *  Create Consultant Agreement
     */
    .addCase(createAgreement.fulfilled, (state, action) => {
      const { meta, payload } = action;

      state.consultantById[meta.arg.userId].consultant = {
        ...state.consultantById[meta.arg.userId].consultant,
        agreements: payload,
        registrationStep: 5
      };
    })

    /**
     *  Fetch Consultant By ID
     */
    .addCase(fetchConsultant.pending, (state, action) => {
      const { meta } = action

      state.consultantById[meta.arg] = {
        ...state.consultantById[meta.arg],
        loading: true,
        loaded: false,
      }
    })
    .addCase(fetchConsultant.fulfilled, (state, action) => {
      const { meta, payload: { consultant, ...user } } = action;

      state.consultantById[meta.arg] = {
        ...state.consultantById[meta.arg],
        user,
        consultant,
        loading: false,
        loaded: true,
      }
    })

    /**
     *  Get Consultant Certificates
     */
    .addCase(fetchConsultantCertificates.fulfilled, (state, action) => {
      const { meta, payload } = action;

      state.consultantById[meta.arg.key] = {
        ...state.consultantById[meta.arg.key],
        certificatesList: payload,
      }
    })

    /**
     *  Create Consultant Certificates
     */
    .addCase(createConsultantCertificate.fulfilled, (state, action) => {
      const { meta, payload } = action;

      state.consultantById[meta.arg.key].certificatesList.push(payload)
    })

    /**
     *  Update Consultant Certificates
     */
    .addCase(deleteConsultantCertificate.fulfilled, (state, action) => {
      const { meta, payload } = action;
      state.consultantById[meta.arg.key].certificatesList = state.consultantById[meta.arg.key].certificatesList.filter((item) => !payload.includes(item.id));
    })

    /**
     *  Update Consultant Certificates
     */
    .addCase(updateConsultantCertificate.fulfilled, (state, action) => {
      const { meta, payload } = action;

      state.consultantById[meta.arg.key].certificatesList = state.consultantById[meta.arg.key].certificatesList.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
    })

    /**
     *  Get Product Experience Full
     */
    .addCase(getConsultantProductsFull.fulfilled, (state, action) => {
      const { meta, payload } = action;

      state.consultantById[meta.arg] = {
        ...state.consultantById[meta.arg],
        productExperienceFull: payload,
      }
    })
    .addCase(setConsultantProductsFull.fulfilled, (state, action) => {
      const { meta, payload } = action;

      state.consultantById[meta.arg.userId] = {
        ...state.consultantById[meta.arg.userId],
        productExperienceFull: payload.data,
      }
    })

    /**
     *  Languages
     */
    .addCase(addUserLanguages.fulfilled, (state, action) => {
      const { meta, payload } = action
      state.consultantById[meta.arg.userId].consultant.languages.push(...payload)
    })
    .addCase(removeUserLanguages.fulfilled, (state, action) => {
      const { meta, payload } = action;
      state.consultantById[meta.arg.userId].consultant.languages = [
        ...state.consultantById[meta.arg.userId].consultant.languages.filter((item: any) => !payload.ids.find((id: number) => id === item.id))
      ]
    })
    .addCase(updateUserLanguages.fulfilled, (state, action) => {
      const { meta, payload } = action;
      state.consultantById[meta.arg.userId].consultant.languages = [
        ...state.consultantById[meta.arg.userId].consultant.languages.map((item: any) => payload.find((upd) => upd.id === item.id && upd) || item) as IUserLanguages[]
      ]
    })

    /**
     *  Fetch Consultant By getMe
     */
    .addCase(fetchMe.fulfilled, (state, action) => {
      const {payload: { consultant, ...user} } = action
      if(user.role.name === ERole.CONSULTANT) {
        state.consultantById[user.id] = {
          ...state.consultantById[user.id],
          consultant,
          user,
          loading: false,
          loaded: true,
        }
      }
    })
    .addCase(setUserFromSSR.fulfilled, (state, action) => {
      const {payload: { consultant, ...user} } = action
      if(user.role.name === ERole.CONSULTANT) {
        state.consultantById[user.id] = {
          ...state.consultantById[user.id],
          consultant,
          user,
          loading: false,
          loaded: true,
        }
      }
    })

    /**
     * Update consultant Info
     */
    .addCase(updateConsultantInfo.fulfilled, (state, action) => {
      /**
       * TODO: Will be refactor
       */
      const { payload: { consultant, ...user } } = action;
      if (state.consultantById[consultant.nickName]) {
        state.consultantById[consultant.nickName] = {
          ...state.consultantById[consultant.nickName],
          consultant,
          user,
        }
      }
      if (state.consultantById[user.id]) {
        state.consultantById[user.id] = {
          ...state.consultantById[user.id],
          consultant,
          user,
        }
      }
    })

    /**
     *  Update Basic User
     */
    .addCase(updateBasicInfo.fulfilled, (state, action) => {
      /**
       * TODO: Will be refactor
       */
      const { payload } = action;

      if(state.consultantById[payload.id]) {
        state.consultantById[payload.id] = {
          ...state.consultantById[payload.id],
          user: payload,
        }
      }
    })
    /**
     *  Update Consultant Expertise
     */
    .addCase(updateConsultantExpertise.fulfilled, (state, action) => {
      const { meta, payload } = action
      const grouped = groupBy('productId')(payload)
      state.consultantById[meta.arg.key].productExperienceFull.map((product) => Object.entries(grouped).map(([productId, expertise]) => {
        if(product.productId === parseInt(productId)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return product.expertise = expertise[0]
        }
        return product
      }))
    })

    /**
     *  Matching Consultant
     */
    .addCase(searchWithMatching.pending, (state, action) => {
      const { meta } = action

      state.matchedResults = {
        ...state.matchedResults,
        items: [
          ...(meta.arg.offset && state.matchedResults.items || []),
        ],
        loading: true,
        loaded: false,
      };
    })
    .addCase(searchWithMatching.fulfilled, (state, action) => {
      const { payload: { cards, countAll } } = action
      state.matchedResults = {
        countAll,
        items: [
          ...state.matchedResults.items,
          ...map((item: IMatchedCard) => pickMatching(item))(cards) as IMatchedCard[]
        ],
        hasMore: countAll > state.matchedResults.items.length + cards.length,
        loading: false,
        loaded: true,
      };

      Object.assign(state.consultantById, consultantFlow(cards));
    })

    .addCase(requestNewProduct.fulfilled, (state, action) => {
      const { payload: { user } } = action;
      if(state.consultantById[user.id]) {
        state.consultantById[user.id] = {
          ...state.consultantById[user.id],
          consultant: user.consultant,
        }
      }
    })


});

export const { resetMatchedResults, setConsultant } = actions;

export default reducer
