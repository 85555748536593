import React, {FunctionComponent} from 'react';

import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Toolbar from '@mui/material/Toolbar';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {Logo} from 'styles/icons/Logo';

const AuthHeader: FunctionComponent<{ type: string | undefined; isInvitation: boolean }> = ({
  type,
  isInvitation,
}) => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <AppBar color="transparent" elevation={0} position="static">
      <Container maxWidth="xxl" fixed>
        <Toolbar disableGutters>
          <Grid container xs={12} alignItems="center" my={{ xs: 4, sm: 4 }} sx={{ height: 42 }}>
            <Grid xs={2} sm={4} md={6}>
              <Logo
                height={(small && 45) || 28}
                width={(small && 260) || 165}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default AuthHeader;
