import { Dispatch, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import instance from 'store/middlewares/api';
import { EMobile } from 'store/constants';
import { RootState } from 'store/store';

const registerUserNotification = createAsyncThunk<
  AxiosResponse,
  number | undefined,
  { state: RootState; dispatch: Dispatch }
>(EMobile.reigsterUserNotifications, async (id, { getState, dispatch }) => {
  const state = getState();
  const userState = state.user;
  const mobileState = state.mobile;

  const userId = userState.user?.id || id;
  const notificationKey = mobileState.notificationKey;
  const deviceInfo = mobileState.deviceInfo;

  const { data } = await instance.put('user-notifications', {
    userId,
    notificationKey,
    deviceInfo,
  });

  return data;
});

export default registerUserNotification;
