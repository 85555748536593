import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import ReactJoyride, { CallBackProps, EVENTS, ACTIONS, STATUS, Step } from 'react-joyride';
import { useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';

import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import deepmerge from 'deepmerge';

import { sortingBy } from 'utils/transformers';
import { tagsFilter } from 'helpers/tagsFilter';

import { IBasicConsultant } from 'store/types/consultant';
import { IBasicUser } from 'store/types/user';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import searchConsultantTags from 'store/query/tags/searchConsultantTags';
import addConsultantTags from 'store/query/tags/addConsultantTags';
import { getSearchTagsSelector } from 'store/selectors/getTagsSelector';
import { getMeSelector } from 'store/selectors/getUserSelector';
import { getBasicConsultantSelector } from 'store/selectors/getConsultantSelector';

import CustomAutocomplete from 'components/CustomFields/CustomAutocomplete';

interface ISkillsForm {
  isModal: boolean;
  onClose: () => void;
  showIntro?: boolean;
}

const SkillsForm: FC<ISkillsForm> = ({ onClose, showIntro }) => {
  const customButtonStyles = {
    buttonNext: {
      fontFamily: 'Visuelt Pro, Arial',
      color: '#000',
    },
  };
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const user = useAppSelector(getMeSelector) as IBasicUser;
  const consultant = useAppSelector(getBasicConsultantSelector(id || user?.id)) as IBasicConsultant;
  const [test, setTest] = useState('');
  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState<Step[]>([]);
  const [joyrideZIndex, setJoyrideZIndex] = useState(10000);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    unregister,
  } = useFormContext();

  const options = useAppSelector(getSearchTagsSelector(test));

  const selectedTags = useWatch({
    control,
    name: 'tags',
    defaultValue: sortingBy(consultant.tags, 'UserTags.showIndex'),
  });

  const debounced = debounce(value => {
    if (value) {
      dispatch(searchConsultantTags(value));
    }
    setTest(value);
  }, 500);

  const searchTerm = (value: string) => {
    debounced(value);
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status, type, index } = data;
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setRun(false);
    } else if (action === ACTIONS.CLOSE || action === ACTIONS.STOP) {
      setRun(false);
    } else if (type === EVENTS.STEP_AFTER) {
      setRun(false);
      setTimeout(() => {
        setRun(true);
      }, 500);
    }
  };

  useEffect(() => {
    if (showIntro) {
      const timeoutId: any = setTimeout(() => {
        let stepsList: Step[] = [];

        if (!consultant?.tags || !(consultant?.tags || []).length || !consultant?.tags[0]) {
          stepsList.push({
            target: '.tags-input',
            title: 'Tag List of Additional Skills',
            content:
              'Tags show skills not covered in other sections. This also helps clients find you through specific searches.',
            placement: 'right',
            disableBeacon: true,
          });
        }

        setSteps(stepsList);

        if (stepsList.length) {
          stepsList.push({
            target: '.submit-button',
            title: 'Save',
            content: 'Click Save button to save your changes.',
            placement: 'right',
            disableBeacon: true,
          });

          setRun(true);
        }
      }, 700);
      return () => clearTimeout(timeoutId);
    }
  }, [showIntro, consultant?.tags]);

  const handleComponentOpen = () => {
    if (mobile) {
      setJoyrideZIndex(0);
    }
  };

  const handleComponentClose = () => {
    if (mobile) {
      setJoyrideZIndex(10000);
    }
  };

  const onSubmit = ({ tags }: Record<string, any>) => {
    const updatedTags = tags.map((item: any, index: number) => ({
      ...item,
      showIndex: index + 1,
    }));
    dispatch(addConsultantTags({ key: id || user.id, tags: updatedTags }))
      .then(onClose)
      .then(() => {
        reset();
      });
  };

  useEffect(() => {
    return () => {
      unregister('tags');
    };
  }, [unregister]);

  return (
    <>
      <ReactJoyride
        continuous
        run={run}
        steps={steps}
        callback={handleJoyrideCallback}
        showProgress
        disableOverlay
        scrollToFirstStep={true}
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Finish',
          next: 'Next',
          open: 'Open the dialog',
          skip: 'Skip',
        }}
        styles={deepmerge(
          {
            options: {
              backgroundColor: '#171717',
              arrowColor: '#171717',
              textColor: '#FFFFFF',
              primaryColor: '#FFCA28',
              zIndex: joyrideZIndex,
            },
          },
          customButtonStyles,
        )}
      />
      <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
        <Box mb={4} className="tags-input">
          <Controller
            control={control}
            name="tags"
            defaultValue={selectedTags}
            render={({ field }) => (
              <CustomAutocomplete
                autocompleteProps={{
                  multiple: true,
                  onInputChange: (event: SyntheticEvent, value: string) => {
                    searchTerm(value);
                    return true;
                  },
                }}
                inputProps={{
                  error: !!errors.tags,
                  helperText: (errors.tags as any)?.message || 'Incorrect data',
                  sx: {
                    '& .MuiInputBase-inputAdornedStart': { minWidth: '0px !important', padding: 0 },
                  },
                }}
                isSortable
                field={field}
                options={options}
                onOpen={handleComponentOpen}
                onClose={handleComponentClose}
                placeholder="Select tags"
                customFilter={tagsFilter}
                disabledMobile
              />
            )}
          />
        </Box>
      </form>
    </>
  );
};

export default SkillsForm;
