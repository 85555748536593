import React, { FC, FunctionComponent, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';

import ROUTES from 'router/constants';

import { IBasicUser } from 'store/types/user';
import { IConsultantRegister } from 'store/types/inner-models';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import updateAction from 'store/actions/updateAction';
import createAgreement from 'store/query/consultant/createAgreement';
import { getMeSelector } from 'store/selectors/getUserSelector';

import { StyledH1Mobile } from '../../styled';

import { BootstrapInput } from 'components/CustomFields';
import ConsultantAgreementBlock from './ConsultantAgreementBlock';
import { getBasicConsultantSelector } from 'store/selectors/getConsultantSelector';
import { IBasicConsultant } from 'store/types/consultant';

interface IUserAgreementModalProps {
  close: () => void;
}

const UserAgreement: FC<IUserAgreementModalProps> = ({ close }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(getMeSelector) as IBasicUser;
  const consultant = useAppSelector(getBasicConsultantSelector(user?.id)) as IBasicConsultant;

  const {
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    unregister,
  } = useFormContext();

  useEffect(() => {
    return () => {
      unregister(['companyNameAgreement', 'fullnameAgreement', 'userAgreement']);
    };
  }, [unregister]);

  const onSubmit = ({ fullnameAgreement, companyNameAgreement }: any) => {
    dispatch(
      createAgreement({
        agreement: {
          fullName: fullnameAgreement,
          company: companyNameAgreement,
          ipAddress: '',
        },
        userId: user.id,
      }),
    )
      .unwrap()
      .then(() => {
        close && close();
      });
  };

  return (
    <form id="hook-form" onSubmit={handleSubmit(onSubmit)} data-test="modal-user-agreement">
      <ConsultantAgreementBlock />
      <Grid spacing={2} mt={2} ml={6} mr={6}>
        <Grid xs={12} md={6}>
          <InputLabel>Full Name</InputLabel>
          <Controller
            control={control}
            name="fullnameAgreement"
            defaultValue=""
            render={({ field: { ref, ...field } }) => (
              <BootstrapInput
                {...field}
                inputRef={ref}
                error={!!errors.fullnameAgreement}
                placeholder="Enter your first name"
                helperText={errors.fullnameAgreement?.message || 'Incorrect data'}
              />
            )}
            rules={{ required: 'This field is required' }}
          />
        </Grid>
        <Grid xs={12} md={6} mt={2}>
          <InputLabel>
            {consultant.businessSetup === 'ein'
              ? 'Enter Your EIN Number'
              : 'Full Legal Name of the Company'}
          </InputLabel>
          <Controller
            control={control}
            name="companyNameAgreement"
            defaultValue=""
            render={({ field: { ref, ...field } }) => (
              <BootstrapInput
                {...field}
                inputRef={ref}
                error={!!errors.companyNameAgreement}
                placeholder={
                  consultant.businessSetup === 'ein' ? 'Enter EIN number' : 'Enter Company name'
                }
                helperText={errors.companyNameAgreement?.message || 'Incorrect data'}
              />
            )}
            rules={{ required: 'This field is required' }}
          />
        </Grid>

        <FormGroup sx={{ mt: 1 }}>
          <Controller
            control={control}
            defaultValue={false}
            name="userAgreement"
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    data-test="user-agreement-checkbox"
                    {...field}
                    checked={field.value}
                    size="small"
                    checkedIcon={<span />}
                    icon={<span />}
                  />
                }
                label="I hereby acknowledge I have read and accept the Specialist User Agreement as an authorized representative of the company."
                sx={{ marginLeft: 0, alignItems: 'flex-start', mt: 2, mb: 4 }}
              />
            )}
            rules={{ required: true }}
          />
        </FormGroup>

        <Grid container xs={12} justifyContent="center" alignItems="center" sx={{ mb: 1 }}>
          <LoadingButton
            data-test="user-agreement-submit"
            color="secondary"
            loading={isSubmitting}
            form="hook-form"
            type="submit"
            disabled={!isValid || isSubmitting}
            variant="contained"
            sx={{ width: '265px' }}
          >
            Submit
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default UserAgreement;
