import { createSlice } from '@reduxjs/toolkit';

import fetchMe from '../query/user/fetchMe';

import { IBasicAgency } from '../types/agency';
import { IBasicConsultant } from '../types/consultant';
import { IBasicCustomer } from '../types/customer';
import { IBasicUser } from '../types/user';

import updateBasicInfo from '../query/consultant/updateBasicInfo';
import updateConsultantInfo from '../query/consultant/updateConsultantInfo';
import updateCustomer from '../query/user/updateCustomer';
import verifyCode from '../query/user/verifyCode';
import getConsultantPipeStatus from '../query/consultant/getConsultantPipeStatus';
import saveAvatar from '../query/consultant/saveAvatar';
import updateAgencyInfo from '../query/agency/updateAgencyInfo';
import addCustomerCompany from '../query/user/addCustomerCompany';
import updateCustomerCompany from '../query/user/updateCustomerCompany';
import addAvailability from '../query/consultant/addAvailability';
import updateAvailability from '../query/consultant/updateAvailability';
import createAgreement from '../query/consultant/createAgreement';
import addAgencyTags from 'store/query/tags/addAgencyTags';
import setUserFromSSR from '../query/user/setUserFromSSR';
import { updateUserRole } from 'store/query/user/updateUserRole';

export interface UserState {
  agency: IBasicAgency | any;
  consultant: IBasicConsultant | null;
  customer: IBasicCustomer | null;
  loading: boolean;
  loaded: boolean;
  user: IBasicUser | any;
  signUpType: 'consultant' | 'customer' | null;
}

const initialState: UserState = {
  agency: null,
  consultant: null,
  customer: null,
  loading: true,
  loaded: false,
  user: null,
  signUpType: null,
};

const { reducer, actions } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSignUpType: (state, { payload }) => {
      state.signUpType = payload;
    },
    setUser: (state, { payload }) => {
      const { agency, consultant, customer, ...user } = payload;
      return {
        ...state,
        agency,
        consultant,
        customer,
        loading: false,
        loaded: true,
        user: {
          ...user,
          agencyId: agency?.id || consultant?.agencyId,
          avatarUrl:
            consultant?.avatarUrl ||
            customer?.avatarUrl ||
            agency?.logo ||
            customer?.companies[0]?.logo,
        },
      };
    },
    changeCustomerAvatar: (state, { payload }) => {
      if (state.customer) {
        state.customer = {
          ...state.customer,
          companies: [
            ...state.customer.companies.map(item => {
              if (item.name === payload.name && state.user?.id === item.ownerId) {
                return {
                  ...item,
                  logo: payload.link,
                };
              }
              return item;
            }),
          ],
        };
      }
    },
  },
  extraReducers: builder =>
    builder
      /**
       *  Get Me
       */
      .addCase(fetchMe.pending, state => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(fetchMe.fulfilled, (state, action) => {
        const {
          payload: { agency, consultant, customer, ...user },
        } = action;
        return {
          ...state,
          agency,
          consultant,
          customer,
          loading: false,
          loaded: true,
          user: {
            ...user,
            agencyId: agency?.id || consultant?.agencyId,
            avatarUrl:
              consultant?.avatarUrl ||
              customer?.avatarUrl ||
              agency?.logo ||
              customer?.companies[0]?.logo,
          },
        };
      })
      .addCase(setUserFromSSR.fulfilled, (state, action) => {
        const {
          payload: { agency, consultant, customer, ...user },
        } = action;
        return {
          ...state,
          agency,
          consultant,
          customer,
          loading: false,
          loaded: true,
          user: {
            ...user,
            agencyId: agency?.id || consultant?.agencyId,
            avatarUrl:
              consultant?.avatarUrl ||
              customer?.avatarUrl ||
              agency?.logo ||
              customer?.companies[0]?.logo,
            firstName: user.firstName || agency?.name,
          },
        };
      })
      /**
       *  Verify Code
       */
      .addCase(verifyCode.fulfilled, state => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.customer = { ...state.customer, isConfirm: true };
      })

      /**
       *  Update Basic User
       */
      .addCase(updateBasicInfo.fulfilled, (state, action) => {
        const { payload } = action;

        state.user = {
          ...state.user,
          ...payload,
        };
      })

      /**
       *  Update Customer company
       */
      .addCase(addCustomerCompany.fulfilled, (state, action) => {
        const { payload } = action;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.customer = payload;
      })
      .addCase(updateCustomerCompany.fulfilled, (state, action) => {
        const { payload } = action;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.customer = payload;
      })

      /**
       *  Add Consultant avatar
       */
      .addCase(saveAvatar.fulfilled, (state, action) => {
        const { payload } = action;
        if (state.consultant) {
          state.consultant.avatarUrl = payload.avatarUrl;
        }
      })

      /**
       * Update consultant Info
       */
      .addCase(updateConsultantInfo.fulfilled, (state, action) => {
        const {
          payload: { consultant },
        } = action;
        state.consultant = {
          ...state.consultant,
          ...consultant,
        };
      })
      .addCase(addAvailability.fulfilled, (state, action) => {
        const { payload } = action;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.consultant = {
          ...state.consultant,
          availabilities: payload,
          registrationStep: 4,
        };
      })
      .addCase(updateAvailability.fulfilled, (state, action) => {
        const { payload } = action;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.consultant.availabilities = payload;
      })
      .addCase(createAgreement.fulfilled, (state, action) => {
        const { payload } = action;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.consultant = {
          ...state.consultant,
          agreements: payload,
          registrationStep: 5,
        };
      })

      /**
       * Update consultant Info
       */
      .addCase(updateAgencyInfo.fulfilled, (state, action) => {
        const { payload } = action;
        state.agency = {
          ...state.agency,
          ...payload,
        };
        state.loading = false;
      })

      /**
       * Update tags Info
       */
      .addCase(addAgencyTags.fulfilled, (state, action) => {
        const { payload } = action;
        state.agency = {
          ...state.agency,
          companyTags: payload,
        };
        state.loading = false;
      })

      /**
       *  Update Basic User customer
       */
      .addCase(updateCustomer.fulfilled, (state, action) => {
        const { payload } = action;

        state.user = {
          ...state.user,
          ...payload,
        };
      })

      /**
       *  Get Consultant PipeDrive
       */
      .addCase(getConsultantPipeStatus.fulfilled, (state, { payload }) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.pipedrive = payload;
      })
      /**
       *  update user role
       */
      .addCase(updateUserRole.fulfilled, (state, action) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.user = {
          ...state.user,
          role: { id: action.payload.id, name: action.payload.role },
        };
      }),
});

export const { changeCustomerAvatar, setUser, setSignUpType } = actions;
export default reducer;
