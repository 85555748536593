import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from 'app.config';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import useSafeAreaView from 'components/SafeAreaView';
import ROUTES from 'router/constants';

import { useAppSelector } from 'store/hooks';
import { getMeSelector } from 'store/selectors/getUserSelector';
import { IBasicUser } from 'store/types/user';
import { getBasicConsultantSelector } from 'store/selectors/getConsultantSelector';
import { IBasicConsultant } from 'store/types/consultant';
import { fetchMe } from 'store/query/user';
import { useAppDispatch } from 'store/hooks';

const OpenWindowWithCallback: FunctionComponent<{ url: string; onWindowClosed: () => void }> = ({
  url,
  onWindowClosed,
}) => {
  const [newWindow, setNewWindow] = useState<Window | null>(null);

  useEffect(() => {
    if (newWindow) {
      const interval = setInterval(() => {
        if (newWindow.closed) {
          clearInterval(interval);
          onWindowClosed();
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [newWindow, onWindowClosed]);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const win = window.open(url, '_blank');
    setNewWindow(win);
  };
  return (
    <Button
      data-test="talent-representative-btn"
      data-testhref={url}
      href={''}
      onClick={handleClick}
      color="secondary"
      variant="contained"
      sx={{ fontSize: { xs: '1.125rem', md: '1.75rem' }, height: { xs: '60px', md: '82px' } }}
    >
      Connect with a Talent Representative
    </Button>
  );
};

const ConnectWithARep: FunctionComponent<any> = () => {
  const { safeAreaPadding } = useSafeAreaView();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const user = useAppSelector(getMeSelector) as IBasicUser;
  const consultant = useAppSelector(getBasicConsultantSelector(user?.id)) as IBasicConsultant;
  const calendlyUrl = `${config.CALENDLY_URL}?name=${encodeURIComponent(user.firstName)}%20${encodeURIComponent(user.lastName)}&email=${encodeURIComponent(user.email)}`;

  useEffect(() => {
    if (!consultant.isApproved && consultant.callScheduled) {
      navigate(ROUTES.CONSULTANT);
    }
  }, [consultant, navigate]);

  const handleWindowClosed = async () => {
    // Your callback logic here
    await dispatch(fetchMe());
  };

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        mb={1.5}
        mt={safeAreaPadding.top}
        sx={{ fontSize: { xs: '2.125rem', md: '3.25rem' }, fontWeight: 'bold' }}
      >
        Congratulations!
      </Typography>
      <Typography variant="h4" sx={{ fontSize: { xs: '1rem', md: '1.875rem' } }}>
        You are one step closer to putting your skills to work.
      </Typography>
      <Typography sx={{ fontSize: { xs: '1rem', md: '1.875rem' }, mb: { xs: 1.5, xl: 4 } }}>
        The final step is to book a screening call with our Talent Team.
      </Typography>

      <Box>
        <Typography
          textAlign={'justify'}
          sx={{
            fontSize: { xs: '.875rem', md: '1.125rem' },
            mb: { xs: 1.5, xl: 4 },
            width: { xs: '100%', md: '78%' },
          }}
        >
          On this call, a Talent Representative will go over your work experience and skills that
          you have shared. This is a straightforward part of the onboarding process where we get to
          know you and help you optimize your profile. There&apos;s no need to prepare for this
          call.
        </Typography>
        <Box display="flex" alignItems="center" mt={{ xs: 1, md: 4 }}>
          <OpenWindowWithCallback url={calendlyUrl} onWindowClosed={handleWindowClosed} />
        </Box>
      </Box>

      <Box mt={{ xs: 2, md: 4 }}>
        <Typography
          textAlign={'justify'}
          sx={{
            fontSize: { xs: '.875rem', md: '1.125rem' },
            mb: { xs: 1.5, xl: 4 },
            width: { xs: '100%', md: '78%' },
          }}
        >
          After the call, we&apos;ll conduct a background check through one of our trusted partners,
          which typically takes less than 72 hours to complete. As Workerbee specialists often work
          directly with customers&apos; data, this background check is essential to build trust and
          assure clients that all platform members meet our high standards for safety and integrity.
        </Typography>
        <Typography
          textAlign={'justify'}
          sx={{
            fontSize: { xs: '.875rem', md: '1.125rem' },
            mb: { xs: 1.5, xl: 4 },
            width: { xs: '100%', md: '78%' },
            fontWeight: 'bold',
          }}
        >
          Once your background check is complete, you&apos;ll be ready to start accepting projects
          and putting your expertise to work!
        </Typography>
      </Box>
    </Box>
  );
};

export default ConnectWithARep;
