import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import NoSsr from '@mui/material/NoSsr';
import Tooltip from '@mui/material/Tooltip';

import { EAuthPage, ERole } from 'models/consts';

import { FeedbackIcon } from 'styles/icons/FeedbackIcon';

import { StyledFab, StyledLayout } from './styled';

import AuthHeader from 'components/Header/AuthHeader';
import FloatingFeedbackDialog from 'components/Modal/FloatingFeedbackDialog/FloatingFeedbackDialog';
import useMatchMultiple from '../../hooks/useMatchUrls';
import ROUTES from '../../router/constants';

import AuthBackgroundXSNew from '../../styles/icons/AuthBackgroundXSNew.svg';
import AuthBackgroundNewCustomer from '../../styles/icons/AuthBackgroundNewCustomer.svg';
import AuthBackgroundNew1 from '../../styles/icons/AuthBackgroundNew1.svg';
import AuthBackgroundNewSpecialist from '../../styles/icons/AuthBackgroundNewSpecialist.svg';
import AuthBackgroundNewAgency from '../../styles/icons/AuthBackgroundNewAgency.svg';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMeRoleSelector, getSignUpTypeSelector } from 'store/selectors/getUserSelector';
import { setSignUpType } from 'store/reducers/user';
import { useStateMachine } from 'little-state-machine';
import updateAction from '../../store/actions/updateAction';

const AuthLayout: FunctionComponent = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [showFeedbackDialog, setShowFeedbackDialog] = useState<boolean>(false);
  const isInvite = useMatchMultiple([
    `${ROUTES.SIGN_UP}/:hash`,
    `${ROUTES.SIGN_UP_CUSTOMER}/:hash`,
    `${ROUTES.SIGN_UP_AGENCY}/:hash`,
  ]);

  const role = useAppSelector(getMeRoleSelector);
  const signUpType = useAppSelector(getSignUpTypeSelector);
  const { actions } = useStateMachine({ updateAction });

  useEffect(() => {
    actions.updateAction({ experiences: [], expertise: [] });

    dispatch(
      setSignUpType(
        !role ? null : role.name === ERole.SELF_CUSTOMER ? 'self_customer' : 'consultant',
      ),
    );
  }, [actions, role, dispatch]);

  const page = useMemo(() => {
    if (location) {
      const curPage = location.pathname.split('/')[2] as EAuthPage;
      return curPage || EAuthPage.LOGIN;
    }
  }, [location]);

  return (
    <NoSsr>
      <StyledLayout
        sx={{
          backgroundImage: {
            xs: `url(${AuthBackgroundXSNew})`,
            lg: location.pathname.startsWith('/auth/signup')
              ? signUpType === null
                ? `url(${AuthBackgroundNewCustomer})`
                : signUpType === 'customer'
                  ? `url(${AuthBackgroundNew1})`
                  : signUpType === 'consultant'
                    ? `url(${AuthBackgroundNewSpecialist})`
                    : `url(${AuthBackgroundNewAgency})`
              : `url(${AuthBackgroundNew1})`,
          },
        }}
      >
        <AuthHeader type={page} isInvitation={isInvite} />
        <Box sx={{ overflowY: 'scroll', mt: { xs: 0, lg: 4 }, flexGrow: 1 }}>
          <Container
            maxWidth="xxl"
            fixed
            sx={{ height: '100%', pr: { xs: 2, sm: 3 }, overflowX: { xs: 'hidden', sm: 'unset' } }}
          >
            <Outlet />
            <Tooltip title={'Leave Feedback'} arrow sx={{ display: { xs: 'none' } }}>
              <StyledFab
                color="tertiary"
                aria-label="add feedback"
                onClick={() => setShowFeedbackDialog(true)}
              >
                <FeedbackIcon />
              </StyledFab>
            </Tooltip>
          </Container>
        </Box>

        <FloatingFeedbackDialog
          visible={showFeedbackDialog}
          close={() => setShowFeedbackDialog(false)}
        />
      </StyledLayout>
    </NoSsr>
  );
};

export default AuthLayout;
