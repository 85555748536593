import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import ListItem from '@mui/material/ListItem';

import ROUTES from 'router/constants';

import { useAppSelector } from '../../../store/hooks';
import {
  getMeSelector,
  isAgencyAdminSelector,
  isAgencyResourceManagerSelector,
  isLeadManagerSelector,
} from 'store/selectors/getUserSelector';

import { StyledRightSide, StyledListItem } from '../styled';

import AuthorizedMenu from './AuthorizedMenu';
import NotAuthorizedMenu from './NotAuthorizedMenu';
import { ERole } from 'models/consts';

interface IRightSideContainer {
  isAuth: boolean;
  isCreationPage?: boolean;
}

const RightSideContainer: FC<IRightSideContainer> = ({ isAuth, isCreationPage }) => {
  const isAgencyAdmin = useAppSelector(isAgencyAdminSelector);
  const isLeadManager = useAppSelector(isLeadManagerSelector);
  const isResourceManager = useAppSelector(isAgencyResourceManagerSelector);
  const me = useAppSelector(getMeSelector);

  return (
    <StyledRightSide isCreationPage={isCreationPage}>
      {isAuth && me.role.name !== ERole.ADMIN && (
        <>
          {/*{isConsultant && (*/}
          {/*  <StyledListItem>*/}
          {/*    <ListItem component={NavLink} to={ROUTES.CALENDAR}>*/}
          {/*      Calendar*/}
          {/*    </ListItem>*/}
          {/*  </StyledListItem>*/}
          {/*)}*/}
          {isAgencyAdmin && (
            <StyledListItem sx={{ display: { xs: 'none', sm: 'none', lg: 'block' } }}>
              <ListItem component={NavLink} to={ROUTES.PRODUCT_AND_EXPERIENCE}>
                Products & Experience
              </ListItem>
            </StyledListItem>
          )}
          {(isAgencyAdmin || isResourceManager) && (
            <StyledListItem sx={{ display: { xs: 'none', sm: 'none', lg: 'block' } }}>
              <ListItem component={NavLink} to={ROUTES.TEAM}>
                Team Roster
              </ListItem>
            </StyledListItem>
          )}
          {isLeadManager && (
            <>
              <StyledListItem>
                <ListItem component={NavLink} to={ROUTES.PARTNER_RATES}>
                  Partner Rates
                </ListItem>
              </StyledListItem>
            </>
          )}
          <StyledListItem sx={{ display: { xs: 'none', sm: 'none', lg: 'block' } }}>
            <ListItem component={NavLink} to={ROUTES.ORDER_LIST}>
              {isLeadManager ? 'My Preorders' : 'My Orders'}
            </ListItem>
          </StyledListItem>

          {!isLeadManager && (
            <StyledListItem sx={{ mr: 1, display: { xs: 'none', sm: 'none', lg: 'block' } }}>
              <ListItem data-test="messages-link" component={NavLink} to={ROUTES.CHAT}>
                Messages
              </ListItem>
            </StyledListItem>
          )}
        </>
      )}
      {isAuth && <AuthorizedMenu />}
      {!isAuth && isCreationPage && <NotAuthorizedMenu />}
    </StyledRightSide>
  );
};

export default RightSideContainer;
