import React, { FunctionComponent, useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import {
  CloseButton,
  DrawerHeaderContainer,
  StyledDrawer,
  StyledDrawerWrapperBox,
  StyledPopperBox,
} from './styled';
import { debounce } from 'lodash';
import useSafeAreaView from 'components/SafeAreaView';

interface IMobileSelectProps {
  children: any;
  className?: string;
  isOpen: boolean;
  multiple?: boolean;
  placeholder: any;
  setOpen: (open: boolean) => void;
  mobilesx?: object;
}

const MobileSelect: FunctionComponent<IMobileSelectProps> = ({
  children,
  className,
  isOpen,
  multiple,
  placeholder,
  setOpen,
  mobilesx,
}) => {
  const { safeAreaPadding } = useSafeAreaView();

  //Debouncing is required since parent MUI Autocomplete component calls
  //PopperComponent multiple times. This seems to be core MUI behavior.
  const [debouncedIsOpen, setDebouncedIsOpen] = useState(isOpen);

  const debounceUpdate = debounce((nextIsOpen: boolean) => {
    setDebouncedIsOpen(nextIsOpen);
  });

  useEffect(() => {
    debounceUpdate(isOpen);

    //Cleanup
    return () => {
      debounceUpdate.cancel();
    };
  }, [isOpen, debounceUpdate]);

  return (
    <StyledDrawer anchor="bottom" open={debouncedIsOpen} onClose={() => setOpen(false)}>
      <StyledPopperBox className={className}>
        <ClickAwayListener
          onClickAway={e => {
            setOpen(false);
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <StyledDrawerWrapperBox>
            <>
              <DrawerHeaderContainer
                container
                alignItems="flex-start"
                justifyContent="flex-start"
                sx={mobilesx ? mobilesx : { marginTop: safeAreaPadding.top }}
              >
                <CloseButton variant="text" onClick={() => setOpen(false)} color="secondary">
                  <CloseIcon />
                </CloseButton>
                <Typography variant="h4">{placeholder}</Typography>
              </DrawerHeaderContainer>
              {children}
              {multiple && (
                <Grid container alignItems="center" justifyContent="center" paddingTop={2}>
                  <Button
                    data-test="mobile-select-button"
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpen(false)}
                  >
                    Select
                  </Button>
                </Grid>
              )}
            </>
          </StyledDrawerWrapperBox>
        </ClickAwayListener>
      </StyledPopperBox>
    </StyledDrawer>
  );
};

export default MobileSelect;
