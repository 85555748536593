import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAppSelector } from 'store/hooks';
import {
  isAgencyAdminSelector,
  isAgencyResourceManagerSelector,
  isAuthorized,
  isConsultantSelector,
  isSelfCustomerSelector,
  isEnterpriseCustomerSelector,
} from 'store/selectors/getUserSelector';

import ROUTES from 'router/constants';

import useSafeAreaView from 'components/SafeAreaView';

import AgencyIcon from 'styles/icons/AgencyIcon';
import BriefcaseIcon2 from 'styles/icons/BriefcaseIcon2';
import MessageIcon from 'styles/icons/MessageIcon';
import MyProfile from 'styles/icons/MyProfile';

import { StyledBottomNavigation, StyledBottomNavigationAction, StyledPaper } from './styled';
import PlusIcon from 'styles/icons/PlusIcon';

type NavbarMenuProps = {
  setShowFeedbackDialog?: React.Dispatch<React.SetStateAction<boolean>>;
};

const NavbarMenu: React.FC<NavbarMenuProps> = ({ setShowFeedbackDialog }) => {
  const isAuth = useAppSelector(isAuthorized);
  const isConsultant = useAppSelector(isConsultantSelector);
  const isSelfCustomer = useAppSelector(isSelfCustomerSelector);
  const isAgencyAdmin = useAppSelector(isAgencyAdminSelector);
  const isResourceManager = useAppSelector(isAgencyResourceManagerSelector);
  const isEnterpriseCustomer = useAppSelector(isEnterpriseCustomerSelector);

  const navigate = useNavigate();
  const location = useLocation();

  const { safeAreaPadding } = useSafeAreaView();

  return (
    <StyledPaper elevation={0} sx={{ display: { xs: 'block', sm: 'block', lg: 'none' } }}>
      <StyledBottomNavigation
        showLabels
        value={location.pathname.split('/')[1] || '/'}
        onChange={(event, newValue) => {
          navigate(newValue);
        }}
        sx={{ height: { xs: 64 + safeAreaPadding.bottom }, alignItems: 'flex-start', pt: '10px' }}
      >
        {isAuth && [
          <StyledBottomNavigationAction
            key="orders"
            label="Orders"
            icon={<BriefcaseIcon2 />}
            value="/"
          />,
          <StyledBottomNavigationAction
            key="messages"
            label="Message"
            icon={<MessageIcon />}
            value="chat"
          />,
        ]}
        {(isAgencyAdmin || isResourceManager) && (
          <StyledBottomNavigationAction label="Team Roster" icon={<AgencyIcon />} value="team" />
        )}
        {isConsultant && (
          <StyledBottomNavigationAction label="Profile" icon={<MyProfile />} value="bee" />
        )}
        {isSelfCustomer && (
          <StyledBottomNavigationAction label="Profile" icon={<MyProfile />} value="security" />
        )}
        {isEnterpriseCustomer && (
          <StyledBottomNavigationAction
            id="create-order"
            label="Create Order"
            icon={<PlusIcon />}
            value={`${ROUTES.ORDER_CREATION}/1`}
          />
        )}
      </StyledBottomNavigation>
    </StyledPaper>
  );
};

export default NavbarMenu;
