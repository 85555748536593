import React, { FC, useState } from 'react';
import ModalWrapper from '../../Modal/Modal';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Typography,
  styled,
} from '@mui/material';
import { ExperiencesItem } from './Experienses';
import { useStateMachine } from 'little-state-machine';
import updateAction from 'store/actions/updateAction';
import { IExperienceBasis } from '../../../models/inner-models';

export interface IProductExperienseCopyModalConfig {
  isOpen: boolean;
  currentProductId?: number;
}

interface IProductExperienseCopyModal {
  modalConfig: IProductExperienseCopyModalConfig;
  closeModal: () => void;
  onSave: (selectedExperiences: IExperienceBasis[]) => void;
}

const ModalButton = styled(Button)(({ theme }) =>
  theme.unstable_sx({
    display: 'inline-flex',
    height: 'auto',
    marginLeft: theme.spacing(2),
    paddingX: 3,
    whiteSpace: 'nowrap',
    fontSize: '.875rem',
    maxWidth: 300,
  }),
);

export const ProductExperienseCopyModal: FC<IProductExperienseCopyModal> = ({
  modalConfig,
  closeModal,
  onSave,
}) => {
  const [selectedItems, setSelectedItems] = useState<{
    [key: string]: { compositeKey: string; item: IExperienceBasis }[];
  }>({});
  const {
    state: { experiences },
  } = useStateMachine({ updateAction });

  let filteredExperiences = experiences;
  if (modalConfig.currentProductId) {
    filteredExperiences = experiences.filter(
      (item: { product: { id: number } }) => item.product.id !== modalConfig.currentProductId,
    );
  }

  const getCompositeKey = (item: IExperienceBasis) => {
    const companyId = item.companyId || item.experienceCompanyId || 'no-company';
    return `${item.description}-${item.startDate}-${companyId}`;
  };

  const handleSelect = (productId: number, item: IExperienceBasis, selected: boolean) => {
    const compositeKey = getCompositeKey(item);
    setSelectedItems(prevState => {
      const productExperiences = prevState[productId] || [];
      if (selected) {
        return { ...prevState, [productId]: [...productExperiences, { compositeKey, item }] };
      } else {
        return {
          ...prevState,
          [productId]: productExperiences.filter(exp => exp.compositeKey !== compositeKey),
        };
      }
    });
  };

  const handleSelectAll = (
    productId: string,
    allSelected: boolean,
    productExperiences: IExperienceBasis[],
  ) => {
    setSelectedItems(prevState => {
      if (allSelected) {
        const experiencesWithKeys = productExperiences.map(e => ({
          compositeKey: getCompositeKey(e),
          item: e,
        }));
        return { ...prevState, [productId]: experiencesWithKeys };
      } else {
        return { ...prevState, [productId]: [] };
      }
    });
  };

  const handleSave = () => {
    const allSelectedItems = Object.values(selectedItems)
      .flat()
      .map(({ item }) => item);
    onSave(allSelectedItems);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setSelectedItems({});
    closeModal();
  };

  return (
    <ModalWrapper
      dialogProps={{
        open: modalConfig.isOpen,
        onClose: handleCloseModal,
        maxWidth: 'xl',
      }}
      title={{
        title: 'Add Experience from Another Product',
      }}
    >
      <>
        <DialogContent>
          <Box display="flex">
            <Box flexGrow={1}>
              {filteredExperiences?.map((product: any, index: number) => {
                const selectedProductExperiences = selectedItems[product.productId || 0] || [];
                const allSelected =
                  product.experiences.length === selectedProductExperiences.length;

                return (
                  <Box key={product.productId} mb={2}>
                    <FormGroup>
                      <FormControlLabel
                        sx={{ marginLeft: 4, alignItems: 'flex-start' }}
                        control={
                          <Checkbox
                            size="small"
                            id={`select-all-${product.productId}`}
                            checked={allSelected}
                            onChange={e =>
                              handleSelectAll(
                                product.productId as string,
                                e.target.checked,
                                product.experiences,
                              )
                            }
                            checkedIcon={<span />}
                            sx={{ marginTop: 0.25 }}
                            icon={<span />}
                          />
                        }
                        label={
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="subtitle2" noWrap sx={{ marginRight: 2 }}>
                              Select all
                            </Typography>
                            <Typography variant="subtitle1" noWrap sx={{ paddingBottom: 0.25 }}>
                              {product.product?.name}
                            </Typography>
                          </Box>
                        }
                      />
                      {product.experiences.map((e: IExperienceBasis) => {
                        const compositeKey = getCompositeKey(e);
                        const itemSelected = selectedProductExperiences.some(
                          exp => exp.compositeKey === compositeKey,
                        );

                        return (
                          <ExperiencesItem
                            key={compositeKey}
                            item={e}
                            index={index}
                            disabledForAgency={false}
                            selected={itemSelected}
                            onSelect={(item, selected) =>
                              handleSelect(e.productId || 0, item, selected)
                            }
                          />
                        );
                      })}
                    </FormGroup>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <ModalButton color="secondary" fullWidth variant="outlined" onClick={handleCloseModal}>
            Cancel
          </ModalButton>
          <ModalButton color="secondary" onClick={handleSave} fullWidth variant="contained">
            Add
          </ModalButton>
        </DialogActions>
      </>
    </ModalWrapper>
  );
};
