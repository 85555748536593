import React, { FunctionComponent } from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import Box from '@mui/material/Box';

const SplashScreenAnimation: FunctionComponent<any> = () => {

    return (
        <Box>
            <Player 
                  src='/assets/splash.json'
                  className="player"
                  loop
                  autoplay
              />
        </Box>
    );
}

export default SplashScreenAnimation;