import React, { FunctionComponent } from 'react'

import { IBasicConsultant } from "store/types/consultant";
import { IBasicUser } from "store/types/user";

import Avatar from "../../Avatar";

interface IAvatarBlock {
  consultant: IBasicConsultant
  user: IBasicUser;
}

const AvatarBlock: FunctionComponent<IAvatarBlock> = ({ consultant, user }) => (
  <Avatar
    image={consultant.avatarUrl}
    firstName={user?.firstName}
    lastName={user?.lastName}
    size="large"
  />
)

export default AvatarBlock
