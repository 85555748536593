import React from 'react';

import { RadioGroup, Typography, Box } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';

import { StyledFormControlLabel, StyledRadio } from './styled';

interface IRadioGroupInput<T> {
  name: string;
  values: T[];
  defaultValue?: T;
  field?: any;
  ariaLabelledby: string;
  isHorizontal?: boolean;
  labels?: any;
  disabled?: boolean;
  onChange?: (event: any) => void;
  sx?: any;
}

const RadioGroupInput: React.FunctionComponent<IRadioGroupInput<string | number>> = ({
  name,
  defaultValue,
  values,
  field,
  ariaLabelledby,
  isHorizontal = true,
  labels,
  disabled,
  onChange,
  sx,
}) => {
  const handleChange = (event: any) => {
    if (onChange) {
      onChange(event);
    } else if (field?.onChange) {
      field?.onChange(event);
    }
  };
  const selectedValue =
    field?.value !== undefined ? field.value : defaultValue !== undefined ? defaultValue : '';
  return (
    <RadioGroup
      aria-labelledby={ariaLabelledby}
      name={name}
      row={isHorizontal}
      value={selectedValue}
      onChange={handleChange}
      defaultValue={defaultValue}
    >
      {values.map((value: any) => (
        <StyledFormControlLabel
          data-test={`radio-button-${value}`}
          style={sx}
          value={value}
          key={value}
          control={
            <StyledRadio
              // onChange={handleChange}
              onBlur={field?.onBlur}
              size="small"
              data-test="radio-button"
              disableRipple
            />
          }
          label={
            labels && labels[value] ? (
              <>
                {!!labels[value].subLabel1 && (
                  <Typography color="inherit" variant="body2">
                    <strong>{labels[value].subLabel1}</strong>
                  </Typography>
                )}
                {!!labels[value].subLabel2 && (
                  <Typography color="inherit" variant="body2">
                    {labels[value].subLabel2}
                  </Typography>
                )}
                {!!labels[value].children && (
                  <Box sx={{ position: 'relative' }}>
                    {labels[value].children}
                    {!!labels[value].error && (
                      <FormHelperText error sx={{ bottom: 0, marginBottom: '-33px' }}>
                        {labels[value].helperText}
                      </FormHelperText>
                    )}
                  </Box>
                )}
              </>
            ) : (
              <span>{value}</span>
            )
          }
          disabled={disabled}
        />
      ))}
    </RadioGroup>
  );
};

export { RadioGroupInput as default, IRadioGroupInput };
