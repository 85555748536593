import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

const StyledEmptyBlock = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  textAlign: 'center'
})

export { StyledEmptyBlock }
