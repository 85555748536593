import React, { FC, useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Unstable_Grid2';
import MuiSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';

import { DEFAULT_USER_LANGUAGE, ELangLevel } from 'models/consts';

import { IUserLanguages } from 'store/types/language';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import getLanguages from 'store/query/common/getLanguages';
import { languagesSelector } from 'store/selectors/getCommonSelector';

import CloseIcon from 'styles/icons/CloseIcon';
import { AddButton } from './styled';

import BootstrapInput from 'components/CustomFields/BootstrapInput';

interface ILanguagesProps {
  items: IUserLanguages[];
}

const Languages: FC<ILanguagesProps> = ({ items }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const langs = useAppSelector(languagesSelector);

  const { control, watch } = useFormContext();
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'languages',
    keyName: '_id',
  });
  const watchFieldArray = watch('languages');

  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...(watchFieldArray && watchFieldArray[index]),
  }));

  useEffect(() => {
    dispatch(getLanguages());
  }, [dispatch]);

  useEffect(() => {
    if (items?.length && !controlledFields.length) {
      replace(items);
    }
  }, [items, controlledFields.length, replace]);

  return (
    <>
      {controlledFields.map((field, index) => (
        <Grid key={field._id} container xs={12} spacing={2} mb={1}>
          <Grid data-test="select-lang" xs>
            <Controller
              control={control}
              name={`languages.${index}.langId`}
              render={({ field: { ref, ...field } }) => (
                <MuiSelect
                  {...field}
                  inputRef={ref}
                  displayEmpty
                  labelId="select-lang"
                  input={<BootstrapInput />}
                  value={field.value || ''}
                  sx={{ textTransform: 'capitalize' }}
                >
                  <MenuItem disabled value="">
                    {smallScreen ? 'Language' : 'Select Language'}
                  </MenuItem>
                  {langs?.map(({ id, name }: { id: number; name: string }) => (
                    <MenuItem
                      data-test="lang-menu-item"
                      disabled={
                        !!watchFieldArray?.find((item: any) => parseInt(item.langId) === id)
                      }
                      key={name}
                      sx={{ textTransform: 'capitalize' }}
                      value={id}
                    >
                      {name.charAt(0) + name.slice(1).toLowerCase()}
                    </MenuItem>
                  ))}
                </MuiSelect>
              )}
              rules={{ required: 'This field is required' }}
            />
          </Grid>
          <Grid data-test="select-level-lang" xs>
            <Controller
              control={control}
              name={`languages.${index}.level`}
              render={({ field: { ref, ...field } }) => (
                <MuiSelect
                  {...field}
                  inputRef={ref}
                  displayEmpty
                  labelId="select-level"
                  input={<BootstrapInput />}
                  value={field.value || ''}
                  sx={{ textTransform: 'capitalize' }}
                >
                  <MenuItem disabled value="">
                    {smallScreen ? 'Level' : 'Select Level'}
                  </MenuItem>
                  {Object.values(ELangLevel).map((level: string) => (
                    <MenuItem
                      data-test="level-menu-item"
                      key={level}
                      sx={{ textTransform: 'capitalize' }}
                      value={level}
                    >
                      {level}
                    </MenuItem>
                  ))}
                </MuiSelect>
              )}
              rules={{ required: 'This field is required' }}
            />
          </Grid>
          <Grid sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              onClick={() => {
                if (controlledFields.length <= 1) {
                  replace({ ...DEFAULT_USER_LANGUAGE, langId: 1 });
                } else {
                  remove(index);
                }
              }}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Grid mdOffset={9} xs={12} md={3} display="flex" justifyContent={{ md: 'end' }}>
        <AddButton
          data-test="add-language-button"
          variant="text"
          color="secondary"
          onClick={() => append({ ...DEFAULT_USER_LANGUAGE })}
          disableRipple
        >
          <AddIcon />
          <Typography fontWeight={500} fontSize={14}>
            Add language
          </Typography>
        </AddButton>
      </Grid>
    </>
  );
};

export default Languages;
