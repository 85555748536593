import { Components } from '@mui/material/styles';

const MuiInputBase: Components['MuiInputBase'] = {
  styleOverrides: {
    root: {
      fontSize: '1.125rem',
      width: '100%',
      paddingRight: 16,
      backgroundColor: '#f5f5f5',
      border: '1px solid #f5f5f5',
      '&:focus': {
        borderColor: '#171717',
      },
    },
    input: ({ theme }: any) => ({
      WebkitAppearance: 'none',
      MozAppearance: 'none',
      appearance: 'none',
      minHeight: 46,
      position: 'relative',
      padding: '0px 12px',
      transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
      borderRadius: 0,
      textAlign: 'inherit',
      '&::placeholder': {
        fontSize: '0.875rem',
        color: '#5D5D5E',
        opacity: 1,
        textAlign: 'start',
      },
    }),
  },
};

export default MuiInputBase;
