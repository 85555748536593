export const agencyTeamHeadCells: any[] = [
  {
    id: 'name',
    label: 'Name',
    isSortable: false,
    // width: '115',
    width: { xs: 88, lg: 115 },
  },
  {
    id: 'email',
    label: 'Email',
    isSortable: true,
    // width: '160',
    width: { xs: 120, lg: 160 },
  },
  {
    id: 'isActive',
    label: 'Status',
    isSortable: true,
    // width: '170',
    minWidth: '106',
    width: { xs: 130, lg: 170 },
  },
  {
    id: 'roleId',
    label: 'Role',
    isSortable: false,
    // width: 'auto',
    width: { xs: 70, lg: 'auto' },
  },
  {
    id: 'impersonate',
    label: null,
    isSortable: false,
    // width: '120',
    width: { xs: 78, lg: 120 },
  },
  {
    id: 'availability',
    label: 'Availability',
    isSortable: false,
    // width: '112',
    width: { xs: 93, lg: 112 },
  },
  {
    id: 'actions',
    label: 'Actions',
    isSortable: false,
    // width: '112',
    width: { xs: 90, lg: 112 },
  },
];

export const teamHeadCells: any[] = [
  {
    id: 'email',
    label: 'Email',
    isSortable: true,
    width: 'auto',
  },
  {
    id: 'isActive',
    label: 'Status',
    isSortable: true,
    width: '300',
  },
  {
    id: 'roleId',
    label: 'Role',
    isSortable: true,
    width: '312',
  },
  {
    id: 'actions',
    label: 'Actions',
    isSortable: false,
    width: '112',
  },
];

export const purchaseOrderHeadCells: any[] = [
  {
    id: 'name',
    label: 'Name',
    isSortable: true,
    width: 'auto',
  },
  {
    id: 'status',
    label: 'Status',
    isSortable: true,
    width: '120',
  },
  {
    id: 'amountCents',
    label: 'Progress',
    isSortable: true,
    width: '120',
  },
  {
    id: 'balanceCents',
    label: 'Left',
    isSortable: true,
    width: '120',
  },
  {
    id: 'startDate',
    label: 'Date',
    isSortable: true,
    width: '210',
  },
  {
    id: 'acceptedUsers',
    label: 'Users',
    isSortable: true,
    width: '210',
  },
  {
    id: 'actions',
    align: 'center',
    label: 'Actions',
    isSortable: false,
    width: '80',
  },
];
