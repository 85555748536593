import { createAsyncThunk} from '@reduxjs/toolkit';
import instance from '../../middlewares/api';
import { ECompany } from '../../constants';

const searchCompanyByName = createAsyncThunk(
  ECompany.searchCompanyByName,
  async (name: string) => {
    const { data } = await instance.get(`companies/search/?search=${name}`);
    return data;
  }
)

export default searchCompanyByName;
