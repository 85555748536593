import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import NoSsr from '@mui/material/NoSsr';
import Typography from '@mui/material/Typography';

import SortDescIcon from 'styles/icons/SortDescIcon';
import PlusIcon from 'styles/icons/PlusIcon';
import ArrowLeftIcon from 'styles/icons/ArrowLeft';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { EOrderFilter, ESorting } from 'models/consts';
import ROUTES from 'router/constants';

import fetchPurchaseOrder from 'store/query/enterprise/fetchPurchaseOrder';
import { purchaseOrderSelector } from 'store/selectors/getEnterpriseSelector';
import {
  getMeSelector,
  isAgencyResourceManagerSelector,
  isSelfCustomerSelector,
  isEnterpriseCustomerSelector,
} from 'store/selectors/getUserSelector';
import { getBasicConsultantSelector } from 'store/selectors/getConsultantSelector';

import { OrderListTab, ToggleOrders } from 'components/Order/OrderList';

const OrderList: FunctionComponent = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const user = useAppSelector(getMeSelector);
  const consultant = useAppSelector(getBasicConsultantSelector(user?.id));

  const purchaseOrder = useAppSelector(purchaseOrderSelector(id));

  const enterpriseCustomer = useAppSelector(isEnterpriseCustomerSelector);
  const isSelfCustomer = useAppSelector(isSelfCustomerSelector);
  const isResourceManager = useAppSelector(isAgencyResourceManagerSelector);

  const [filter, setFilter] = React.useState<EOrderFilter>(
    (id && isResourceManager && EOrderFilter.UNASSIGNED) || EOrderFilter.CURRENT,
  );
  const [sorting, setSorting] = useState<ESorting>(ESorting.ASC);

  const handleChange = (event: any, newFilter: any) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };

  const dispatch = useAppDispatch();

  const changeSorting = () => {
    setSorting(sorting => (sorting === ESorting.ASC ? ESorting.DESC : ESorting.ASC));
  };

  useEffect(() => {
    if (enterpriseCustomer && id) {
      dispatch(fetchPurchaseOrder(id));
    }
  }, [dispatch, enterpriseCustomer, id]);

  return (
    <NoSsr>
      {enterpriseCustomer && (
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button
            color="secondary"
            onClick={() => navigate(ROUTES.ORDER_LIST)}
            sx={{ display: { xs: 'none', md: 'inline-flex' } }}
            variant="outlined"
          >
            Back to orders
          </Button>
          <IconButton
            onClick={() => navigate(ROUTES.ORDER_LIST)}
            sx={{ display: { md: 'none' }, ml: -2.5 }}
          >
            <ArrowLeftIcon />
          </IconButton>
          <Button
            id="create-an-order"
            component={Link}
            variant="contained"
            color="secondary"
            startIcon={<PlusIcon />}
            sx={{ display: { md: 'none' } }}
            to={ROUTES.ORDER_CREATION + '/1'}
            state={purchaseOrder}
          >
            Create an order
          </Button>
        </Box>
      )}
      {user && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={{ md: 7, xs: 2 }}
            mb={{ sm: 3 }}
          >
            <Typography mb={{ md: 3 }} alignSelf="center" variant="h1">
              {(!enterpriseCustomer && 'My Orders') || purchaseOrder?.name}
            </Typography>
            {isSelfCustomer && (
              <Button
                id="create-order-button-header-sm"
                component={Link}
                variant="contained"
                color="secondary"
                startIcon={<PlusIcon />}
                sx={{ display: { md: 'none' }, height: 40, px: 2.5 }}
                to={ROUTES.ORDER_CREATION + '/1'}
                state={purchaseOrder}
              >
                Create an order
              </Button>
            )}
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
            <ToggleOrders
              isAgency={!!consultant?.agency}
              value={filter}
              handleChange={handleChange}
              userRole={user?.role}
            />
            <IconButton
              onClick={changeSorting}
              size="small"
              sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
            >
              {sorting === ESorting.DESC ? (
                <SortDescIcon />
              ) : (
                <SortDescIcon sx={{ transform: 'rotateX(180deg)' }} />
              )}
            </IconButton>
          </Box>

          <Button
            color="secondary"
            fullWidth
            onClick={changeSorting}
            startIcon={
              sorting === ESorting.DESC ? (
                <SortDescIcon />
              ) : (
                <SortDescIcon sx={{ transform: 'rotateX(180deg)' }} />
              )
            }
            sx={{ display: { sm: 'none' }, mb: 3 }}
            variant="outlined"
          >
            {sorting === ESorting.DESC ? 'Sort by Start date' : 'Sort by End date'}
          </Button>

          <OrderListTab filter={filter} sortStartDate={sorting} />
        </>
      )}
    </NoSsr>
  );
};

export default OrderList;
