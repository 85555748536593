import React, { FunctionComponent, useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';

import CloseIcon from 'styles/icons/CloseIcon';
import { StyledCloseBtn, StyledSubtitle } from './styled';

interface IDialogTitle {
  title?: string;
  subTitle?: string;
}
interface IExtendDialogProps extends DialogProps {
  'data-test'?: string;
}

interface IDialogProps {
  children?: React.ReactNode;
  title?: IDialogTitle;
  dialogProps: IExtendDialogProps;
  closeIcon?: React.ReactElement;
}

const ModalWrapper: FunctionComponent<IDialogProps> = ({
  children,
  closeIcon,
  dialogProps,
  title,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const isMobileNative = Capacitor.isNativePlatform();
  const getPlatform = Capacitor.getPlatform();

  const [mobKeyboardHgt, setMobKeyboardHgt] = useState(0);

  useEffect(() => {
    if (isMobileNative) {
      Keyboard.setAccessoryBarVisible({ isVisible: true });
      Keyboard.setScroll({ isDisabled: false });

      Keyboard.addListener('keyboardWillShow', info => {
        setMobKeyboardHgt(info.keyboardHeight);
      });
      Keyboard.addListener('keyboardWillHide', () => {
        setMobKeyboardHgt(0);
      });
    }

    return () => {
      if (isMobileNative) {
        Keyboard.removeAllListeners();
      }
    };
  }, [isMobileNative]);

  return (
    <Dialog {...dialogProps} fullScreen={fullScreen} fullWidth={true}>
      <DialogTitle mt={{ xs: undefined, md: 4 }}>
        <Typography
          data-test="modal-title"
          component="p"
          fontSize="inherit"
          lineHeight="normal"
          fontWeight="700"
        >
          {title && title.title}
        </Typography>
        <StyledSubtitle id="customer-register-header" component="p" variant="h6">
          {title && title.subTitle}
        </StyledSubtitle>
        {dialogProps.onClose && (
          <StyledCloseBtn
            onClick={e => dialogProps.onClose && dialogProps.onClose(e, 'escapeKeyDown')}
          >
            {(closeIcon && closeIcon) || <CloseIcon />}
          </StyledCloseBtn>
        )}
      </DialogTitle>
      <Box
        sx={{
          marginBottom: isMobileNative && getPlatform === 'ios' ? `${mobKeyboardHgt}px` : undefined,
        }}
      >
        {children}
      </Box>
    </Dialog>
  );
};

export default ModalWrapper;
