import React, { FunctionComponent } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { IModalProps } from 'store/types/common';
import { IBasicOrders } from 'store/types/order';
import { EOrderFilter } from 'models/consts';

import { getMeRoleSelector } from 'store/selectors/getUserSelector';
import orderChangeName from 'store/query/order/orderChangeName';

import ModalWrapper from 'components/Modal/Modal';
import BootstrapInput from 'components/CustomFields/BootstrapInput';

interface IRenameModal extends IModalProps {
  order: IBasicOrders;
  filter: EOrderFilter;
}

const ChangeNameModal: FunctionComponent<IRenameModal> = ({ filter, visible, close, order }) => {
  const dispatch = useAppDispatch();

  const role = useAppSelector(getMeRoleSelector);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: order.name,
    },
  });

  const onSubmit: SubmitHandler<any> = (data: any) => {
    dispatch(
      orderChangeName({
        id: +order.id,
        key: `${order?.purchaseOrderId}` || 'nonEnterprise',
        name: data.name,
        filter,
        role,
        isPurchase: !!order.purchaseOrder,
      }),
    )
      .unwrap()
      .then(() => close());
  };

  const onClose = () => {
    reset();
    close();
  };

  return (
    <ModalWrapper
      dialogProps={{
        open: visible,
        onClose,
      }}
      title={{ title: 'Title of the order' }}
    >
      <DialogContent>
        <form id="title-order" onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <InputLabel>Create a title for your order</InputLabel>
            <Controller
              control={control}
              name="name"
              render={({ field: { ref, ...field } }) => (
                <BootstrapInput
                  {...field}
                  inputRef={ref}
                  error={!!errors.name}
                  helperText={errors.name?.message || 'Incorrect data'}
                  placeholder="Order title"
                />
              )}
              rules={{ required: 'This field is required' }}
            />
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!isValid}
          color="secondary"
          form="title-order"
          fullWidth
          type="submit"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </ModalWrapper>
  );
};

export default ChangeNameModal;
