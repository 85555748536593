import {Link} from "react-router-dom";

import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const StyledPaper = styled(Paper)(({ theme }) => theme.unstable_sx({
  borderBottom: '1px solid #F5F5F5',
  boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.10)',
}))

const StyledItemWrap = styled(Box)(({ theme }) => theme.unstable_sx({
  pb: 2.5,
  width: '100%',
  position: 'relative'
}))

const StyledItemHeader = styled(Box)(({ theme }) => theme.unstable_sx({
  height: {
    md: 69
  },
  minHeight: {
    md: 30
  }
}))

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.secondary.main,
  fontSize: '1.125rem',
  fontWeight: 700,
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  lineHeight: 'normal'
}))

const StyledTimelineWrap = styled(Box)({
  backgroundColor: '#F9F9F9',
  borderRadius: 2,
})

const StyledColaborator = styled(Typography)({
  fontSize: '.875rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
  textWrap: 'nowrap'
})

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.common.white,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
  },
  [`&.in_progress .${linearProgressClasses.bar}, &.active .${linearProgressClasses.bar}`]: {
    backgroundColor: '#C0FFC7'
  },
  [`&.canceled .${linearProgressClasses.bar}`]: {
    backgroundColor: '#FFF1C0'
  },
  [`&.completed .${linearProgressClasses.bar}`]: {
    backgroundColor: '#D8E3FD'
  },
  [`&.created .${linearProgressClasses.bar}, &.match .${linearProgressClasses.bar}`]: {
    backgroundColor: '#FFF1C0'
  },
}));

export { BorderLinearProgress, StyledItemHeader, StyledColaborator, StyledItemWrap, StyledLink, StyledPaper, StyledTimelineWrap }
