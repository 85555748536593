import { createAsyncThunk } from "@reduxjs/toolkit";
import { Cookies } from "react-cookie";
import { Capacitor } from "@capacitor/core";

import instance from "../../middlewares/api";
import { EUser } from "../../constants";
import { ELocalStoreKeys, ERole, mobileCookieMaxAge } from "models/consts";
import fetchMe from "./fetchMe";
import { removeAuthTokens, removeImpersonateTokens } from "utils/apiCallHelpers";

const cookies = new Cookies(null, { path: '/', sameSite: 'lax' })
const isMobileNative = Capacitor.isNativePlatform();

interface ISignupProps {
  email: string;
  password: string;
  code?: string;
  role: ERole;
  wbSource?: string;
}

const signup = createAsyncThunk<any, ISignupProps>(
  EUser.signup,
  async (payload, thunkAPI) => {
    let response;

    if (payload.code &&
      (payload.role === ERole.CONSULTANT || payload.role === ERole.LEAD_MANAGER || payload.role === ERole.RESOURCE_MANAGER)) {
      const { email, role, ...data} = payload
      response = await instance.post('agency/users-team/registration-user', data);
    } else {
      response = await instance.post('auth/registration', payload);
    }

    const { data: { accessToken, refreshToken, ...data } } = response

    removeAuthTokens();
    removeImpersonateTokens();

    cookies.set(ELocalStoreKeys.ACCESS_TOKEN, accessToken, {maxAge: isMobileNative ? mobileCookieMaxAge : undefined})
    cookies.set(ELocalStoreKeys.REFRESH_TOKEN, refreshToken, {maxAge: isMobileNative ? mobileCookieMaxAge : undefined})

    await thunkAPI.dispatch(fetchMe())

    return data;
  }
)

export default signup;
