import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import FormGroup from '@mui/material/FormGroup';
import LoadingButton from '@mui/lab/LoadingButton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import getPurchaseOrders from 'store/query/enterprise/getPurchaseOrders';
import editPreorder from 'store/query/order/editPreorder';
import { getPurchaseOrdersSelector } from 'store/selectors/getEnterpriseSelector';

import CustomAutocomplete from 'components/CustomFields/CustomAutocomplete';

import { IBasicPreOrder } from 'store/types/preOrder';
import ROUTES from 'router/constants';

export interface ISetPurchaseOrderForm {
  order: IBasicPreOrder;
  role: any;
  onSubmitForm?: () => void;
}

const SetPurchaseOrderForm: React.FunctionComponent<ISetPurchaseOrderForm> = ({
  order,
  role,
  onSubmitForm,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const purchaseOrders = useAppSelector(getPurchaseOrdersSelector);
  const purchaseOrdersOptions = purchaseOrders?.map(item => ({
    value: item.id,
    name: item.name,
  }));
  const defaultPurchaseOrder =
    !!order.purchaseOrderId && !!purchaseOrdersOptions?.length
      ? purchaseOrdersOptions.find(item => item.value === order?.purchaseOrderId)
      : undefined;

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      purchaseOrder: !!order.purchaseOrderId
        ? purchaseOrdersOptions?.find(item => item.value === order?.purchaseOrderId)
        : undefined,
    },
  });

  const currentPurchaseOrder = watch('purchaseOrder');

  useEffect(() => {
    dispatch(getPurchaseOrders({}));
  }, [dispatch]);

  useEffect(() => {
    if (defaultPurchaseOrder && !currentPurchaseOrder) {
      setValue('purchaseOrder', defaultPurchaseOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPurchaseOrder?.value, currentPurchaseOrder]);

  const onSubmit = async (data: any) => {
    dispatch(
      editPreorder({
        preorder: {
          purchaseOrderId: data.purchaseOrder.value,
          id: order.id,
        },
        role,
      }),
    )
      .unwrap()
      .then(response => {
        if (!!response.length) {
          onSubmitForm && onSubmitForm();
        }
      });
  };

  const handleSaveClose = async (data: any) => {
    await onSubmit(data);
    navigate(`${ROUTES.PRE_ORDER}/${order.id}`);
  };

  return (
    <form id="order-form" onSubmit={handleSubmit(onSubmit)}>
      <Typography component="div" variant="body1" sx={{ mb: 1 }}>
        In order to proceed with this order, we need to finalize paperwork
      </Typography>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid xs={12} md={6} lg={4}>
          <FormGroup>
            <InputLabel>Order Form</InputLabel>
            <FormControl>
              <Controller
                control={control}
                name="purchaseOrder"
                render={({ field }) => (
                  <CustomAutocomplete
                    autocompleteProps={{
                      multiple: undefined,
                    }}
                    inputProps={{
                      readOnly: true,
                      error: !!errors.purchaseOrder,
                      helperText: (errors.purchaseOrder as any)?.message || 'Incorrect data',
                    }}
                    field={field}
                    options={purchaseOrdersOptions || []}
                    placeholder="Select Order Form"
                  />
                )}
                rules={{ required: 'This field is required' }}
              />
            </FormControl>
          </FormGroup>
        </Grid>
      </Grid>

      <Grid container xs={12} spacing={2} justifyContent="center">
        <Grid xs={12} md="auto">
          <FormGroup>
            <LoadingButton
              color="primary"
              loading={isSubmitting}
              form="order-form"
              type="submit"
              fullWidth
              disabled={!isValid || isSubmitting}
              variant="contained"
            >
              Save
            </LoadingButton>
          </FormGroup>
        </Grid>
        <Grid xs={12} md="auto">
          <LoadingButton
            color="secondary"
            loading={isSubmitting}
            form="review-preorder-form"
            type="button"
            fullWidth
            disabled={!isValid || isSubmitting}
            variant="contained"
            onClick={handleSubmit(handleSaveClose)}
          >
            Save & Close
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default SetPurchaseOrderForm;
