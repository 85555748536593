import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { StateMachineProvider } from 'little-state-machine';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import ROUTES from 'router/constants';
import { IBasicUser } from 'store/types/user';
import { IBasicConsultant } from 'store/types/consultant';

import useScrollToTop from 'hooks/useScrollToTop';

import { useAppSelector } from 'store/hooks';
import { getMeSelector } from 'store/selectors/getUserSelector';
import { getBasicConsultantSelector } from 'store/selectors/getConsultantSelector';
import { getAgencyByIdSelector } from 'store/selectors/getAgencySelector';
import { DialogContent } from '@mui/material';

import { EAgencyRegStep, EConsultantRegProgress, EConsultantRegStep, ERole } from 'models/consts';

import { StyledFluid, StyledRoot, StyledWrap } from './styled';

import NavbarMenu from 'components/NavbarMenu/NavbarMenu';
import Header from 'components/Header/Header';
import FloatingFeedbackDialog from 'components/Modal/FloatingFeedbackDialog/FloatingFeedbackDialog';
import ModalWrapper from 'components/Modal/Modal';
import UserAgreement from 'views/Auth/ConsultantRegister/UserAgreement/UserAgreement';
import { FormProvider, useForm } from 'react-hook-form';
const AuthorizedLayout: FunctionComponent = () => {
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [showFeedbackDialog, setShowFeedbackDialog] = useState<boolean>(false);
  const user = useAppSelector(getMeSelector) as IBasicUser;
  const consultant = useAppSelector(getBasicConsultantSelector(user?.id)) as IBasicConsultant;
  const [modal, setModal] = useState(!consultant?.agreements ? true : false);

  const closeModal = () => {
    setModal(false);
  };
  const methods = useForm<any>({
    mode: 'onChange',
  });
  useScrollToTop(ref);
  const agency = useAppSelector(getAgencyByIdSelector(user?.agencyId));

  const consultantRegistrationProgress = useMemo(() => {
    if (user?.role.name === ERole.CONSULTANT) {
      if (consultant.isApproved && !consultant?.agencyId) {
        if (consultant.agreements) {
          return EConsultantRegProgress.COMPLETE;
        }
        return EConsultantRegProgress.MISSING_ACCEPT_TERMS;
      } else {
        if (consultant.callScheduled) {
          return EConsultantRegProgress.CONSULTANT_UNVETTED;
        } else if (consultant.agencyId) {
          return consultant.registrationStep >= EConsultantRegStep.PROFICIENCY
            ? EConsultantRegProgress.COMPLETE
            : EConsultantRegProgress.REGISTRATION_INCOMPLETE;
        }
        return EConsultantRegProgress.CALL_UNSCHEDULED;
      }
    }
    return EConsultantRegProgress.NOT_CONSULTANT;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultant]);

  useEffect(() => {
    if (consultant && !consultant.isConfirm) {
      navigate(ROUTES.SIGNUP_CODE);
      return;
    }
    if (consultantRegistrationProgress > EConsultantRegProgress.NOT_CONSULTANT) {
      if (consultantRegistrationProgress <= EConsultantRegProgress.CALL_UNSCHEDULED) {
        navigate(
          `${ROUTES.CONSULTANT_REGISTRATION}/${consultant.registrationStep + 1}`,
          consultant.agencyId ? { state: { isAgencyAddingUser: true } } : {},
        );
      }
    } else if (user?.role.name === ERole.AGENCY_ADMIN && !agency?.location) {
      navigate(`${ROUTES.AGENCY_CREATION}/${EAgencyRegStep.PROFILE}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agency?.location, consultant, navigate, user?.role.name]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflowX: 'hidden' }}>
      <StateMachineProvider>
        <Header />
      </StateMachineProvider>
      <StyledRoot>
        <StyledFluid>
          <StyledWrap ref={ref}>
            <Outlet />
            {consultantRegistrationProgress === EConsultantRegProgress.MISSING_ACCEPT_TERMS && (
              <ModalWrapper
                dialogProps={{
                  open: modal,
                }}
                title={{
                  title: 'User Agreement',
                }}
              >
                <>
                  <DialogContent>
                    <FormProvider {...methods}>
                      <UserAgreement close={closeModal} />
                    </FormProvider>
                  </DialogContent>
                </>
              </ModalWrapper>
            )}
          </StyledWrap>
        </StyledFluid>
      </StyledRoot>

      <FloatingFeedbackDialog
        visible={showFeedbackDialog}
        close={() => setShowFeedbackDialog(false)}
      />
      <Box
        sx={{
          display: { lg: 'flex', md: 'flex', sm: 'none', xs: 'none' },
          position: 'fixed',
          bottom: 80,
          right: 16,
        }}
      >
        <Tooltip title="Leave Feedback" arrow>
          <IconButton onClick={() => setShowFeedbackDialog(true)} size="large" sx={{ p: 0 }}>
            <img src="/img/feedbackIcon.png" height={'100%'} />
          </IconButton>
        </Tooltip>
      </Box>
      <NavbarMenu />
    </Box>
  );
};

export default AuthorizedLayout;
