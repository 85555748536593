import React, { FunctionComponent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input/input';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { IBasicConsultant } from 'store/types/consultant';
import { getExtendMeByRoleSelector } from 'store/selectors/getUserSelector';
import updateConsultantInfo from 'store/query/consultant/updateConsultantInfo';

import UserEmail from '../UserEmail/UserEmail';
import { PhoneNumber } from 'components/CustomFields';

const ConsultantSettings: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const consultant = useAppSelector(getExtendMeByRoleSelector) as IBasicConsultant;

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty, errors },
    trigger,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      mobilePhone: {
        country: parsePhoneNumber(consultant?.mobilePhone || '')?.country || 'US',
        countryCallingCode: parsePhoneNumber(consultant?.mobilePhone || '')?.countryCallingCode,
        nationalNumber: parsePhoneNumber(consultant?.mobilePhone || '')?.nationalNumber,
      },
    },
  });

  const mobilePhone = watch('mobilePhone');

  const onSubmit: SubmitHandler<any> = ({ mobilePhone }: any) => {
    dispatch(
      updateConsultantInfo({
        mobilePhone: [
          '+',
          getCountryCallingCode(mobilePhone.country),
          mobilePhone.nationalNumber,
        ].join(''),
      }),
    );
  };

  return (
    <>
      <Typography mt={{ xs: 2.5, md: 7 }} variant="h1" data-test="contact-page">
        Contact details
      </Typography>
      <Divider sx={{ mb: 3, mt: 1 }} />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container md={6} spacing={2}>
          <Grid xs={12}>
            <UserEmail visible />
          </Grid>
          <Grid xs={12}>
            <FormGroup sx={{ mb: 4 }}>
              <InputLabel>Phone number</InputLabel>
              <PhoneNumber
                control={control}
                name="mobilePhone"
                defaultValue={mobilePhone}
                rules={{
                  required: 'This field is required',
                }}
                error={!!errors.mobilePhone?.nationalNumber || !!errors.mobilePhone?.country}
                errorText={
                  (errors.mobilePhone?.nationalNumber as any)?.message ||
                  (errors.mobilePhone?.country as any)?.message
                }
                trigger={trigger}
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent={{ xs: 'center', md: 'start' }}>
          <Button disabled={!isValid || !isDirty} variant="contained" type="submit">
            Save updates
          </Button>
        </Box>
      </form>
    </>
  );
};

export default ConsultantSettings;
