import React, { FC, useState } from 'react';

import Picker, { EmojiClickData } from 'emoji-picker-react';

import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';

import SmileIcon from 'styles/icons/Smile';

const EmojiBtn: FC<any> = ({ handleValue, isiPadOrMobile, value }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEmojiClick = ({ emoji }: EmojiClickData) => {
    handleValue('message', `${value}${emoji}`);
  };

  return (
    <>
      <Popover
        id={id}
        open={openPopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Picker onEmojiClick={onEmojiClick} />
      </Popover>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        size={isiPadOrMobile ? 'medium' : 'large'}
      >
        <SmileIcon />
      </IconButton>
    </>
  );
};

export default EmojiBtn;
