import { Components } from '@mui/material/styles';

const MuiFormGroup: Components['MuiFormGroup'] = {
  styleOverrides: {
    root: ({ theme }: any) => theme.unstable_sx({
      mb: 2.5,
    })
  },
}

export default MuiFormGroup
