import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from 'store/middlewares/api';
import { ECompany } from 'store/constants';

const checkCompanyByName = createAsyncThunk(
  ECompany.checkCompanyByName,
  async (name: string) => {
    const { data } = await instance.get(`companies/check?search=${name}`);
    return data;
  }
)

export default checkCompanyByName;
