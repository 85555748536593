import React, { FC } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import InfoIcon from 'styles/icons/InfoIcon';

interface ICustomTooltip {
  placement:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
    | undefined;
  title: string;
  disableFocusListener?: boolean;
  sx?: any;
}
const CustomTooltip: FC<ICustomTooltip> = ({ sx, ...props }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        {...props}
        arrow
        leaveTouchDelay={5000}
        onClose={handleTooltipClose}
        onOpen={handleTooltipOpen}
        open={open}
      >
        <IconButton onClick={handleTooltipOpen} size="small" sx={sx}>
          <InfoIcon />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default CustomTooltip;
