import { createSlice } from '@reduxjs/toolkit';

import { IDynamicKey, IEntitiesIds } from '../types/common';
import { IEnterpriseUser, IPurchaseOrder } from '../types/enterprise';

import usersTeam from '../query/enterprise/usersTeam';
import changeUserStatus from '../query/enterprise/changeUserStatus';
import changeUserRole from '../query/enterprise/changeUserRole';
import getPurchaseOrders from '../query/enterprise/getPurchaseOrders';
import fetchPurchaseOrder from '../query/enterprise/fetchPurchaseOrder';
import inviteUser from '../query/enterprise/inviteUser';
import acceptUsersOrders from '../query/enterprise/acceptUsersOrders';
import searchUsersTeam from '../query/enterprise/searchUsersTeam';
import { DEFAULT_ENTITY_IDS, LIMITS } from '../../models/consts';

const addUsers = (users: IEnterpriseUser[]) => (idToUserMap: IDynamicKey<IEnterpriseUser>) =>
  users.reduce((userMap, user) => {
    if (!userMap[user.id.toString()]) {
      userMap[user.id.toString()] = user;
    }
    return userMap;
  }, idToUserMap);

const addPurchaseOrders =
  (purchaseOrders: IPurchaseOrder[]) => (idToPurchaseOrderMap: IDynamicKey<IPurchaseOrder>) =>
    purchaseOrders.reduce((purchaseOrderMap, purchaseOrder) => {
      if (!purchaseOrderMap[purchaseOrder.id.toString()]) {
        purchaseOrderMap[purchaseOrder.id.toString()] = purchaseOrder;
      }
      return purchaseOrderMap;
    }, idToPurchaseOrderMap);

export interface EnterpriseState {
  currentPurchaseOrder: IPurchaseOrder | any;
  isFetchingInvite: boolean;
  loading: boolean;
  loaded: boolean;
  purchaseOrders: IDynamicKey<IEntitiesIds>;
  purchaseOrdersById: IDynamicKey<IPurchaseOrder>;
  purchaseOrdersFilter: string;
  ordersById: IDynamicKey<IPurchaseOrder>;
  searchUsers: IDynamicKey<IEnterpriseUser[]>;
  users: IDynamicKey<IEntitiesIds>;
  usersById: IDynamicKey<IEnterpriseUser>;
  usersFilter: string;
}

const initialState: EnterpriseState = {
  currentPurchaseOrder: {},
  isFetchingInvite: false,
  loading: false,
  loaded: false,
  purchaseOrders: {},
  purchaseOrdersById: {},
  purchaseOrdersFilter: '',
  ordersById: {},
  searchUsers: {},
  users: {},
  usersById: {},
  usersFilter: '',
};

const { reducer } = createSlice({
  name: 'enterprise',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(acceptUsersOrders.pending, state => {
        state.loading = true;
      })
      .addCase(acceptUsersOrders.rejected, state => {
        state.loading = false;
      })
      .addCase(acceptUsersOrders.fulfilled, (state, { payload, meta }) => {
        const [orderId] = meta.arg;
        if (state.purchaseOrdersById[orderId]) {
          state.purchaseOrdersById[orderId].acceptedUsers = payload;
        }
        state.loading = false;
      })
      .addCase(changeUserRole.fulfilled, (state, action) => {
        const { meta } = action;
        meta.arg.forEach(change => {
          const user = state.usersById[change.userId];
          if (user) {
            user.role = {
              ...user.role,
              id: change.roleId,
            };
          }
        });
      })
      .addCase(changeUserStatus.fulfilled, (state, action) => {
        const { meta } = action;

        meta.arg.forEach(change => {
          const user = state.usersById[change.userId];
          if (user) {
            user.isActive = !user.isActive;
          }
        });
      })
      .addCase(getPurchaseOrders.pending, (state, action) => {
        const { meta } = action;
        const key = meta.arg.key || JSON.stringify(meta.arg.filter);
        if (state.purchaseOrders[key]) {
          state.purchaseOrders[key].loaded = false;
          state.purchaseOrders[key].loading = true;
        }
        if (!state.purchaseOrders[key]) {
          state.purchaseOrders[key] = DEFAULT_ENTITY_IDS;
        }
      })
      .addCase(getPurchaseOrders.fulfilled, (state, action) => {
        const { meta, payload } = action;
        const key = meta.arg.key || JSON.stringify(meta.arg.filter);

        state.purchaseOrdersFilter = key;
        if (!state.purchaseOrders[key]?.ids.length) {
          state.purchaseOrders[key].ids = Array(payload.count).fill(null);
        }

        if (payload.purchaseOrders.length) {
          state.purchaseOrders[key].ids.splice(
            meta.arg.offset,
            LIMITS.team,
            ...payload.purchaseOrders.map((purchaseOrder: IPurchaseOrder) => purchaseOrder.id),
          );
          addPurchaseOrders(payload.purchaseOrders)(state.purchaseOrdersById);
        }

        state.purchaseOrders[key].page = meta.arg.offset / meta.arg.limit + 1;
        state.purchaseOrders[key].countAll = payload.count;
        state.purchaseOrders[key].hasMore =
          !!payload.purchaseOrders.length && state.purchaseOrders[key].ids.some(id => !id);
        state.purchaseOrders[key].loaded = true;
        state.purchaseOrders[key].loading = false;
      })
      .addCase(inviteUser.pending, state => {
        state.isFetchingInvite = true;
      })
      .addCase(inviteUser.rejected, state => {
        state.isFetchingInvite = false;
      })
      .addCase(inviteUser.fulfilled, (state, { payload }) => {
        state.isFetchingInvite = false;
        payload.forEach(user => {
          state.users[state.usersFilter].ids.push(user.id);
          state.users[state.usersFilter].countAll = state.users[state.usersFilter].countAll + 1;
          state.usersById[user.id] = user;
        });
      })
      .addCase(searchUsersTeam.pending, (state, action) => {
        const { meta } = action;

        state.loading = true;
        state.searchUsers[meta.arg.filterByEmail] = [];
      })
      .addCase(searchUsersTeam.fulfilled, (state, action) => {
        const { meta, payload } = action;

        state.loading = false;
        if (payload.users) {
          state.searchUsers[meta.arg.filterByEmail].push(...payload.users);
        }
      })
      .addCase(usersTeam.pending, (state, action) => {
        const { meta } = action;
        const key = meta.arg.key || JSON.stringify(meta.arg.filter);
        if (state.users[key]) {
          state.users[key].loaded = false;
          state.users[key].loading = true;
        }
        if (!state.users[key]) {
          state.users[key] = DEFAULT_ENTITY_IDS;
        }
      })
      .addCase(usersTeam.fulfilled, (state, action) => {
        const { meta, payload } = action;
        const key = meta.arg.key || JSON.stringify(meta.arg.filter);

        state.usersFilter = key;
        if (!state.users[key]?.ids.length) {
          state.users[key].ids = Array(payload.count).fill(null);
        }

        if (payload.users.length) {
          state.users[key].ids.splice(
            meta.arg.offset,
            LIMITS.team,
            ...payload.users.map((user: IEnterpriseUser) => user.id),
          );
          addUsers(payload.users)(state.usersById);
        }

        state.users[key].page = meta.arg.offset / meta.arg.limit + 1;
        state.users[key].countAll = payload.count;
        state.users[key].hasMore = !!payload.users.length && state.users[key].ids.some(id => !id); //payload.users.length >= (meta.arg?.limit || LIMITS.team);
        state.users[key].loaded = true;
        state.users[key].loading = false;
      })

      /**
       *  Refactored 12.12
       */

      /**
       *  get Order Form by Id
       */
      .addCase(fetchPurchaseOrder.pending, (state, action) => {
        const { meta } = action;
        state.ordersById[meta.arg] = {
          ...state.ordersById[meta.arg],
        };
        state.loading = true;
      })
      .addCase(fetchPurchaseOrder.fulfilled, (state, action) => {
        const { meta, payload } = action;
        state.ordersById[meta.arg] = {
          ...state.ordersById[meta.arg],
          ...payload,
        };
        state.loading = true;
      }),
});

export default reducer;
