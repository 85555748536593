import React, { FunctionComponent, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { EOrderFilter, ESorting } from 'models/consts';
import { IPurchaseOrder } from 'store/types/enterprise';

import fetchOrderList from 'store/query/order/fetchOrderList';
import { orderListSelector, isOrdersLoadingSelector } from 'store/selectors/getOrderSelector';
import { getMeRoleSelector } from 'store/selectors/getUserSelector';

import { StyledTitleContainer } from './styled';

import OrderItem from '../../Order/OrderItem/OrderItem';
import EmptyList from '../../Order/OrderList/EmptyList';
import Loader from '../../Loader/Loader';
import ROUTES from '../../../router/constants';

interface IPurchaseItem {
  purchase: IPurchaseOrder;
}

const PurchaseItem: FunctionComponent<IPurchaseItem> = ({ purchase }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const three = useMediaQuery(theme.breakpoints.between('lg', 'xxl'));

  const key = [EOrderFilter.PURCHASE_ORDERS, purchase.id, 'ASC'].join(':') as string;
  const role = useAppSelector(getMeRoleSelector);

  const orderList = useAppSelector(orderListSelector(key));
  const orderListLoading = useAppSelector(isOrdersLoadingSelector(key));

  useEffect(() => {
    if (purchase.id) {
      dispatch(
        fetchOrderList({
          params: {
            limit: (three && 3) || 4,
            offset: 0,
            sortStartDate: ESorting.ASC,
            addStatuses: ['completed'],
          },
          filter: EOrderFilter.PURCHASE_ORDERS,
          key,
          id: purchase.id,
          role,
        }),
      );
    }
  }, [dispatch, key, purchase.id, role, three]);

  return (
    <Box mb={3}>
      <StyledTitleContainer>
        <Typography component="h3" variant="h1">
          {purchase.name}
        </Typography>
        {((three && purchase.orders.length > 3) || purchase.orders.length > 4) && (
          <MuiLink
            color="secondary"
            component={Link}
            to={`${ROUTES.ORDER_FORM_LIST}/${purchase.id}`}
            sx={{ minWidth: 60, ml: 2, textAlign: 'right' }}
          >
            View All
          </MuiLink>
        )}
      </StyledTitleContainer>
      {!!orderList.length && (
        <Grid container spacing={3}>
          {orderList?.map((order: any) => (
            <Grid xxl={3} lg={4} sm={6} xs={12} key={`order-${order.id}`}>
              <OrderItem filter={EOrderFilter.CURRENT} order={order} />
            </Grid>
          ))}
        </Grid>
      )}
      {(orderListLoading && <Loader />) ||
        (!orderList.length && (
          <Box>
            <EmptyList purchase={purchase} />
          </Box>
        ))}
    </Box>
  );
};

export default PurchaseItem;
