import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import ReactJoyride, { CallBackProps, EVENTS, ACTIONS, STATUS, Step } from 'react-joyride';
import deepmerge from 'deepmerge';
import { useStateMachine } from 'little-state-machine';
import DatePicker from 'react-datepicker';
import dayjs from 'utils/dayjs';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { isFriday, isMonday } from 'utils/dateFormatter';

import ROUTES from 'router/constants';
import { EBusinessType, EConsultantRegStep } from 'models/consts';

import { IBasicUser } from 'store/types/user';
import { IConsultantRegister } from 'store/types/inner-models';
import { IBasicConsultant } from 'store/types/consultant';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import updateAction from 'store/actions/updateAction';

import addAvailability from 'store/query/consultant/addAvailability';
import addOutDates from 'store/query/consultant/addOutDates';
import updateAvailability from 'store/query/consultant/updateAvailability';
import updateOutDates from 'store/query/consultant/updateOutDates';
import fetchAvailability from 'store/query/common/fetchAvailability';
import fetchTimePreferences from 'store/query/common/fetchTimePreferences';

import { getExtendMeByRoleSelector, getMeSelector } from 'store/selectors/getUserSelector';
import { availabilitiesSelector, timePreferencesSelector } from 'store/selectors/getCommonSelector';
import { getBasicConsultantSelector } from 'store/selectors/getConsultantSelector';

import CalendarIcon from 'styles/icons/CalendarIcon';
import { StyledH1Mobile } from '../../styled';

import Loader from 'components/Loader/Loader';
import DateTextInput from 'components/CustomFields/DateTextInput';
import { Select } from 'components/CustomFields';

const Availability: FunctionComponent<IConsultantRegister> = ({
  isModal,
  showIntro,
  onSubmitted,
}) => {
  const customButtonStyles = {
    buttonNext: {
      fontFamily: 'Visuelt Pro, Arial',
      color: '#000',
    },
  };
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();

  const { actions } = useStateMachine({ updateAction });

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const user = useAppSelector(getMeSelector) as IBasicUser;
  const consultant = useAppSelector(getBasicConsultantSelector(id || user.id)) as IBasicConsultant;
  const extendMeByRole = useAppSelector(getExtendMeByRoleSelector);

  const {
    control,
    formState: { errors },
    getFieldState,
    handleSubmit,
    resetField,
    unregister,
  } = useFormContext();

  const { availabilities, outDates } = consultant;

  const availability = useAppSelector(availabilitiesSelector);
  const timePreferences = useAppSelector(timePreferencesSelector);

  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState<Step[]>([]);
  const [joyrideZIndex, setJoyrideZIndex] = useState(10000);

  const selectedAvailability = useWatch({
    control,
    name: 'availabilityWeek',
    defaultValue: availabilities[0]?.availabilityWeek,
  });

  const availabilityDate = useWatch({
    control,
    name: 'availableFrom',
    defaultValue: availabilities[0]?.availableFrom || dayjs().toDate(),
  });

  const selectedOutDatesFrom = useWatch({
    control,
    name: 'outDates.from',
    defaultValue: outDates.length && outDates[0]?.from,
  });

  const selectedOutDatesTo = useWatch({
    control,
    name: 'outDates.to',
    defaultValue: outDates.length && outDates[0]?.to,
  });

  useEffect(() => {
    if (!availability.length) {
      dispatch(fetchAvailability());
    }
  }, [availability.length, dispatch]);

  useEffect(() => {
    if (!timePreferences.length) {
      dispatch(fetchTimePreferences());
    }
  }, [dispatch, timePreferences.length]);

  const onSubmit = async (values: any) => {
    if (!availabilities[0]?.id) {
      await dispatch(
        addAvailability({
          availabilities: [
            {
              availabilityWeek: values.availabilityWeek,
              availableFrom: values.availableFrom,
              forMeetTimeId: values.forMeetTimeId,
              forWorkTimeId: values.forWorkTimeId,
            },
          ],
          userId: id || user.id,
        }),
      )
        .unwrap()
        .then(async () => {
          if (values.outDates.from && values.outDates.to && !availabilities[0]?.id) {
            await dispatch(
              addOutDates({
                outDates: [
                  {
                    ...values.outDates,
                    text: '',
                  },
                ],
                userId: user.id,
              }),
            );
          }
        });
    } else {
      await dispatch(
        updateAvailability({
          availabilities: [
            {
              id: availabilities[0]?.id,
              availabilityWeek: values.availabilityWeek,
              availableFrom: values.availableFrom,
              forMeetTimeId: values.forMeetTimeId,
              forWorkTimeId: values.forWorkTimeId,
            },
          ],
          userId: id || user.id,
        }),
      );
      if (values.outDates.from && values.outDates.to) {
        if (!outDates.length) {
          await dispatch(
            addOutDates({
              outDates: [
                {
                  ...values.outDates,
                  text: '',
                },
              ],
              userId: id || user.id,
            }),
          );
        } else if (values.outDates?.from || values.outDates?.to) {
          await dispatch(
            updateOutDates({
              outDates: [
                {
                  ...values.outDates,
                  id: outDates[0].id,
                  text: '',
                },
              ],
              userId: id || user.id,
            }),
          );
        }
      }
    }
    if (!!consultant.agencyId) {
      actions.updateAction({ experiences: [], expertise: [] });
    }
    onSubmitted && onSubmitted();
  };

  const isEin = useMemo(
    () => consultant?.businessSetup === EBusinessType.EIN,
    [consultant?.businessSetup],
  );

  useEffect(() => {
    if (showIntro) {
      const timeoutId: any = setTimeout(() => {
        let stepsList: Step[] = [];

        if (!consultant?.availabilities[0]?.availabilityWeek) {
          stepsList.push({
            target: '.availability-week-input',
            title: 'Weekly Availability',
            content: 'On average, how many hours per week are you available for work?',
            placement: 'right',
            disableBeacon: true,
          });
        }

        if (!consultant?.availabilities[0]?.availableFrom) {
          stepsList.push({
            target: '.start-date-input',
            title: 'Start Date',
            content: 'When are you available to begin working on new projects?',
            placement: 'right',
            disableBeacon: true,
          });
        }

        if (!consultant?.outDates[0]?.from || !consultant?.outDates[0]?.to) {
          stepsList.push({
            target: '.blockout-dates-input',
            title: 'Block Out Dates',
            content: 'When are you not available?',
            placement: 'right',
            disableBeacon: true,
          });
        }

        if (
          !consultant?.availabilities[0]?.forMeetTimeId ||
          !consultant?.availabilities[0]?.forWorkTimeId
        ) {
          stepsList.push({
            target: '.availability-preference-input',
            title: 'Daily Availability',
            content: 'What hours of the day are you available to work?',
            placement: 'right',
            disableBeacon: true,
          });
        }

        setSteps(stepsList);

        if (stepsList.length) {
          stepsList.push({
            target: '.submit-button',
            title: 'Save',
            content: 'Click Save button to save your changes.',
            placement: 'right',
            disableBeacon: true,
          });

          setRun(true);
        }
      }, 700);
      return () => clearTimeout(timeoutId);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    consultant?.availabilities[0]?.availableFrom,
    consultant?.outDates[0]?.from,
    consultant?.outDates[0]?.to,
    consultant?.availabilities[0]?.availabilityWeek,
    consultant?.availabilities[0]?.forMeetTimeId,
    showIntro,
    consultant?.availabilities[0]?.forWorkTimeId,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleComponentOpen = () => {
    if (mobile) {
      setJoyrideZIndex(0);
    }
  };

  const handleComponentClose = () => {
    if (mobile) {
      setJoyrideZIndex(10000);
    }
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status, type, index } = data;
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setRun(false);
    } else if (action === ACTIONS.CLOSE || action === ACTIONS.STOP) {
      setRun(false);
    } else if (type === EVENTS.STEP_AFTER) {
      setRun(false);
      setTimeout(() => {
        setRun(true);
      }, 500);
    }
  };

  return (
    <>
      <ReactJoyride
        continuous
        run={run}
        steps={steps}
        callback={handleJoyrideCallback}
        showProgress
        disableOverlay
        scrollToFirstStep={true}
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Finish',
          next: 'Next',
          open: 'Open the dialog',
          skip: 'Skip',
        }}
        styles={deepmerge(
          {
            options: {
              backgroundColor: '#171717',
              arrowColor: '#171717',
              textColor: '#FFFFFF',
              primaryColor: '#FFCA28',
              zIndex: joyrideZIndex,
            },
          },
          customButtonStyles,
        )}
      />
      <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
        {!isModal && (
          <StyledH1Mobile component="div">
            <Typography data-test="consultant-register-header" component="h1" variant="h4" mb={3}>
              Tell us when and how you want to work:
            </Typography>
          </StyledH1Mobile>
        )}
        <FormGroup sx={{ mb: 4 }} className="availability-week-input">
          <Typography mb={2} variant="h6" sx={{ borderBottom: { md: '1px solid #ebebeb' } }}>
            Availability<sup>*</sup>
          </Typography>
          {availabilities ? (
            <>
              <Controller
                name="availabilityWeek"
                control={control}
                defaultValue={selectedAvailability}
                rules={{ required: 'Select your weekly availability' }}
                render={({ field }) => (
                  <Box {...field}>
                    {availability?.map((hours, i) => (
                      <Chip
                        key={`availbility-week-${i}`}
                        label={`${hours} hours / week`}
                        onClick={() => field.onChange(hours)}
                        color={(selectedAvailability === hours && 'secondary') || undefined}
                        sx={{ mr: 2, mb: 1 }}
                        variant={(selectedAvailability === hours && 'filled') || 'outlined'}
                        disabled={isEin && hours > 20}
                      />
                    ))}
                  </Box>
                )}
              />
              {!!errors.availabilityWeek && (
                <FormHelperText error>
                  {errors.availabilityWeek?.message?.toString() || 'Incorrect data'}
                </FormHelperText>
              )}
            </>
          ) : (
            <Loader />
          )}
        </FormGroup>
        <FormGroup className="start-date-input">
          <InputLabel>Available From</InputLabel>
          <Controller
            name="availableFrom"
            defaultValue={availabilities[0]?.availableFrom || dayjs().toDate()}
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <DatePicker
                {...field}
                isClearable
                selected={!!availabilityDate ? dayjs(availabilityDate).toDate() : dayjs().toDate()}
                onCalendarOpen={handleComponentOpen}
                onCalendarClose={handleComponentClose}
                calendarStartDay={1}
                customInput={
                  <DateTextInput
                    className="text-custom-input"
                    onReset={() => resetField('availableFrom')}
                    icon={<CalendarIcon />}
                  />
                }
                showPopperArrow={false}
                minDate={dayjs().toDate()}
                placeholderText="DD/MM/YYYY"
                withPortal={mobile}
              />
            )}
          />
          {!!errors.availableFrom && (
            <FormHelperText error>
              {errors?.availableFrom?.message?.toString() || 'Incorrect data'}
            </FormHelperText>
          )}
        </FormGroup>
        <Box mb={4} className="blockout-dates-input">
          <InputLabel>Block out dates</InputLabel>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <Controller
                name="outDates.from"
                control={control}
                defaultValue={outDates[0]?.from}
                rules={{
                  validate: {
                    beforeEnd: date => {
                      if (date) {
                        if (selectedOutDatesTo) {
                          if (!dayjs(selectedOutDatesTo).isSameOrAfter(date)) {
                            return 'Incorrect date range';
                          }
                        }
                        return undefined;
                      }
                      if (!date && getFieldState('outDates.from').isTouched) {
                        return 'Incorrect date range';
                      }
                      return undefined;
                    },
                    bothField: date =>
                      !date && selectedOutDatesTo ? 'Both dates should be filled' : undefined,
                  },
                }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    selected={!!selectedOutDatesFrom ? dayjs(selectedOutDatesFrom).toDate() : null}
                    // onChange={(date:Date) => setDate(date, 'outDates.from' as keyof IUserAvailabilitiesBasis)}
                    onCalendarOpen={handleComponentOpen}
                    onCalendarClose={handleComponentClose}
                    calendarStartDay={1}
                    customInput={
                      <DateTextInput
                        className="text-custom-input"
                        onReset={() => resetField('outDates.from')}
                        icon={<CalendarIcon />}
                      />
                    }
                    showPopperArrow={false}
                    minDate={dayjs().toDate()}
                    filterDate={isMonday}
                    isClearable
                    placeholderText={!mobile ? 'DD/MM/YYYY' : 'Start Date'}
                    withPortal={mobile}
                  />
                )}
              />
              {!!(errors?.outDates as any)?.from && (
                <FormHelperText error>
                  {(errors?.outDates as any)?.from.message?.toString() || 'Incorrect data'}
                </FormHelperText>
              )}
            </Grid>
            <Grid xs={6}>
              <Controller
                name="outDates.to"
                control={control}
                defaultValue={outDates[0]?.to}
                rules={{
                  validate: {
                    afterStart: date => {
                      if (date) {
                        if (selectedOutDatesFrom) {
                          if (!dayjs(selectedOutDatesFrom).isSameOrBefore(date)) {
                            return 'Incorrect date range';
                          }
                        }
                        return undefined;
                      }
                      if (!date && getFieldState('outDates.to').isTouched) {
                        return 'Incorrect date range';
                      }
                      return undefined;
                    },
                    bothField: date =>
                      !date && selectedOutDatesFrom ? 'Both dates should be filled' : undefined,
                  },
                }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    selected={!!selectedOutDatesTo ? dayjs(selectedOutDatesTo).toDate() : null}
                    // onChange={(date:Date) => setDate(date, 'outDates.to' as keyof IUserAvailabilitiesBasis)}
                    onCalendarOpen={handleComponentOpen}
                    onCalendarClose={handleComponentClose}
                    calendarStartDay={1}
                    customInput={
                      <DateTextInput
                        className="text-custom-input"
                        onReset={() => resetField('outDates.to')}
                        icon={<CalendarIcon />}
                      />
                    }
                    showPopperArrow={false}
                    minDate={dayjs().toDate()}
                    filterDate={isFriday}
                    isClearable
                    placeholderText={!mobile ? 'DD/MM/YYYY' : 'End Date'}
                    withPortal={mobile}
                  />
                )}
              />

              {!!(errors?.outDates as any)?.to && (
                <FormHelperText error>
                  {(errors?.outDates as any)?.to.message?.toString() || 'Incorrect data'}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </Box>
        <Typography mb={2} sx={{ borderBottom: { md: '1px solid #ebebeb' } }} variant="h6">
          Time preferences
        </Typography>
        {timePreferences.length ? (
          <Grid container spacing={2} className="availability-preference-input">
            <Grid xs={12} md={6}>
              <InputLabel>Available for Meetings</InputLabel>
              <Controller
                name="forMeetTimeId"
                control={control}
                defaultValue={availabilities[0]?.forMeetTimeId || timePreferences[0]?.id}
                rules={{ required: 'This field is required' }}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    inputRef={ref}
                    label="Select available time for meeting"
                    onOpen={handleComponentOpen}
                    onClose={handleComponentClose}
                    options={timePreferences || []}
                    labelMenuItem="text"
                  />
                )}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <InputLabel>Available to Work</InputLabel>
              <Controller
                name="forWorkTimeId"
                control={control}
                defaultValue={availabilities[0]?.forWorkTimeId || timePreferences[0]?.id}
                rules={{ required: 'This field is required' }}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    inputRef={ref}
                    onOpen={handleComponentOpen}
                    onClose={handleComponentClose}
                    label="Select available time for work"
                    options={timePreferences || []}
                    labelMenuItem="text"
                  />
                )}
              />
            </Grid>
          </Grid>
        ) : (
          <Loader />
        )}
      </form>
    </>
  );
};

export default Availability;
