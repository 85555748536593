import  React, { FC } from "react";

import Box from "@mui/material/Box";

import { IConsultantExperience } from "store/types/experiences";

import { StyledBox, StyledCompanyName, StyledWrap } from './styled'

import Avatar from "../../Avatar";
import ExperiencesGroupedItem from "./ExperiencesGroupedItem";

interface IExperiencesGroupedWrap {
  item: any
}

const ExperiencesGroupedWrap: FC<IExperiencesGroupedWrap> = ({ item }) => (
  <StyledWrap>
    <Box display="flex">
      <Avatar firstName={item.company.name} image={item.company.logo} round />
      <StyledCompanyName variant="body1">
        <b>{item.company.name}</b>
      </StyledCompanyName>
    </Box>
    <StyledBox>
      {item.experiences.map((experience: IConsultantExperience) => (
        <ExperiencesGroupedItem key={`${experience.description}-${experience.id}`} item={experience} />
      ))}
    </StyledBox>
  </StyledWrap>
)

export default ExperiencesGroupedWrap
