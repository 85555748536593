import React, { FunctionComponent } from 'react';

import Chip from '@mui/material/Chip';
import MuiLink from '@mui/material/Link';
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import PowerIcon from '@mui/icons-material/PowerSettingsNew';
import PauseCircleIcon from '@mui/icons-material/PauseCircleOutline';
import RefreshIcon from '@mui/icons-material/Autorenew';

import { byRole } from 'models/consts';
import { IEnterpriseUser } from 'store/types/enterprise';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getEnterpriseUsersSelector,
  hasMoreEnterpriseUsersSelector,
} from 'store/selectors/getEnterpriseSelector';
import { getMeSelector } from 'store/selectors/getUserSelector';

import BootstrapInput from '../../CustomFields/BootstrapInput';
import StackItem from '../../StackList/StackItem';
import StackRow from '../../StackList/StackRow';
import StyledContainer from '../../StackList/Container';

import { StyledLabel } from './styled';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import Box from '@mui/material/Box';
import Loader from '../../Loader/Loader';

const invited = (user: IEnterpriseUser) => {
  return !user.isActive && !user.lastChangeActiveAt;
};

interface IManageListMobile {
  onChangeRole: (event: SelectChangeEvent, id: number) => void;
  onChangeStatus: (row: IEnterpriseUser, status: string) => void;
  setPage: (id: (page: number) => number) => void;
}

const ManageListMobile: FunctionComponent<IManageListMobile> = ({
  onChangeRole,
  onChangeStatus,
  setPage,
}) => {
  const users = useAppSelector(getEnterpriseUsersSelector);
  const hasMore = useAppSelector(hasMoreEnterpriseUsersSelector);

  const me = useAppSelector(getMeSelector);

  const handleChangePage = () => {
    setPage((page: number) => page + 1);
  };

  const observerTarget = useInfiniteScroll(handleChangePage, { threshold: 1 });

  return (
    <StyledContainer>
      {users.map(row => (
        <StackItem key={row.email} spacing={2}>
          <StackRow direction="row">
            <StyledLabel color="textSecondary">Email</StyledLabel>
            <Typography>{row.email}</Typography>
          </StackRow>
          <StackRow direction="row">
            <StyledLabel color="textSecondary">Status</StyledLabel>
            <Chip
              className={invited(row) ? 'created' : (row.isActive && 'active') || 'terminated'}
              label={invited(row) ? 'Pending Invitation' : (row.isActive && 'Active') || 'Inactive'}
              variant="filled"
            />
          </StackRow>
          <StackRow direction="row">
            <StyledLabel color="textSecondary">Role</StyledLabel>
            <MuiSelect
              displayEmpty
              label="Select role"
              labelId="select-role"
              input={<BootstrapInput />}
              value={String(row.role.id) || ''}
              sx={{ textTransform: 'capitalize', height: 42, fontSize: '.875rem' }}
              onChange={event => {
                onChangeRole(event, row.id);
              }}
              disabled={me.id === row.id}
            >
              {byRole?.map(({ id, name }: { id: number; name: string }) => (
                <MenuItem key={name} sx={{ textTransform: 'capitalize' }} value={id}>
                  {name}
                </MenuItem>
              ))}
            </MuiSelect>
          </StackRow>
          {me.id !== row.id && (
            <StackRow direction="row">
              <StyledLabel color="textSecondary">Action</StyledLabel>
              {!invited(row) && row.isActive && me.id !== row.id && (
                <MuiLink
                  color="secondary"
                  onClick={() => onChangeStatus(row, 'active')}
                  sx={{ display: 'inline-flex' }}
                  underline="none"
                >
                  <PauseCircleIcon />
                  <Typography component="span" lineHeight="20px" ml={1}>
                    Pause
                  </Typography>
                </MuiLink>
              )}
              {!invited(row) && !row.isActive && me.id !== row.id && (
                <MuiLink
                  color="secondary"
                  onClick={() => onChangeStatus(row, 'deactive')}
                  sx={{ display: 'inline-flex', alignItems: 'center', stroke: 'none !important' }}
                  underline="none"
                >
                  <PowerIcon />
                  <Typography component="span" lineHeight="20px" ml={1}>
                    Activate
                  </Typography>
                </MuiLink>
              )}
              {invited(row) && me.id !== row.id && (
                <MuiLink
                  color="secondary"
                  onClick={() => onChangeStatus(row, 'refresh')}
                  sx={{ display: 'inline-flex', alignItems: 'center', stroke: 'none !important' }}
                  underline="none"
                >
                  <RefreshIcon />
                  <Typography component="span" lineHeight="normal" ml={1}>
                    Resend
                  </Typography>
                </MuiLink>
              )}
            </StackRow>
          )}
        </StackItem>
      ))}
      {hasMore && (
        <Box sx={{ height: 128 }}>
          <Loader />
        </Box>
      )}
      <Box sx={{ height: 10 }} ref={observerTarget}></Box>
    </StyledContainer>
  );
};

export default ManageListMobile;
