import React, { FC, SyntheticEvent } from 'react';
import { UseFormSetValue, FieldValues } from 'react-hook-form';

import CustomAutocomplete, { FormValues } from '.';
import useSearchCompanyByName from 'hooks/useSearchCompanyByName';
import { customFilter } from 'helpers/customFilter';
import { useAppDispatch } from "store/hooks";
import { createConsultantCompany } from "store/query/consultant";

import { ICompany } from 'store/types/company';

interface CompanySelectProps {
    field: FormValues;
    errors: any;
    setValue: UseFormSetValue<FieldValues>
    handleCreateCompany?: (company: ICompany) => void;
    createText?: string;
}

const CompanySelect: FC<CompanySelectProps> = (props) => {
    const {
        field,
        errors,
        setValue,
        handleCreateCompany,
        createText
    } = props;

    const dispatch = useAppDispatch();

    const {
        companies,
        setSearchTerm,
        isLoading: isCompaniesLoading,
    } = useSearchCompanyByName();

    const handleCompanySearch = (event: SyntheticEvent, value: string) => {
        setSearchTerm(value);
    };

    const defaultHandleCreateCompany = (company: ICompany) => {
        if (company.create) {
            dispatch(createConsultantCompany({
                company: {
                    name: company.name,
                    roleId: 4
                },
            })).unwrap().then((data) => {
                setValue(`company`, {
                    ...data[0],
                    create: `${createText || 'Add new company:'} "${company.name}"`
                })
                setValue(`companyId`, data[0].id)
            });
        }
        setValue(`.companyId`, company.id)
    };

    return (
        <CustomAutocomplete
            autocompleteProps={{
                multiple: undefined,
                onInputChange: handleCompanySearch,
            }}
            inputProps={{
                error: !!errors?.company,
                helperText: (errors?.company as any)?.message || 'Incorrect data'
            }}
            field={field}
            options={companies || []}
            placeholder="Select a vendor"
            customFilter={customFilter}
            handleChange={handleCreateCompany || defaultHandleCreateCompany}
            isLoading={isCompaniesLoading}
        />
    );
};

export default CompanySelect;