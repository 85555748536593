import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import ROUTES from 'router/constants';
import { useAppSelector } from 'store/hooks';

import { IBasicConsultant } from "store/types/consultant";
import { isAuthorized } from 'store/selectors/getUserSelector';

type IConsultantLayout = {
  children: ReactNode,
  user: IBasicConsultant,
  redirectTo?: string,
}

const ConsultantLayout: React.FunctionComponent<IConsultantLayout> = ({ children, user, redirectTo}) => {
  const isAuth = useAppSelector(isAuthorized);

  if (!isAuth && !user?.isPublic && typeof window !== 'undefined') {
    return <Navigate replace to={redirectTo || ROUTES.AUTH} />;
  }

  return (
    <>
      {children}
    </>
  );
};

export default ConsultantLayout;
