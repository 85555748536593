import { createAsyncThunk } from '@reduxjs/toolkit';
import { IChatAssistantMessage } from '../../types/chat-assistant';
import { EChatAssistant } from '../../constants/chat-assistant';
import instance from '../../middlewares/api';

const getAssistantResponse = createAsyncThunk<string, IChatAssistantMessage[]>(
  EChatAssistant.getAssistantResponse,
  async chat => {
    const response = await instance.post('/ai/chat', { conversation: chat });
    return response.data;
  },
);

export default getAssistantResponse;
