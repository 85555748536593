import React, { FunctionComponent } from "react";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import {ESize} from "models/consts";

import { FeedbackStarIcon } from "styles/icons/FeedbackStarIcon";

interface IStarRatingProps {
  rate?: number;
  size: ESize;
  onRatingChange?: (rating: number) => void;
  readonly?: boolean;
  value: number;
}

const StarRating: FunctionComponent<IStarRatingProps> = ({ value, onRatingChange, size, readonly}) => (
  <Box>
    {[...Array(5)].map((star, index) => (
      <IconButton
        key={index}
        color={index <= value ? 'primary' : 'secondary'}
        disabled={readonly}
        size={size}
        onClick={() => !readonly && onRatingChange && onRatingChange(index + 1)}
      >
        <FeedbackStarIcon fill={(value - index) * 100} />
      </IconButton>
    ))}
  </Box>
);

export default StarRating;
