/**
 *  Refactor 12.23
 */
import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';

import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';

import { CommonState } from '../reducers/common';
import {
  IExtendCategoryWithProducts,
  IModuleOfProduct,
  IWorkTypesOfProduct,
} from '../types/categoryProducts';
import { DEFAULT_AGENCY_TAXES, TAXES } from 'store/constants/order';

export const availabilitiesSelector = createSelector(
  (state: DefaultRootState) => state.common,
  ({ availabilities }: CommonState) => availabilities.items,
);
export const categoryProductsSelector = createSelector(
  (state: DefaultRootState) => state.common,
  ({ categoryProducts }: CommonState) => categoryProducts.items,
);
export const categoryProductsWithoutDeprecatedSelector = createSelector(
  (state: DefaultRootState) => state.common,
  ({ categoryProducts }: CommonState) =>
    filter({ isDeprecated: false })(categoryProducts.items) as IExtendCategoryWithProducts[],
);
export const productByIdSelector = (id: number) =>
  createSelector(
    (state: DefaultRootState) => state.common,
    ({ categoryProducts }: CommonState) =>
      find({ id })(categoryProducts.items) as IExtendCategoryWithProducts,
  );
export const expertiseLevelsSelector = createSelector(
  (state: DefaultRootState) => state.common,
  ({ expertiseLevels }: CommonState) => expertiseLevels.items,
);
export const expertiseLevelIdSelector = (id: number) =>
  createSelector(
    (state: DefaultRootState) => state.common,
    ({ expertiseLevels }: CommonState) => expertiseLevels.items?.find(level => level.id === id),
  );
export const languagesSelector = createSelector(
  (state: DefaultRootState) => state.common,
  ({ languages }: CommonState) => languages.items,
);
export const modulesSelector = createSelector(
  (state: DefaultRootState) => state.common,
  ({ modules }: CommonState) => modules.items,
);
export const modulesByProductSelector = (productId?: number) =>
  createSelector(
    (state: DefaultRootState) => state.common,
    ({ modules }: CommonState) =>
      (filter({ productId, isActive: true, isDeprecated: false })(
        modules.items,
      ) as IModuleOfProduct[]) || undefined,
  );
export const primaryModuleSelector = (id?: number) =>
  createSelector(
    (state: DefaultRootState) => state.common,
    ({ modules }: CommonState) => find({ id })(modules.items) as IModuleOfProduct,
  );
export const productModulesSelector = (key: number) =>
  createSelector(
    (state: DefaultRootState) => state.common,
    ({ modulesByProduct }: CommonState) => modulesByProduct[key]?.items || [],
  );
export const productPricesSelector = (key: number) =>
  createSelector(
    (state: DefaultRootState) => state.common,
    ({ productPrices }: CommonState) => productPrices[key],
  );
export const productWorksSelector = (key: number) =>
  createSelector(
    (state: DefaultRootState) => state.common,
    ({ workTypesByProduct }: CommonState) => workTypesByProduct[key]?.items || [],
  );
export const agencyCustomRateSelector = createSelector(
  (state: DefaultRootState) => state.user.agency,
  agency => (agency ? agency.customTakeRate || DEFAULT_AGENCY_TAXES : TAXES),
);
export const rolesSelector = createSelector(
  (state: DefaultRootState) => state.common,
  ({ roles }: CommonState) => roles.items || [],
);
export const timePreferencesSelector = createSelector(
  (state: DefaultRootState) => state.common,
  ({ timePreferences }: CommonState) => timePreferences.items,
);
export const workTypesSelector = createSelector(
  (state: DefaultRootState) => state.common,
  ({ workTypes }: CommonState) => workTypes.items,
);
export const workTypesByProductSelector = (productId?: number) =>
  createSelector(
    (state: DefaultRootState) => state.common,
    ({ workTypes }: CommonState) =>
      (filter({ productId, isActive: true, isDeprecated: false })(
        workTypes.items,
      ) as IWorkTypesOfProduct[]) || [],
  );
