import React, { FunctionComponent } from 'react';

import find from 'lodash/fp/find';
import { Controller, useFormContext } from 'react-hook-form';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import PlusIcon from 'styles/icons/PlusIcon';
import { StyledLabel } from './styled';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import useInfiniteScroll from 'hooks/useInfiniteScroll';

import {
  getAgencyUsersSelector,
  hasMoreAgencyUsersSelector,
  loadingAgencyUsersSelector,
} from 'store/selectors/getAgencySelector';
import updateUsersTeamAvailability from 'store/query/agency/updateUsersTeamAvailability';

import StackItem from '../../StackList/StackItem';
import StackRow from '../../StackList/StackRow';
import StyledContainer from '../../StackList/Container';
import CustomAutocomplete from 'components/CustomFields/CustomAutocomplete';
import Loader from '../../Loader/Loader';
import ActionsRow from './ActionRow';
import usersTeam from '../../../store/query/agency/usersTeam';
import { outputQuery } from '../../../helpers/decorators';

import { IAgencyUser } from 'store/types/agency';
import { availabilityOptions, byAgencyRoles, ERole, LIMITS } from '../../../models/consts';

const invited = (user: IAgencyUser) => {
  return !user.isActive && !user.lastChangeActiveAt;
};

interface IManageListMobile {
  onChangeStatus: (row: IAgencyUser, status: string) => void;
  onImpersonate: (id: number) => void;
  setPage: (id: (page: number) => number) => void;
  page: number;
}

const ManageListMobile: FunctionComponent<IManageListMobile> = ({
  onChangeStatus,
  onImpersonate,
  page,
  setPage,
}) => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(getAgencyUsersSelector);
  const loading = useAppSelector(loadingAgencyUsersSelector);

  const hasMore = useAppSelector(hasMoreAgencyUsersSelector);

  const handleChangePage = () => {
    setPage((page: number) => page + 1);
    dispatch(
      usersTeam({
        filter: outputQuery({
          filterByEmail: undefined,
          filterByStatus: undefined,
          filterByRole: undefined,
          property: undefined,
          type: 'asc',
        }),
        limit: LIMITS.team,
        offset: LIMITS.team * (page + 1),
      }),
    );
  };

  const observerTarget = useInfiniteScroll(handleChangePage, { threshold: 1 });

  const { control } = useFormContext();

  const handleChange = (value: any, userData: any) => {
    const payloadData = [
      {
        availabilityWeek: parseInt(value),
        userId: userData.id,
      },
    ];
    dispatch(updateUsersTeamAvailability(payloadData));
  };

  return (
    <>
      <StyledContainer className="testing">
        {users.map(row => (
          <StackItem key={row.email} spacing={2}>
            <StackRow direction="row">
              <StyledLabel color="textSecondary">Email</StyledLabel>
              <Typography>
                {row.role.name === ERole.CONSULTANT && row.isVirtual ? '' : row.email}
              </Typography>
            </StackRow>
            <StackRow direction="row">
              <StyledLabel color="textSecondary">Name</StyledLabel>
              <Typography>
                {row.role.name === ERole.AGENCY_ADMIN
                  ? row?.agency?.name
                  : `${row.firstName || ''} ${row.lastName || ''}`}
              </Typography>
            </StackRow>
            <StackRow direction="row">
              <StyledLabel color="textSecondary">Status</StyledLabel>
              <Chip
                className={invited(row) ? 'created' : (row.isActive && 'active') || 'terminated'}
                label={
                  invited(row) ? 'Pending Invitation' : (row.isActive && 'Active') || 'Inactive'
                }
                variant="filled"
              />
            </StackRow>
            <StackRow direction="row">
              <StyledLabel color="textSecondary">Role</StyledLabel>
              <Typography>
                {row.role.name === ERole.CONSULTANT && row.isVirtual
                  ? 'Virtual Specialist'
                  : (find({ id: row.role.id })(byAgencyRoles) as any)?.name}
              </Typography>
            </StackRow>
            {row?.availability && row.role.name === ERole.CONSULTANT && (
              <StackRow direction="row">
                <StyledLabel color="textSecondary">Availability</StyledLabel>
                <Controller
                  control={control}
                  name={`availability-${row.id}`}
                  defaultValue={
                    availabilityOptions.find(
                      option => option.name === row?.availability?.availabilityWeek?.toString(),
                    ) || null
                  }
                  render={({ field }) => (
                    <CustomAutocomplete
                      field={field}
                      options={availabilityOptions}
                      placeholder="Select Availability"
                      isSortable
                      handleChange={value => handleChange(value.name, row)}
                    />
                  )}
                />
              </StackRow>
            )}
            <ActionsRow user={row} onChangeStatus={onChangeStatus} />
            {row.role.name === ERole.CONSULTANT && row.isActive && (
              <StackRow direction="row">
                <Button
                  color="secondary"
                  startIcon={<PlusIcon />}
                  onClick={() => onImpersonate(row.id)}
                  variant="outlined"
                  fullWidth
                >
                  Impersonate
                </Button>
              </StackRow>
            )}
          </StackItem>
        ))}
        {hasMore && (
          <Box sx={{ height: 128 }}>
            <Loader />
          </Box>
        )}
        {!loading && hasMore && <Box sx={{ height: 10 }} ref={observerTarget}></Box>}
      </StyledContainer>
    </>
  );
};

ManageListMobile.whyDidYouRender = true;

export default ManageListMobile;
