import React, { FunctionComponent } from 'react'
import { Controller, useFormContext } from "react-hook-form";

import { normalizeOnChangeValue, onlyNumbers } from "helpers/normalizers";

import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";

import { ESeniority } from "models/consts";

import { useAppSelector } from "store/hooks";
import { expertiseLevelsSelector } from "store/selectors/getCommonSelector";
import { IProductStripe } from "store/types/categoryProducts";

import { BootstrapInput, RadioGroupInput } from "../index";

interface ILevelIdSelectProps {
  productPrices: IProductStripe[]
}

const LevelIdSelect: FunctionComponent<ILevelIdSelectProps> = ({ productPrices }) => {
  const seniorities = useAppSelector(expertiseLevelsSelector);

  const {
    clearErrors,
    control,
    formState: { errors },
    setValue,
    trigger,
    watch
  } = useFormContext()

  const levelId = watch('levelId');
  const customPriseCents = watch('customPriseCents');

  return (
    <>
      <Typography component="div" variant="h3" mb={2}>Select seniority or custom rate</Typography>
      <Controller
        control={control}
        name="levelId"
        render={({field}) => (
          <RadioGroupInput
            field={field}
            ariaLabelledby="levelId"
            name={field.name}
            onChange={(event) => {
              if (Number(event.target.value) !== ESeniority.CUSTOM && !!customPriseCents) {
                setValue('customPriseCents', '');
                trigger('customPriseCents');
              }
              field.onChange(event);
            }}
            values={[
              ESeniority.JUNIOR,
              ESeniority.INTERMEDIATE,
              ESeniority.SENIORITY,
              ESeniority.CUSTOM,
            ]}
            labels={{
              [ESeniority.JUNIOR]: {
                subLabel1: seniorities?.find(item => item.id === ESeniority.JUNIOR)?.name || '',
                subLabel2: `$ ${((productPrices?.find(item => item.levelId === ESeniority.JUNIOR)?.stripePriceCents || 0) / 100)}/hour`,
              },
              [ESeniority.INTERMEDIATE]: {
                subLabel1: seniorities?.find(item => item.id === ESeniority.INTERMEDIATE)?.name || '',
                subLabel2: `$ ${((productPrices?.find(item => item.levelId === ESeniority.INTERMEDIATE)?.stripePriceCents || 0) / 100)}/hour`,
              },
              [ESeniority.SENIORITY]: {
                subLabel1: seniorities?.find(item => item.id === ESeniority.SENIORITY)?.name || '',
                subLabel2: `$ ${((productPrices?.find(item => item.levelId === ESeniority.SENIORITY)?.stripePriceCents || 0) / 100)}/hour`,
              },
              [ESeniority.CUSTOM]: {
                subLabel1: 'Custom',
                children: (
                  <Box display="flex" alignItems="center">
                    <FormControl>
                      <Controller
                        control={control}
                        name="customPriseCents"
                        render={({field: {ref, ...field}}) => (
                          <BootstrapInput
                            {...field}
                            inputRef={ref}
                            placeholder="$"
                            disabled={Number(levelId) !== ESeniority.CUSTOM}
                            onChange={(event: any) => {
                              field.onChange(normalizeOnChangeValue(event.target.value, onlyNumbers));
                            }}
                          />
                        )}
                        rules={{
                          validate: {
                            optionalRequired: (value) => {
                              if (!value && Number(levelId) === ESeniority.CUSTOM) {
                                return 'This field is required!';
                              }
                              clearErrors('customPriseCents');
                              return undefined;
                            }
                          },
                        }}
                      />
                    </FormControl>

                    <Typography variant="body2" paddingX="4px" color="inherit">/hour</Typography>
                  </Box>
                ),
                error: !!errors.customPriseCents,
                helperText: errors.customPriseCents?.message || 'Incorrect data',
              },
            }}
            isHorizontal
          />
        )}
        rules={{
          required: 'This field is required',
        }}
      />
      {!!errors?.levelId && (
        <FormHelperText error sx={{position: 'relative', marginTop: {xs: -4, md: -7},}}>
          {(errors?.levelId as any).message || 'Incorrect data'}
        </FormHelperText>
      )}
    </>
  )
}

export default LevelIdSelect
