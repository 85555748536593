import { createSelector } from '@reduxjs/toolkit';
import { DefaultRootState } from 'react-redux';

import { EnterpriseState } from '../reducers/enterprise';
import { LIMITS } from '../../models/consts';
import getOr from 'lodash/fp/getOr';
import flowRight from 'lodash/fp/flowRight';
import compact from 'lodash/fp/compact';
import map from 'lodash/fp/map';
import { IEnterpriseUser, IPurchaseOrder } from '../types/enterprise';

const enterpriseState = (state: DefaultRootState) => state.enterprise;
const usersById = (state: DefaultRootState) => state.enterprise.usersById;
const purchaseOrdersById = (state: DefaultRootState) => state.enterprise.purchaseOrdersById;
const getUsers = (usersById: Record<string, any>) =>
  flowRight(
    compact,
    map((id: number) => id && usersById[id]),
  );
const getPurchaseOrders = (purchaseOrdersById: Record<string, any>) =>
  flowRight(
    compact,
    map((id: number) => id && purchaseOrdersById[id]),
  );
/**
 *  Get Users List
 */
export const selectUsersIds = createSelector(
  enterpriseState,
  state => state.users[state.usersFilter]?.ids,
);
export const getEnterpriseUsersByPageSelector = (page: number) =>
  createSelector(
    selectUsersIds,
    usersById,
    (ids, userById) =>
      getUsers(userById)(
        ids?.slice((page - 1) * LIMITS.team, page * LIMITS.team),
      ) as IEnterpriseUser[],
  );
export const getEnterpriseUsersSelector = createSelector(
  selectUsersIds,
  usersById,
  (ids, userById) => getUsers(userById)(ids) as IEnterpriseUser[],
);
export const loadingEnterpriseUsersSelector = createSelector(enterpriseState, state =>
  getOr(false)(['users', state.usersFilter, 'loading'])(state as any),
);
export const hasMoreEnterpriseUsersSelector = createSelector(enterpriseState, state =>
  getOr(true)(['users', state.usersFilter, 'hasMore'])(state as any),
);
export const totalEnterpriseUsersSelector = createSelector(
  enterpriseState,
  state => state.users[state.usersFilter]?.countAll || 0,
);
// export const getEnterpriseUsersSelector = createSelector(
//   enterpriseState,
//   ({ users }: EnterpriseState) => users,
// );
//
// export const loadingEnterpriseUsersSelector = createSelector(
//   enterpriseState,
//   ({ loading }: EnterpriseState) => loading,
// );
export const getSearchEnterpriseUsersSelector = (search: string) =>
  createSelector(enterpriseState, ({ searchUsers }: EnterpriseState) => searchUsers[search]);

/**
 *  Get Order Forms List
 */
export const selectPurchaseOrderIds = createSelector(
  enterpriseState,
  state => state.purchaseOrders[state.purchaseOrdersFilter]?.ids,
);
export const getPurchaseOrdersSelector = createSelector(
  selectPurchaseOrderIds,
  purchaseOrdersById,
  (ids, purchaseOrderById) => getPurchaseOrders(purchaseOrderById)(ids) as IPurchaseOrder[],
);
export const getPurchaseOrdersByPageSelector = (page: number) =>
  createSelector(
    selectPurchaseOrderIds,
    purchaseOrdersById,
    (ids, purchaseOrderById) =>
      getPurchaseOrders(purchaseOrderById)(
        ids?.slice((page - 1) * LIMITS.team, page * LIMITS.team),
      ) as IPurchaseOrder[],
  );

export const loadingPurchaseOrdersSelector = createSelector(enterpriseState, state =>
  getOr(false)(['purchaseOrders', state.purchaseOrdersFilter, 'loading'])(state as any),
);
export const hasMorePurchaseOrdersSelector = createSelector(enterpriseState, state =>
  getOr(true)(['purchaseOrders', state.purchaseOrdersFilter, 'hasMore'])(state as any),
);
export const totalPurchaseOrdersSelector = createSelector(
  enterpriseState,
  state => state.purchaseOrders[state.purchaseOrdersFilter]?.countAll || 0,
);

/**
 *  Refactored 12.12
 */
export const purchaseOrderSelector = (id: number | string = -1) =>
  createSelector(enterpriseState, ({ ordersById }: EnterpriseState) => ordersById[id]);
