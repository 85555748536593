import React, { FunctionComponent } from 'react';
import Typography from '@mui/material/Typography'

interface IToastMessage {
  body: string;
  title: string;
}

const ToastMessage: FunctionComponent<IToastMessage> = ({title, body}) => (
  <>
    <Typography variant="subtitle1" fontWeight={500}>{title}</Typography>
    <Typography fontSize="1rem">{body}</Typography>
  </>
)

export default ToastMessage;
