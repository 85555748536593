import React, {FC} from "react";
import { DndContext } from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import SortableItem from "../../ConsultantComponents/AdditionalSkills/SortableItem";

interface ISortableContainer {
  getTagProps: ({ index }: { index: number }) => void;
  onChange: (value: any[]) => void;
  value: any[]
}
const SortableContainer: FC<ISortableContainer> = ({ getTagProps, onChange, value }) => (
  <DndContext
    onDragEnd={({ active, over }) => {
      if (over && active.id !== over?.id) {
        const activeIndex = value?.findIndex(({ id }) => id === active.id);
        const overIndex = value?.findIndex(({ id }) => id === over.id);

        onChange(arrayMove(value, activeIndex, overIndex));
      }
    }}
  >
    <SortableContext items={value || []}>
      {!!value?.length && value.map((item, index) => (
        <SortableItem key={item.id} {...item} getTagProps={getTagProps} index={index} />
      ))}
    </SortableContext>
  </DndContext>
)

export default SortableContainer
