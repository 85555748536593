import React, { FunctionComponent, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input/input';

import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import checkCompanyByName from 'store/query/company/checkCompanyByName';
import fetchRoles from 'store/query/common/fetchRoles';
import saveCompanyLogo from 'store/query/company/saveCompanyLogo';
import updateCustomerCompany from 'store/query/user/updateCustomerCompany';

import { rolesSelector } from 'store/selectors/getCommonSelector';
import { getMeSelector } from 'store/selectors/getUserSelector';
import { getCustomerCompanySelector } from 'store/selectors/getCustomerSelector';

import BuildingIcon from 'styles/icons/BuildingIcon';

import UploadAvatar from 'components/UploadAvatar';
import { BootstrapInput, PhoneNumber, Select } from 'components/CustomFields';
import { ICustomerBasisForm } from 'store/types/customer';
import { IAvatarResponse } from 'store/types/user';
import { changeCustomerAvatar } from 'store/reducers/user';
import { NAME_REGEX } from 'models/consts';

const CustomerSettings: FunctionComponent<any> = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(getMeSelector);
  const company = useAppSelector(getCustomerCompanySelector(user.id));

  const companyRolesList = useAppSelector(rolesSelector);

  const {
    control,
    handleSubmit,
    formState: { isValid, touchedFields, dirtyFields, errors, isSubmitting },
    trigger,
    watch,
    setValue,
    reset,
  } = useForm<ICustomerBasisForm>({
    defaultValues: {
      description: company?.description,
      mobilePhone: {
        country: parsePhoneNumber(company?.mobilePhone || '')?.country || 'US',
        countryCallingCode: parsePhoneNumber(company?.mobilePhone || '')?.countryCallingCode,
        nationalNumber: parsePhoneNumber(company?.mobilePhone || '')?.nationalNumber,
      },
      name: company?.name,
      roleId: company?.UsersCompanies?.companyRoleId || company.roleId,
      image: '',
    },
    mode: 'onChange',
  });

  const mobilePhones = watch('mobilePhone');
  const image = watch('image', '');

  useEffect(() => {
    if (!companyRolesList.length) {
      dispatch(fetchRoles());
    }
  }, [companyRolesList.length, dispatch]);

  const onSubmit: SubmitHandler<any> = async ({
    name,
    description,
    roleId,
    mobilePhone,
    image,
  }: any) => {
    if (image) {
      await dispatch(
        saveCompanyLogo({
          companyId: company.id,
          logo: image,
          needUpdateUser: true,
        }),
      )
        .unwrap()
        .then((data: IAvatarResponse) => {
          dispatch(changeCustomerAvatar(data));
        });
    }
    if (!!Object.keys(touchedFields).length) {
      const updatedCompany = {
        id: company.id,
        mobilePhone: [
          '+',
          getCountryCallingCode(mobilePhone.country),
          mobilePhone.nationalNumber,
        ].join(''),
        description,
        roleId,
      };
      if (touchedFields.name && name !== company.name) {
        await dispatch(
          updateCustomerCompany({ userId: user.id, company: { ...updatedCompany, name } }),
        );
      } else {
        await dispatch(updateCustomerCompany({ userId: user.id, company: updatedCompany }));
      }
    }
    reset({
      description: description,
      mobilePhone: {
        country: mobilePhone.country,
        countryCallingCode: mobilePhone.countryCallingCode,
        nationalNumber: mobilePhone.nationalNumber,
      },
      name,
      roleId,
      image: '',
    });
  };

  const checkIsCompanyNotExist = (companyName?: string) => {
    if (companyName && companyName !== company.name) {
      return dispatch(checkCompanyByName(companyName))
        .unwrap()
        .then(({ isHave }) => {
          return !isHave;
        });
    }
    return true;
  };

  return (
    <>
      <Typography mt={{ xs: 2.5, md: 7 }} variant="h1" data-test="contact-page">
        Contact details
      </Typography>
      <Divider sx={{ mb: 3, mt: 1 }} />
      <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid container xs={12} md={4} lg={5} xxl={4} alignItems="center">
            <Controller
              control={control}
              name="image"
              render={({ field: { ref, ...field } }) => (
                <UploadAvatar
                  {...field}
                  btnName="Upload photo"
                  size="large"
                  icon={<BuildingIcon />}
                  image={company?.logo}
                  firstName={company?.name}
                  onChange={e => {
                    trigger();
                    setValue('image', e);
                  }}
                />
              )}
            />
          </Grid>
          <Grid container md={8} lg={7} xxl={8}>
            <Grid xs={12} lg={6}>
              <FormGroup sx={{ mb: 0 }}>
                <InputLabel>Company</InputLabel>
                <Controller
                  control={control}
                  name="name"
                  render={({ field: { ref, ...field } }) => (
                    <BootstrapInput
                      {...field}
                      inputRef={ref}
                      placeholder="Company name"
                      disabled={company?.ownerId !== user?.id}
                      error={!!errors.name}
                      helperText={errors.name?.message}
                    />
                  )}
                  rules={{
                    required: 'This field is required',
                    pattern: {
                      value: NAME_REGEX,
                      message: 'Too many spaces',
                    },
                    validate: {
                      checkCompanyExist: async (companyName?: string) =>
                        (await checkIsCompanyNotExist(companyName)) || 'Company already exist',
                    },
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid xs={12} lg={6}>
              <FormGroup sx={{ mb: 0 }}>
                <InputLabel>Job title</InputLabel>
                <Controller
                  control={control}
                  name="description"
                  render={({ field: { ref, ...field } }) => (
                    <BootstrapInput
                      {...field}
                      disabled={company?.ownerId !== user?.id}
                      inputRef={ref}
                      placeholder="Title"
                    />
                  )}
                />
              </FormGroup>
            </Grid>
            <Grid xs={12} lg={6}>
              <FormGroup sx={{ mb: 0 }}>
                <InputLabel>Phone number</InputLabel>
                <PhoneNumber
                  name="mobilePhone"
                  control={control}
                  defaultValue={mobilePhones}
                  rules={{
                    required: 'This field is required',
                  }}
                  error={!!errors.mobilePhone?.nationalNumber || !!errors.mobilePhone?.country}
                  errorText={
                    (errors.mobilePhone?.nationalNumber as any)?.message ||
                    (errors.mobilePhone?.country as any)?.message
                  }
                  disabled={company?.ownerId !== user?.id}
                  trigger={trigger}
                />
              </FormGroup>
            </Grid>
            <Grid xs={12} lg={6}>
              <FormGroup sx={{ mb: 0 }}>
                <InputLabel>Current role</InputLabel>
                <FormControl variant="filled">
                  <Controller
                    control={control}
                    name="roleId"
                    render={({ field: { ref, ...field } }) => (
                      <Select
                        {...field}
                        disabled={company?.ownerId !== user?.id}
                        value={field.value && !!companyRolesList?.length ? field.value : ''}
                        options={companyRolesList || []}
                        label="Select role"
                      />
                    )}
                  />
                </FormControl>
              </FormGroup>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            sx={{ pt: { xs: 3, md: 6 } }}
            justifyContent={{ xs: 'center', md: 'start' }}
          >
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={!isValid || (!Object.keys(dirtyFields).length && !image)}
              loading={isSubmitting}
            >
              Save updates
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default CustomerSettings;
