import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/MoreVert'
import { IAgencyUser } from 'store/types/agency';
import { Box } from '@mui/system';
import { useAppSelector } from 'store/hooks';
import { getMeSelector } from 'store/selectors/getUserSelector';
import { IBasicUser } from 'store/types/user';
import { useMemo } from 'react';
import { ERole } from 'models/consts';
import usePermissions from "./usePermissions";

const invited = (user: IAgencyUser) => {
    return !user.isActive && !user.lastChangeActiveAt
}

export interface IActionsMenuProps {
  user: IAgencyUser;
  onChangeStatus: (user: IAgencyUser, selectedAction: string) => void;
}

const ActionsMenu: React.FunctionComponent<IActionsMenuProps> = ({ user, onChangeStatus }) => {
  const me = useAppSelector(getMeSelector) as IBasicUser;

  const isViewDeleteBtn = usePermissions(user)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (status: string) => {
    setAnchorEl(null);
    if (typeof status === 'string') {
      onChangeStatus(user, status)
    }
  };

  const showActivationButtons = useMemo(() => {
    const condition = !invited(user) &&
      user.id !== me.id &&
      user.agency?.owner.id !== user.id;
    if (me.role.name === ERole.RESOURCE_MANAGER && me.id !== user.agency?.owner.id) {
      return condition && user.role.name !== ERole.AGENCY_ADMIN
    }
    return condition;
  }, [me, user]);

  return (
    <Box>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{color: 'black', textAlign: 'left', width: 'fit-content', padding: '8px 16px'}}
      >
        <MenuIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {user.isActive
          && showActivationButtons
          && (
            <MenuItem onClick={() => handleClose('active')}>Pause</MenuItem>
        )}
        {!user.isActive
          && showActivationButtons
          && (
          <MenuItem onClick={() => handleClose('deactive')}>Activate</MenuItem>
        )}
        {invited(user) && (
          <MenuItem onClick={() => handleClose('refresh')}>Resend</MenuItem>
        )}
        {isViewDeleteBtn && (
          <MenuItem onClick={() => handleClose(user.isActive === null ? 'cancel' : 'delete')}>{user.isActive === null ? 'Cancel' : 'Delete'}</MenuItem>
        )}
      </Menu>
    </Box>
  );
}

export default ActionsMenu;
