import { Capacitor } from '@capacitor/core';
import { ELocalStoreKeys, mobileCookieMaxAge } from 'models/consts';
import {Cookies} from "react-cookie";

const cookies = new Cookies(null, { path: '/', sameSite: 'lax' })
const isMobileNative = Capacitor.isNativePlatform();

export const removeAuthTokens = () => {
  cookies.remove(ELocalStoreKeys.ACCESS_TOKEN)
  cookies.remove(ELocalStoreKeys.REFRESH_TOKEN)
}

export const removeImpersonateTokens = () => {
  cookies.remove(ELocalStoreKeys.IMPERSONATE_TOKEN)
  cookies.remove(ELocalStoreKeys.IMPERSONATE_REFRESH_TOKEN)
  cookies.remove(ELocalStoreKeys.GLOBAL_IMPERSONATION)
}

export const saveTokens = (accessToken: string, refreshToken: string) => {
  const impersonateToken = cookies.get(ELocalStoreKeys.IMPERSONATE_TOKEN);

  if(impersonateToken) {
    cookies.set(ELocalStoreKeys.IMPERSONATE_TOKEN, accessToken);
    cookies.set(ELocalStoreKeys.IMPERSONATE_REFRESH_TOKEN, refreshToken);
  } else {
    cookies.set(ELocalStoreKeys.ACCESS_TOKEN, accessToken, {maxAge: isMobileNative ? mobileCookieMaxAge : undefined})
    cookies.set(ELocalStoreKeys.REFRESH_TOKEN, refreshToken, {maxAge: isMobileNative ? mobileCookieMaxAge : undefined})
  }
}
