import React, { FunctionComponent } from 'react';
import { Link as RouteLink, useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useStateMachine } from 'little-state-machine';

import { useMediaQuery } from '@mui/material';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/material/styles';

import { Logo } from 'styles/icons/Logo';
import { LogoWhite } from 'styles/icons/LogoWhite';

import { StyledBox, StyledBoxImpersonate, StyledDiv, StyledHeader } from './styled';

import ROUTES from 'router/constants';
import { IBasicUser } from '../../store/types/user';
import { ELocalStoreKeys } from 'models/consts';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMeLoadingSelector, getMeSelector, isAuthorized } from 'store/selectors/getUserSelector';
import logoutImpersonate from 'store/query/agency/logoutImpersonate';
import updateAction from 'store/actions/updateAction';

import RightSideContainer from './Components/RightSideContainer';
import useMatchMultiple from '../../hooks/useMatchUrls';
import { getBasicConsultantSelector } from 'store/selectors/getConsultantSelector';
import { IBasicConsultant } from 'store/types/consultant';

interface IHeader {
  isCreationPage?: boolean;
}

const Header: FunctionComponent<IHeader> = ({ isCreationPage }) => {
  const [cookies] = useCookies([
    ELocalStoreKeys.IMPERSONATE_TOKEN,
    ELocalStoreKeys.GLOBAL_IMPERSONATION,
  ]);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const match = useMatchMultiple([
    `${ROUTES.CONSULTANT_REGISTRATION}/${ROUTES.STEP}`,
    `${ROUTES.AGENCY_CREATION}/${ROUTES.STEP}`,
  ]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xl'));

  const isAuth = useAppSelector(isAuthorized);
  const user = useAppSelector(getMeSelector) as IBasicUser;
  const loading = useAppSelector(getMeLoadingSelector);
  const consultant = useAppSelector(getBasicConsultantSelector(id || user?.id)) as IBasicConsultant;

  const impersonateToken =
    typeof document !== 'undefined' && cookies[ELocalStoreKeys.IMPERSONATE_TOKEN];
  const isGlobalImpersonated = cookies[ELocalStoreKeys.GLOBAL_IMPERSONATION];

  const { actions } = useStateMachine({ updateAction });

  const removeImpersonateToken = () => {
    dispatch(logoutImpersonate())
      .unwrap()
      .finally(() => {
        navigate(ROUTES.TEAM);
        actions.updateAction({
          experiences: [],
          expertise: {},
          levelId: 0,
          timeLine: {},
          worker: {},
          searchParams: {},
        });
      });
  };
  const navigateToMyProfile = () => {
    navigate(`${ROUTES.CONSULTANT}/${consultant?.nickName || consultant?.userId}`);
  };
  return (
    <>
      {impersonateToken && !loading && (
        <StyledBoxImpersonate>
          <Typography variant="body2">
            Impersonation is active now. You are logged in
            {(user.firstName || user.lastName) && (
              <>
                {' '}
                as{' '}
                <strong>
                  {user.firstName} {user.lastName}
                </strong>
              </>
            )}
            . {!isGlobalImpersonated ? 'Back to' : ''}&nbsp;
            <Link
              onClick={removeImpersonateToken}
              sx={{ color: 'text.primary', cursor: 'pointer', textDecoration: 'underline' }}
            >
              {isGlobalImpersonated ? 'Exit Impersonation' : 'Team Roster'}
            </Link>
            .
          </Typography>
        </StyledBoxImpersonate>
      )}
      {consultant?.callScheduled && !consultant?.isApproved && !loading && (
        <StyledBoxImpersonate>
          <Typography variant="body2">
            Your profile is not fully vetted yet. Feel free to&nbsp;
            <Link
              sx={{ color: 'text.primary', cursor: 'pointer', textDecoration: 'underline' }}
              onClick={navigateToMyProfile}
            >
              finalize your profile
            </Link>
            &nbsp;while you wait.
          </Typography>
        </StyledBoxImpersonate>
      )}
      {!match && (
        <Container
          maxWidth={(!isCreationPage && 'xxl') || false}
          fixed={(!isCreationPage && matches) || false}
          disableGutters={isCreationPage}
          sx={{
            px: { xs: 2, sm: 3, md: (!isCreationPage && 5) || 0, lg: (!isCreationPage && 8) || 0 },
          }}
        >
          <StyledBox>
            <StyledHeader isCreationPage={isCreationPage}>
              <StyledDiv isCreationPage={isCreationPage}>
                <Link component={RouteLink} to={(isAuth && ROUTES.ORDER_LIST) || ROUTES.AUTH}>
                  {isCreationPage ? <LogoWhite /> : <Logo />}
                </Link>
              </StyledDiv>
              <RightSideContainer isAuth={isAuth} isCreationPage={isCreationPage} />
            </StyledHeader>
          </StyledBox>
        </Container>
      )}
    </>
  );
};

export default Header;
