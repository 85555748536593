import React, { FunctionComponent, useMemo } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { EConsultantEditingModals } from 'models/consts';
import { IBasicConsultant, IProductExperienceFull } from 'store/types/consultant';

import { useAppSelector } from 'store/hooks';
import { timePreferencesSelector } from 'store/selectors/getCommonSelector';

import EditIcon from 'styles/icons/EditIcon';

import ConsultantProgressSnackBar from 'components/SnackBar/ConsultantProgressSnackBar/ConsultantProgressSnackBar';

import { StyledInfoFieldValue } from 'components/CustomFields/styled';
import { StyledLabel, StyledTypographyWithBorder, StyledValueBox } from './styled';
import { IBasicUser } from 'store/types/user';
import { ICertificateItem } from 'store/types/certificates';

interface ILeftSideProps {
  consultant: IBasicConsultant;
  expertiseSummary: IProductExperienceFull[];
  certificatesList: ICertificateItem[];
  user: IBasicUser;
  isCurrentUser: boolean;
  onOpen: (open: { type: EConsultantEditingModals; open: boolean; showIntro?: boolean }) => void;
}

const LeftSide: FunctionComponent<ILeftSideProps> = ({
  consultant,
  expertiseSummary,
  certificatesList,
  user,
  isCurrentUser,
  onOpen,
}) => {
  const tPreferences = useAppSelector(timePreferencesSelector);

  const availability = useMemo(() => {
    return consultant.availabilities && consultant.availabilities[0];
  }, [consultant.availabilities]);

  return (
    <Grid container>
      {isCurrentUser && (
        <ConsultantProgressSnackBar
          consultant={consultant}
          expertiseSummary={expertiseSummary}
          certificatesList={certificatesList}
          user={user}
          onOpen={onOpen}
        />
      )}
      {availability && (
        <Grid xs={12} pb={4}>
          <Box display="flex" justifyContent="space-between" alignItems="center" my={3}>
            <Typography variant="h3">Availability</Typography>
            {isCurrentUser && onOpen && (
              <IconButton
                data-test="edit-availability-btn"
                color="tertiary"
                size="small"
                onClick={() => onOpen({ open: true, type: EConsultantEditingModals.AVAILABILITY })}
              >
                <EditIcon fontSize="inherit" sx={{ fill: 'none' }} />
              </IconButton>
            )}
          </Box>
          <Stack direction="row" alignItems="center" spacing={2}>
            <StyledLabel variant="subtitle2">Capacity</StyledLabel>
            <StyledInfoFieldValue>
              <Typography data-test="capacity-value">{`${availability.availabilityWeek} hours / week`}</Typography>
            </StyledInfoFieldValue>
          </Stack>
          <StyledTypographyWithBorder>
            <strong>Available for meeting</strong>
          </StyledTypographyWithBorder>
          <StyledValueBox>
            <Typography data-test="meet-value">
              {tPreferences?.find(({ id }) => availability.forMeetTimeId === id)?.text}
            </Typography>
          </StyledValueBox>
          <StyledTypographyWithBorder>
            <strong>Available for work</strong>
          </StyledTypographyWithBorder>
          <StyledValueBox>
            <Typography data-test="work-value">
              {tPreferences?.find(({ id }) => availability.forWorkTimeId === id)?.text}
            </Typography>
          </StyledValueBox>
        </Grid>
      )}
      {consultant.languages && (
        <Grid xs={12} pb={4}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={{ xs: 0, md: 3 }}
            mb={3}
          >
            <Typography variant="h3">Languages</Typography>
            {isCurrentUser && onOpen && (
              <IconButton
                id="edit-languages-btn"
                color="tertiary"
                size="small"
                onClick={() => onOpen({ open: true, type: EConsultantEditingModals.PROFILE })}
              >
                <EditIcon fontSize="inherit" sx={{ fill: 'none' }} />
              </IconButton>
            )}
          </Box>
          <Stack spacing={2}>
            {consultant.languages.map(({ id, language, level }) => (
              <Stack
                key={`lang-${id}`}
                sx={{ textTransform: 'capitalize' }}
                direction="row"
                alignItems="center"
                spacing={2}
              >
                <StyledLabel variant="subtitle2">{language.name.toLowerCase() || ''}</StyledLabel>
                <StyledInfoFieldValue>
                  <Typography>{level}</Typography>
                </StyledInfoFieldValue>
              </Stack>
            ))}
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default LeftSide;
