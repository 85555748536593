export default (duration: any) => {
  const years = duration.years();
  const months = duration.months();

  let formattedDuration = '';
  if (years > 0) {
    formattedDuration += `${years} ${years === 1 ? 'year' : 'years'}`;
  }
  if (months > 0) {
    if (formattedDuration !== '') {
      formattedDuration += ', ';
    }
    formattedDuration += `${months} ${months === 1 ? 'month' : 'months'}`;
  }
  if (years ==+ 0 && months === 0) {
    formattedDuration +=  '0 months';
  }

  return formattedDuration;
}
