import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useAppSelector } from 'store/hooks';
import {
  isAgencyAdminSelector,
  isAgencyResourceManagerSelector,
  isConsultantSelector,
} from 'store/selectors/getUserSelector';
import { IPurchaseOrder } from 'store/types/enterprise';

import ROUTES from 'router/constants';

import PlusIcon from 'styles/icons/PlusIcon';
import { StyledEmptyBlock } from './styled';

interface IEmptyList {
  purchase?: IPurchaseOrder | null;
}

const EmptyList: FunctionComponent<IEmptyList> = ({ purchase }) => {
  const isConsultant = useAppSelector(isConsultantSelector);
  const isAgencyAdmin = useAppSelector(isAgencyAdminSelector);
  const isResourceManager = useAppSelector(isAgencyResourceManagerSelector);

  return (
    <StyledEmptyBlock>
      {isConsultant ? (
        <Typography sx={{ fontSize: '1rem', maxWidth: 300 }}>
          You don’t have any upcoming orders. Make sure to keep{' '}
          <Link to={ROUTES.CONSULTANT}>your profile</Link> complete and up to date to improve your
          positioning for new orders.
        </Typography>
      ) : (
        <Box maxWidth={320}>
          {!isAgencyAdmin && !isResourceManager && (
            <>
              <Typography sx={{ fontSize: '1rem', mb: 3 }}>
                You have no orders yet.
                <br />
                Please press “Create an order”
                <br />
                to start your first experience
              </Typography>
              <Button
                id="create-an-order"
                component={Link}
                startIcon={<PlusIcon />}
                to={ROUTES.ORDER_CREATION + '/1'}
                state={purchase}
                variant="contained"
              >
                Create an order
              </Button>
            </>
          )}
          {(isAgencyAdmin || isResourceManager) && (
            <Typography sx={{ fontSize: '1rem', mb: 3 }}>
              There are no upcoming orders,
              <br /> make sure your team&apos;s profiles are up to date to improve their positioning
              for new orders
            </Typography>
          )}
        </Box>
      )}
    </StyledEmptyBlock>
  );
};

export default EmptyList;
