import React, { FunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, DialogActions, DialogContent, FormGroup, InputLabel, FormLabel, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import ModalWrapper from 'components/Modal/Modal';
import BootstrapInput from 'components/CustomFields/BootstrapInput';
import CompanySelect from 'components/CustomFields/CustomAutocomplete/CompanySelect';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getMeSelector } from 'store/selectors/getUserSelector';
import requestNewProduct from 'store/query/productAndExperience/requestProduct';
import { IModalProps } from 'store/types/common';
import { IBasicUser } from 'store/types/user';

interface IRequestProductModal extends IModalProps {
    afterSubmit: () => void;
};

const RequestProductModal: FunctionComponent<IRequestProductModal> = (props) => {
    const { visible, close, afterSubmit } = props;

    const user = useAppSelector(getMeSelector) as IBasicUser;

    const dispatch = useAppDispatch();

    const {
        control,
        handleSubmit,
        setValue,
        reset,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: {
            name: '',
            notes: '',
            modules: '',
            typesOfWork: '',
            category: '',
            company: undefined,
            requestorsName: ((user.firstName ?? '') + ' ' + (user.lastName ?? '')).trim(),
            requestorsEmail: user.email,
        },
        mode: 'onChange',
    });
    
    const onSubmit = (data: any) => {
        dispatch(requestNewProduct(data))
        .then((result) => {
            if (requestNewProduct.fulfilled.match(result)) {
                afterSubmit();
            }
        })
        .finally(() => {
            close();
            reset();
        });
    }

    return (
        <ModalWrapper
            dialogProps={{
                open: visible,
                onClose: close,
            }}
            title={{title: 'Request a Product'}}>
            <DialogContent>
                <form id="title-order" onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid xs={12} md={3}>
                                <FormLabel>Name*</FormLabel>
                            </Grid>
                            <Grid xs={12} md={9}>
                                <Controller
                                    control={control}
                                    name="name"
                                    render={({field: { ref, ...field}}) => (
                                        <BootstrapInput
                                            {...field}
                                            inputRef={ref}
                                            error={!!errors.name}
                                            helperText={errors.name?.message || 'Invalid value'}
                                            placeholder="Product Name"
                                        />
                                    )}
                                    rules={{ required: 'This field is required' }}
                                />
                            </Grid>
                        </Grid>
                    </FormGroup>

                    <FormGroup>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid xs={12} md={3}>
                                <FormLabel>Modules</FormLabel>
                            </Grid>
                            <Grid xs={12} md={9}>
                                <Controller
                                    control={control}
                                    name="modules"
                                    render={({field: { ref, ...field}}) => (
                                        <BootstrapInput
                                            {...field}
                                            inputRef={ref}
                                            placeholder="eg Payroll, Time Off, Attendance"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </FormGroup>

                    <FormGroup>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid xs={12} md={3}>
                                <FormLabel>Types of Work</FormLabel>
                            </Grid>
                            <Grid xs={12} md={9}>
                                <Controller
                                    control={control}
                                    name="typesOfWork"
                                    render={({field: { ref, ...field}}) => (
                                        <BootstrapInput
                                            {...field}
                                            inputRef={ref}
                                            placeholder="eg Configuration, Integration, Testing"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </FormGroup>

                    <FormGroup>
                        <Grid container alignItems="flex-start" spacing={2}>
                            <Grid xs={12} md={3}>
                                <FormLabel>Notes*</FormLabel>
                            </Grid>
                            <Grid xs={12} md={9}>
                                <Controller
                                    control={control}
                                    name="notes"
                                    render={({field: { ref, ...field}}) => (
                                        <BootstrapInput
                                            {...field}
                                            inputRef={ref}
                                            error={!!errors.notes}
                                            helperText={errors.notes?.message || 'Incorrect data'}
                                            placeholder="Tell us a little bit about the product, what it’s used for or add any
                                            relevant links where we can get more information..."
                                            multiline
                                            rows={3}
                                        />
                                    )}
                                    rules={{ required: 'This field is required' }}
                                />
                            </Grid>
                        </Grid>
                    </FormGroup>
                                    
                    <FormGroup>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid xs={12} md={3}>
                                <FormLabel>Category</FormLabel>
                            </Grid>
                            <Grid xs={12} md={9}>
                                <Controller
                                    control={control}
                                    name="category"
                                    render={({field: { ref, ...field}}) => (
                                        <BootstrapInput
                                            {...field}
                                            inputRef={ref}
                                            placeholder="Enter a category"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </FormGroup>

                    <FormGroup>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid xs={12} md={3}>
                                <FormLabel>Vendor</FormLabel>
                            </Grid>
                            <Grid xs={12} md={9}>
                            <Controller
                                name='company'
                                control={control}
                                render={({ field }) => (
                                    <CompanySelect
                                        field={field}
                                        errors={errors}
                                        //@ts-ignore - setValue type is not compatible
                                        setValue={setValue}
                                        createText='Add new vendor:'
                                    />
                                )}
                            />
                            </Grid>
                        </Grid>
                    </FormGroup>

                    <Controller
                        name="requestorsName"
                        control={control}
                        render={({ field }) => <input {...field} type="hidden" />}
                    />

                    <Controller
                        name="requestorsEmail"
                        control={control}
                        render={({ field }) => <input {...field} type="hidden" />}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button disabled={!isValid} color="secondary" form="title-order" fullWidth type="submit" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </ModalWrapper>
    )
};

export default RequestProductModal;