import React, { forwardRef, FunctionComponent } from 'react'
import { Controller, useFormContext } from "react-hook-form";

import Grid from "@mui/material/Unstable_Grid2";
import InputLabel from "@mui/material/InputLabel";

import { onTypeaheadItemChange } from "utils/orderCreationHelpers";
import { filterIsDeprecated } from "helpers/decorators";
import { useAppSelector } from "store/hooks";

import { categoryProductsSelector, modulesSelector, workTypesSelector } from "store/selectors/getCommonSelector";

import CustomAutocomplete from "../CustomAutocomplete";
import { StyledMasonry } from "../CustomAutocomplete/styled";

interface IProductsModulesWorkProps {
  disabledProductEdit?: boolean;
  isModal?: boolean;
}

const ProductsModulesWork: FunctionComponent<IProductsModulesWorkProps> = ({ disabledProductEdit, isModal  }) => {
  const categoriesWithProductsList = useAppSelector(categoryProductsSelector);
  const modulesList = useAppSelector(modulesSelector);
  const worksList = useAppSelector(workTypesSelector);

  const { control, formState: { errors }, resetField, setValue, watch } = useFormContext()

  const currentProduct = watch('product');
  const primaryModule = watch('primaryModule');
  const primaryWorkType = watch('primaryWorkType');

  const modulesListByProduct = filterIsDeprecated(modulesList, currentProduct?.id);
  const worksListByProduct = filterIsDeprecated(worksList, currentProduct?.id);

  return (
    <Grid container spacing={2} sx={{mb: 3}}>
      <Grid xs={12} md={6} lg={isModal ? 6 : 4}>
        <InputLabel>Product</InputLabel>
        <Controller
          control={control}
          name="product"
          render={({field}) => (
            <CustomAutocomplete
              autocompleteProps={{
                groupBy: (option) => option.categoryName,
                ListboxComponent: forwardRef((props: any, ref: any) => (
                  <StyledMasonry
                    ref={ref}
                    component="ul"
                    spacing={2}
                    {...props}
                    columns={1}
                  />
                )),
                multiple: undefined,
              }}
              inputProps={{
                readOnly: true,
                error: !!errors.product,
                helperText: (errors.product as any)?.message || 'Incorrect data'
              }}
              field={field}
              handleChange={(event, fieldName) => {
                onTypeaheadItemChange(event, fieldName, setValue);
                resetField('modules', {defaultValue: []});
                resetField('works', {defaultValue: []});
              }}
              options={categoriesWithProductsList || []}
              placeholder="Select a Product"
              disabled={disabledProductEdit}
            />)}
          rules={{required: 'This field is required'}}
        />
      </Grid>
      <Grid xs={12} md={6} lg={isModal ? 6 : 4}>
        <InputLabel>Modules</InputLabel>
        <Controller
          control={control}
          name="modules"
          render={({field}) => (
            <CustomAutocomplete
              autocompleteProps={{
                multiple: true
              }}
              inputProps={{
                readOnly: true,
                error: !!errors.modules,
                helperText: (errors.modules as any)?.message || 'Incorrect data',
                sx: { '& .MuiInputBase-inputAdornedStart': { minWidth: '0px !important', padding: 0 } }
              }}
              field={field}
              handleChange={(event, fieldName) => onTypeaheadItemChange(event, fieldName, setValue)}
              isMainTag
              options={modulesListByProduct || []}
              placeholder="Select a Module"
              primaryItem={primaryModule}
              onPrimaryItemChange={(id) => setValue('primaryModule', id)}
            />
          )}
          rules={{required: 'This field is required'}}
        />
      </Grid>
      <Grid xs={12} md={6} lg={isModal ? 6 : 4}>
        <InputLabel>Type of Work</InputLabel>
        <Controller
          control={control}
          name="works"
          render={({field}) => (
            <CustomAutocomplete
              autocompleteProps={{
                multiple: true
              }}
              inputProps={{
                readOnly: true,
                error: !!errors.works,
                helperText: (errors.works as any)?.message || 'Incorrect data',
                sx: { '& .MuiInputBase-inputAdornedStart': { minWidth: '0px !important', padding: 0 } }
              }}
              field={field}
              isMainTag
              options={worksListByProduct || []}
              handleChange={(event, fieldName) => onTypeaheadItemChange(event, fieldName, setValue)}
              placeholder="Select a Type of Work"
              primaryItem={primaryWorkType}
              onPrimaryItemChange={(id) => setValue('primaryWorkType', id)}
            />
          )}
          rules={{required: 'This field is required'}}
        />
      </Grid>
    </Grid>
  )
}

export default ProductsModulesWork
