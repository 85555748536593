import React, { useMemo } from 'react';

import { useAppSelector } from '../store/hooks';

import { ERole } from '../models/consts';

import { getMeSelector } from '../store/selectors/getUserSelector';

import AdminSettings from '../views/Settings/AdminSettings';
import ConsultantSettings from '../views/Settings/ConsultantSettings';
import CustomerSettings from '../views/Settings/CustomerSettings';

const ContactDetailsRoute = () => {
  const user = useAppSelector(getMeSelector);

  return useMemo(() => {
    switch (user?.role.name) {
      case ERole.AGENCY_ADMIN:
      case ERole.RESOURCE_MANAGER:
        return <AdminSettings />;
      case ERole.CONSULTANT:
        return <ConsultantSettings />;
      case ERole.SELF_CUSTOMER:
      case ERole.CUSTOMER_ADMIN:
      case ERole.CUSTOMER:
        return <CustomerSettings />;
      default:
        return <></>;
    }
  }, [user?.role.name]);
};

export default ContactDetailsRoute;
