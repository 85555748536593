import React, { FunctionComponent, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  Link,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import FormLabel from '@mui/material/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Unstable_Grid2';
import MuiLink from '@mui/material/Link';

import ROUTES from 'router/constants';

import {
  EAgencyRegStep,
  ECustomerRegisterModal,
  EMAIL_REGEX,
  ERole,
  NAME_REGEX,
  PASSWORD_REGEX,
  registrationSources,
} from 'models/consts';
import { EErrorMessages } from 'models/formConstants';

import { IPasswordsVisiblity } from 'store/types/inner-models';

import { useAppDispatch } from 'store/hooks';
import signup from 'store/query/user/signup';
import createAgency from 'store/query/agency/createAgency';

import VisibleIcon from 'styles/icons/VisibleIcon';
import InvisibleIcon from 'styles/icons/InvisibleIcon';

import { StyledActionGroup, StyledGrid, StyledH1, StyledRoot, StyledForm } from '../styled';
import { BootstrapInput, RadioGroupInput, Select } from 'components/CustomFields';
import { setSignUpType } from 'store/reducers/user';
import { FormControl } from '@mui/material';
import requestAgency from 'store/query/agency/requestAgency';

export interface ISignupProps {
  isModal?: boolean;
  onChangeCustomerStep?: (step: ECustomerRegisterModal) => void;
  userRole?: ERole;
  allowToggleRole?: boolean; //we'll use this to determine if the user can change the assigned / null role
}

const Signup: FunctionComponent<ISignupProps> = ({
  isModal,
  userRole,
  onChangeCustomerStep,
  allowToggleRole = true,
}) => {
  const data = useLoaderData() as any;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { hash } = useParams();
  const { search } = useLocation();
  const [searchParams] = useSearchParams();

  if (data?.role) {
    //we should trust the role from the hash (user invite) over the URL routing userRole
    userRole = data.role.name;
  }

  const companyName = (data && JSON.parse(data.metaData)?.agencyName) || '';

  const [visible, setPasswordVisibility] = useState<IPasswordsVisiblity>({
    password: false,
    confirmPassword: false,
  });

  const {
    clearErrors,
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    watch,
    setError,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      signupType: hash
        ? 'consultant'
        : allowToggleRole && searchParams.get('type')
          ? searchParams.get('type')
          : '',
      email: searchParams?.get('email') || data?.email || '',
      password: '',
      confirmPassword: '',
      userAgreement: undefined,
      firstName: '',
      lastName: '',
      company: '',
      notes: '',
      teamSize: '',
      newsletterSubscription: false,
    },
  });

  const { password, confirmPassword, signupType } = watch();

  useEffect(() => {
    return () => {
      dispatch(setSignUpType(null));
    };
  }, [dispatch]);

  useEffect(() => {
    const updateUserRoleAsync = async () => {
      if (signupType !== '' && typeof window !== 'undefined' && allowToggleRole) {
        const url = new URL(window.location.href);
        url.searchParams.set('type', signupType ? signupType : '');
        window.history.pushState({ path: url.href }, '', url.href);
        await dispatch(setSignUpType(signupType));
      }
    };
    updateUserRoleAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signupType]);

  const [agencyRequest, setAgencyRequestFields] = useState(false);
  const [agencySubmitted, setAgencySubmitted] = useState(false);

  useEffect(() => {
    if (signupType === 'agency') {
      setAgencyRequestFields(true);
    } else {
      setAgencyRequestFields(false);
    }
  }, [signupType]);

  const handleClickShowPassword = (field: keyof IPasswordsVisiblity) => () => {
    setPasswordVisibility({ ...visible, [field]: !visible[field] });
  };

  const onSubmit: SubmitHandler<any> = async ({
    email,
    password,
    signupType,
    firstName,
    lastName,
    company,
    notes,
    teamSize,
    newsletterSubscription,
  }: any) => {
    const wbSourceParam = searchParams?.get('wb_source') || '';
    const wbSource = registrationSources.includes(wbSourceParam) ? wbSourceParam : undefined;
    if (userRole === ERole.AGENCY_ADMIN) {
      /**
       *   Agency Registration
       */
      await dispatch(createAgency({ code: hash, password }))
        .unwrap()
        .then(() => {
          if (data.email === data.company.emailDomain) {
            navigate(`${ROUTES.AGENCY_CREATION}/${EAgencyRegStep.PROFILE}`);
          } else {
            navigate(ROUTES.HOME);
          }
        });
    } else if (agencyRequest) {
      setAgencySubmitted(true);
      await dispatch(
        requestAgency({
          email,
          firstName,
          lastName,
          company,
          notes,
          teamSize,
          newsletterSubscription,
        }),
      );
    } else {
      await dispatch(
        signup({
          code: hash,
          email,
          password,
          role: userRole || signupType,
          wbSource,
        }),
      )
        .unwrap()
        .then(() => {
          if (isModal && onChangeCustomerStep) {
            onChangeCustomerStep(ECustomerRegisterModal.VERIFICATION_CODE);
          } else if (
            hash &&
            [ERole.LEAD_MANAGER, ERole.RESOURCE_MANAGER].includes(userRole || signupType)
          ) {
            navigate(ROUTES.ORDER_LIST);
          } else if (hash) {
            hash
              ? navigate(`${ROUTES.CONSULTANT_REGISTRATION}/1`, {
                  state: { isAgencyAddingUser: true },
                })
              : navigate(`${ROUTES.CONSULTANT_REGISTRATION}/1`);
          } else {
            navigate(ROUTES.SIGNUP_CODE);
          }
        });
    }
  };

  return (
    <Box>
      <StyledRoot component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container sx={{ height: '100%' }}>
          <StyledGrid xs={12} lg={isModal ? 12 : 7.02}>
            <Box flexGrow={1} mt={{ xs: 2, sm: 0 }}>
              {!isModal && (
                <StyledH1 variant="h1" data-test="sign-up-header">
                  {'Sign Up'}
                  {hash && userRole !== ERole.LEAD_MANAGER && (
                    <Typography
                      component="p"
                      variant="subtitle1"
                      fontSize={{ xs: 16, lg: 18 }}
                      fontWeight={400}
                      sx={{ mt: 1 }}
                    >
                      You’ve been invited to join <b>{companyName}</b>.
                    </Typography>
                  )}
                  {hash && (
                    <Typography
                      component="p"
                      variant="subtitle1"
                      fontSize={{ xs: 16, lg: 18 }}
                      fontWeight={400}
                    >
                      Please give us your details to finish your registration.
                    </Typography>
                  )}
                </StyledH1>
              )}
              {hash || !allowToggleRole ? (
                <></>
              ) : (
                <FormGroup sx={{ marginBottom: 0 }}>
                  <Grid xs={12}>
                    <InputLabel>Account Type</InputLabel>
                    <Controller
                      control={control}
                      name="signupType"
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <RadioGroupInput
                          ariaLabelledby="signup-type"
                          name="signup-type"
                          values={['self_customer', 'consultant', 'agency']}
                          labels={{
                            ['self_customer']: {
                              subLabel1: 'Customer',
                              subLabel2: `I'm looking to hire talent`,
                            },
                            ['consultant']: {
                              subLabel1: 'Specialist',
                              subLabel2: `I'm looking to find gig work`,
                            },
                            ['agency']: {
                              subLabel1: 'Agency',
                              subLabel2: `I have multiple specialists`,
                            },
                          }}
                          field={field}
                          isHorizontal
                        />
                      )}
                    />
                  </Grid>
                </FormGroup>
              )}
              <StyledForm>
                <FormGroup>
                  <InputLabel>Email</InputLabel>
                  <Controller
                    control={control}
                    name="email"
                    render={({ field: { ref, ...field } }) => (
                      <BootstrapInput
                        {...field}
                        disabled={!!hash || !!searchParams.get('email')}
                        inputRef={ref}
                        error={!!errors.email}
                        placeholder="Enter your email"
                        helperText={errors.email?.message || 'Incorrect data'}
                        type="email"
                      />
                    )}
                    rules={{
                      required: 'This field is required',
                      pattern: {
                        value: EMAIL_REGEX,
                        message: 'Invalid email',
                      },
                    }}
                  />
                </FormGroup>
                {!agencyRequest && (
                  <>
                    <FormGroup>
                      <FormLabel>Create Password</FormLabel>
                      <Controller
                        control={control}
                        name="password"
                        render={({ field: { ref, ...field } }) => (
                          <BootstrapInput
                            {...field}
                            inputRef={ref}
                            error={!!errors.password}
                            placeholder="Enter password"
                            helperText={errors.password?.message || 'Incorrect data'}
                            type={visible.password ? 'text' : 'password'}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword('password')}
                                  edge="end"
                                  size="small"
                                >
                                  {visible.password ? <InvisibleIcon /> : <VisibleIcon />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        )}
                        rules={{
                          required: 'This field is required',
                          minLength: {
                            value: 8,
                            message: EErrorMessages.MIN_LENGTH_PASSWORD,
                          },
                          pattern: {
                            value: PASSWORD_REGEX,
                            message: 'Whitespace is not allowed',
                          },
                          validate: (value: string) => {
                            if (confirmPassword === value) {
                              clearErrors('confirmPassword');
                              return true;
                            } else if (confirmPassword && confirmPassword !== value) {
                              setError('confirmPassword', {
                                message: 'Your passwords do not match',
                              });
                            }
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>Repeat Password</FormLabel>
                      <Controller
                        control={control}
                        name="confirmPassword"
                        render={({ field: { ref, ...field } }) => (
                          <BootstrapInput
                            {...field}
                            inputRef={ref}
                            error={!!errors.confirmPassword}
                            placeholder="Enter password"
                            helperText={errors.confirmPassword?.message || 'Incorrect data'}
                            type={visible.confirmPassword ? 'text' : 'password'}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword('confirmPassword')}
                                  edge="end"
                                  size="small"
                                >
                                  {visible.confirmPassword ? <InvisibleIcon /> : <VisibleIcon />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        )}
                        rules={{
                          required: 'This field is required',
                          minLength: {
                            value: 8,
                            message: EErrorMessages.MIN_LENGTH_PASSWORD,
                          },
                          pattern: {
                            value: PASSWORD_REGEX,
                            message: 'Whitespace is not allowed',
                          },
                          validate: (value: string) =>
                            (password != value && 'Your passwords do not match') || true,
                        }}
                      />
                    </FormGroup>
                    <FormGroup sx={{ mb: 5, mt: { xs: '32px' } }}>
                      <Controller
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                data-test="user-agreement-checkbox"
                                {...field}
                                size="small"
                                checkedIcon={<span />}
                                icon={<span />}
                              />
                            }
                            label={
                              <>
                                <span>I agree to the </span>
                                <MuiLink
                                  color="secondary"
                                  component={Link}
                                  target="_blank"
                                  to={ROUTES.TERMS_OF_USE}
                                >
                                  Terms of Use
                                </MuiLink>
                                <span> and </span>
                                <MuiLink
                                  color="secondary"
                                  component={Link}
                                  target="_blank"
                                  to={ROUTES.PRIVACY_POLICY}
                                >
                                  Privacy Policy
                                </MuiLink>
                              </>
                            }
                            sx={{ marginLeft: 0, alignItems: 'flex-start' }}
                          />
                        )}
                        name="userAgreement"
                        rules={{ required: true }}
                      />
                    </FormGroup>
                  </>
                )}
                {agencyRequest && (
                  <>
                    <Grid container spacing={2}>
                      <StyledGrid xs={12} lg={isModal ? 12 : 6}>
                        <FormGroup>
                          <InputLabel>First name</InputLabel>
                          <Controller
                            control={control}
                            name="firstName"
                            render={({ field: { ref, ...field } }) => (
                              <BootstrapInput
                                {...field}
                                inputRef={ref}
                                error={!!errors.firstName}
                                placeholder="Enter your first name"
                                helperText={errors.firstName?.message || 'Incorrect data'}
                                dataTest="firstName"
                              />
                            )}
                            rules={{
                              required: 'This field is required',
                              pattern: {
                                value: NAME_REGEX,
                                message: 'Too many spaces',
                              },
                              maxLength: {
                                message: 'The max count of characters is 255',
                                value: 255,
                              },
                            }}
                          />
                        </FormGroup>
                      </StyledGrid>
                      <StyledGrid xs={12} lg={isModal ? 12 : 6}>
                        <FormGroup>
                          <InputLabel>Last name</InputLabel>
                          <Controller
                            control={control}
                            name="lastName"
                            render={({ field: { ref, ...field } }) => (
                              <BootstrapInput
                                {...field}
                                inputRef={ref}
                                error={!!errors.lastName}
                                placeholder="Enter your last name"
                                helperText={errors.lastName?.message || 'Incorrect data'}
                                dataTest="lastName"
                              />
                            )}
                            rules={{
                              required: 'This field is required',
                              pattern: {
                                value: NAME_REGEX,
                                message: 'Too many spaces',
                              },
                              maxLength: {
                                message: 'The max count of characters is 255',
                                value: 255,
                              },
                            }}
                          />
                        </FormGroup>
                      </StyledGrid>
                    </Grid>
                    <FormGroup>
                      <InputLabel>Company</InputLabel>
                      <Controller
                        control={control}
                        name="company"
                        render={({ field: { ref, ...field } }) => (
                          <BootstrapInput
                            {...field}
                            inputRef={ref}
                            error={!!errors.company}
                            placeholder="Enter Company Name"
                            helperText={errors.company?.message || 'Incorrect data'}
                            dataTest="company"
                          />
                        )}
                        rules={{ required: 'This field is required' }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <InputLabel>What products and type of work do you specialize in?</InputLabel>
                      <Controller
                        control={control}
                        name="notes"
                        render={({ field: { ref, ...field } }) => (
                          <BootstrapInput
                            {...field}
                            inputRef={ref}
                            error={!!errors.notes}
                            placeholder="eg UKG Dimensions configurations and migrations"
                            dataTest="description"
                            multiline
                            rows={2}
                            sx={{ paddingTop: '10px' }}
                            helperText={errors.notes?.message || 'Incorrect data'}
                          />
                        )}
                        rules={{ required: 'This field is required' }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <InputLabel>How big is your services team?</InputLabel>
                      <FormControl variant="filled">
                        <Controller
                          control={control}
                          name="teamSize"
                          render={({ field: { ref, ...field } }) => (
                            <Select
                              {...field}
                              inputRef={ref}
                              options={['1-10', '11-50', '51-200', '200+'] || []}
                              label="Select team size"
                              data-test="teamSize"
                            />
                          )}
                          rules={{ required: 'This field is required' }}
                        />
                      </FormControl>
                    </FormGroup>
                    {/* <FormGroup sx={{mb: 5, mt: {xs: '32px'}}}>
                  <Controller
                    control={control}
                    render={({field}) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="newsletter-subscription-checkbox"
                            {...field}
                            size="small"
                            checkedIcon={<span />}
                            icon={<span />}
                          />
                        }
                        label={ 'Would you like to subscribe to our newsletter?'}
                        sx={{marginLeft: 0, alignItems: 'flex-start'}}
                      />
                    )}
                    name="newsletterSubscription"
                  />
                </FormGroup> */}
                  </>
                )}
              </StyledForm>
              {agencyRequest && agencySubmitted && (
                <Typography color="error" sx={{ mb: 2 }}>
                  Your request has been submitted successfully
                </Typography>
              )}
              <LoadingButton
                data-test="sign-up-button-lg"
                loading={isSubmitting}
                color="secondary"
                sx={{ mb: 2, display: { xs: 'none', lg: 'flex' } }}
                variant="contained"
                disabled={!isValid || agencySubmitted}
                type="submit"
              >
                {agencyRequest ? 'Send Agency Inquiry' : isModal ? 'Continue' : 'Sign Up'}
              </LoadingButton>
            </Box>
            <StyledActionGroup>
              {!hash && (
                <Box mt={2} sx={{ mb: { xs: 8, lg: '5vh' } }} justifyContent="space-between">
                  <Typography component="span" sx={{ mr: 1 }} variant="body1">
                    Already a member?
                  </Typography>
                  {isModal ? (
                    <MuiLink
                      data-test="sign-in-link"
                      color="secondary"
                      component={Link}
                      to={search}
                      onClick={() =>
                        onChangeCustomerStep && onChangeCustomerStep(ECustomerRegisterModal.SIGNIN)
                      }
                    >
                      Sign in instead
                    </MuiLink>
                  ) : (
                    <MuiLink
                      data-test="sign-in-link"
                      color="primary"
                      component={Link}
                      to={ROUTES.AUTH}
                    >
                      Sign in instead
                    </MuiLink>
                  )}
                </Box>
              )}
              <LoadingButton
                data-test="sign-up-button-xs"
                loading={isSubmitting}
                color="secondary"
                sx={{ mb: 2, display: { xs: 'flex', lg: 'none' } }}
                variant="contained"
                disabled={!isValid || agencySubmitted}
                type="submit"
              >
                {agencyRequest ? 'Send Agency Inquiry' : isModal ? 'Continue' : 'Sign Up'}
              </LoadingButton>
            </StyledActionGroup>
          </StyledGrid>
        </Grid>
      </StyledRoot>
    </Box>
  );
};

export default Signup;
