import React, { FunctionComponent, useRef } from "react";

import TableBody from '@mui/material/TableBody';
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";

import TableCell from "./TableCell";
import TableRow from "./TableRow";
import TablePaginationActions from "components/TableCustomized/TablePaginationActions";

interface IManageTableWrapper {
  page: number;
  total: number;
  count: number;
  defaultLimit: number;
  TableContent: React.ReactNode;
  onPageChange: (page: number, limit: number, offset: number) => void;
}

const ManageTableWrapper: FunctionComponent<IManageTableWrapper> = ({
  page,
  total,
  count,
  defaultLimit,
  TableContent,
  onPageChange,
}) => {
  const [rowsPerPage] = React.useState(defaultLimit);
  const tableScrollRef = useRef<HTMLTableSectionElement | null>(null);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = defaultLimit - count;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    onPageChange(newPage, rowsPerPage, rowsPerPage * newPage);
    if (tableScrollRef.current?.parentElement?.parentElement) {
      tableScrollRef.current.parentElement.parentElement.scrollTop = 0;
    }
  };

  return (
    <>
      <TableBody ref={tableScrollRef}>
        {TableContent}
        {emptyRows > 0 && (
          <TableRow sx={{ height: 64 * emptyRows, backgroundColor: 'white !important' }}>
            <TableCell colSpan={7} />
          </TableRow>
        )}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[defaultLimit]}
            colSpan={7}
            count={total}
            rowsPerPage={defaultLimit}
            page={page}
            SelectProps={{
              sx: { display: 'none' }
            }}
            labelRowsPerPage={''}
            labelDisplayedRows={() => ''}
            onPageChange={handleChangePage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </>
  );
}

export default ManageTableWrapper;