import { createAsyncThunk} from '@reduxjs/toolkit';
import instance from '../../middlewares/api';
import { EEnterprise } from '../../constants';

const fetchPurchaseOrder = createAsyncThunk(
  EEnterprise.getPurchaseOrder,
  async (id: string) => {
    const { data } = await instance.get(`/enterprise/purchase-orders/${id}`);

    return data
  }
)

export default fetchPurchaseOrder;
