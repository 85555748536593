import { useParams } from 'react-router-dom';
import React, { FunctionComponent } from 'react';
import Page404 from './404';

interface IValidateRoute {
  matchRegExp: string;
  component: FunctionComponent;
}

const ValidateRoute: FunctionComponent<IValidateRoute> = ({
  component: Component,
  matchRegExp,
}) => {
  const { step } = useParams();

  const regexp = new RegExp(`${matchRegExp}`);
  const isMatched = step?.match(regexp);

  if (isMatched) {
    return <Component />;
  }
  return <Page404 />;
};

export default ValidateRoute;
