import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import ReactJoyride, { CallBackProps, EVENTS, ACTIONS, STATUS, Step } from 'react-joyride';
import deepmerge from 'deepmerge';

import { useStateMachine } from 'little-state-machine';

import flatMapDepth from 'lodash/fp/flatMapDepth';
import map from 'lodash/fp/map';
import omit from 'lodash/fp/omit';
import dayjs from 'utils/dayjs';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TabContext from '@mui/lab/TabContext';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { StyledH1Mobile } from 'views/Auth/styled';

import { IBasicUser } from 'store/types/user';
import { IBasicConsultant } from 'store/types/consultant';
import { IConsultantRegister } from 'store/types/inner-models';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import getConsultantProductsFull from 'store/query/consultant/getConsultantProductsFull';
import updateConsultantExpertise from 'store/query/consultant/updateConsultantExpertise';
import addAvailability from 'store/query/consultant/addAvailability';
import { getMeSelector } from 'store/selectors/getUserSelector';
import { getBasicConsultantSelector } from 'store/selectors/getConsultantSelector';
import updateAction from 'store/actions/updateAction';

import { TabsList } from './Common';
import ModulesContainer from './Expertises/ModulesContainer';
import ROUTES from 'router/constants';

const StyledTabPanel = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const ProductsExpertises: FunctionComponent<IConsultantRegister> = ({
  isModal,
  showIntro,
  onSubmitted,
}) => {
  const customButtonStyles = {
    buttonNext: {
      fontFamily: 'Visuelt Pro, Arial',
      color: '#000',
    },
  };
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const { id } = useParams();
  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState<Step[]>([]);

  const user = useAppSelector(getMeSelector) as IBasicUser;
  const consultant = useAppSelector(getBasicConsultantSelector(user?.id)) as IBasicConsultant;

  const { actions } = useStateMachine({ updateAction });

  const { control, watch, handleSubmit, unregister } = useFormContext();

  const [tab, setTab] = React.useState(`0`);
  const { fields, append, replace } = useFieldArray({
    control,
    name: 'expertise',
    keyName: '_id',
  });

  const watchFieldArray = watch('expertise');
  const controlledFields =
    watchFieldArray &&
    fields?.map((field, index) => ({
      ...field,
      ...watchFieldArray[index],
    }));

  const [isReadLoading, setIsReadLoading] = useState(false);

  useEffect(() => {
    if (consultant?.id) {
      setIsReadLoading(true);
      dispatch(getConsultantProductsFull(id || user?.id))
        .unwrap()
        .then(data => {
          if (controlledFields && controlledFields.length) {
            replace(map(omit('experiences'))(controlledFields));
            actions.updateAction({ expertise: map(omit('experiences'))(controlledFields) });
          } else {
            replace(map(omit('experiences'))(data));
            actions.updateAction({ expertise: map(omit('experiences'))(data) });
          }
          setTab('0');
        })
        .finally(() => setIsReadLoading(false));
    }

    return () => {
      unregister('expertise');
    };
    // don't add controlledFields to dependsies array because of endless loading
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, append, consultant?.id, dispatch, id, user?.id, replace]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const onSubmit = async (value: any) => {
    const expertises = flatMapDepth(({ expertise }) => expertise, 1)(value.expertise);
    if (!consultant.agencyId) {
      await dispatch(
        updateConsultantExpertise({ expertises, key: id || user?.id, userId: user?.id }),
      )
        .unwrap()
        .then(
          async () =>
            await dispatch(
              addAvailability({
                availabilities: [
                  {
                    availabilityWeek: 20,
                    availableFrom: dayjs().toDate().toUTCString(),
                    forMeetTimeId: 2,
                    forWorkTimeId: 2,
                  },
                ],
                userId: user?.id,
              }),
            )
              .unwrap()
              // .then(() => {
              // actions.updateAction({ experiences: [], expertise: [] })
              // })
              .then(() => onSubmitted()),
        );
    } else {
      await dispatch(
        updateConsultantExpertise({ expertises, key: id || user?.id, userId: user?.id }),
      )
        .unwrap()
        .then(
          async () =>
            await dispatch(
              addAvailability({
                availabilities: [
                  {
                    availabilityWeek: 40,
                    availableFrom: dayjs().toDate().toUTCString(),
                    forMeetTimeId: 2,
                    forWorkTimeId: 2,
                  },
                ],
                userId: user?.id,
              }),
            )
              .unwrap()
              .then(() => {
                onSubmitted && onSubmitted();
                // actions.updateAction({ experiences: [], expertise: [] })
                navigate(ROUTES.CONSULTANT, {
                  state: {
                    enableCongratsModal: false,
                  },
                });
              }),
        );
    }
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status, type, index } = data;
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setRun(false);
    } else if (action === ACTIONS.CLOSE || action === ACTIONS.STOP) {
      setRun(false);
    } else if (type === EVENTS.STEP_AFTER) {
      setRun(false);
      setTimeout(() => {
        setRun(true);
      }, 500);
    }
  };

  return (
    <>
      <ReactJoyride
        continuous
        run={run}
        steps={steps}
        callback={handleJoyrideCallback}
        showProgress
        disableOverlay
        scrollToFirstStep={true}
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Finish',
          next: 'Next',
          open: 'Open the dialog',
          skip: 'Skip',
        }}
        styles={deepmerge(
          {
            options: {
              backgroundColor: '#171717',
              arrowColor: '#171717',
              textColor: '#FFFFFF',
              primaryColor: '#FFCA28',
              zIndex: 10000,
            },
          },
          customButtonStyles,
        )}
      />
      <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
        {!isModal && (
          <StyledH1Mobile component="div">
            <Typography data-test="consultant-register-header" component="h1" variant="h4" mb={2}>
              Select your preferred modules and expertise level
            </Typography>
            {!mobile ? (
              <Typography component="p" mb={3}>
                To match you with relevant projects, please indicate your interest and proficiency
                in each module. By clearly communicating your preferences and expertise, we can
                better align you with projects that suit your skills and interests.
              </Typography>
            ) : (
              <Typography component="p" mb={3}>
                To match you with relevant projects, please indicate your interest and proficiency
                in each module. By clearly communicating your preferences and expertise, we can
                better align you with projects that suit your skills and interests.
              </Typography>
            )}
          </StyledH1Mobile>
        )}
        <TabContext value={tab}>
          {!!controlledFields?.length && (
            <TabsList onHandleChange={handleChange} readOnly tabsList={controlledFields} />
          )}
          {controlledFields?.map(({ expertise, product }: any, index: any) => (
            <StyledTabPanel
              key={product?.id}
              data-test="tab-proficiency"
              hidden={parseInt(tab) !== index}
            >
              <ModulesContainer
                showIntro={showIntro}
                setSteps={setSteps}
                setRun={setRun}
                expertise={expertise}
                name={`expertise.${index}.expertise.modulesWorks`}
                product={product}
              />
            </StyledTabPanel>
          ))}
        </TabContext>
        {controlledFields?.length === 0 && !isReadLoading && (
          <Box alignContent={'center'} sx={{ width: '100%', mt: { xs: 4.5, md: 14.5 } }}>
            <Typography fontSize="18px" fontWeight="400" align="center">
              No products to show
            </Typography>
          </Box>
        )}
      </form>
    </>
  );
};

export default ProductsExpertises;
