import Box from "@mui/material/Box";
import Fab from '@mui/material/Fab';
import { styled } from "@mui/material/styles";

import AuthBackgroundXSNew from "../../styles/icons/AuthBackgroundXSNew2.svg";
import AuthBackgroundNew from "../../styles/icons/AuthBackgroundNew.svg";

const StyledLayout = styled(Box)(({ theme }) => theme.unstable_sx({
  display: 'flex',
  flexDirection:'column',
  backgroundSize: {
    xs: 'auto',
    sm: 'contain',
  },
  backgroundPosition: {
    xs: 'right bottom -25px',
    sm:'bottom -250px right 0',
    lg: 'right bottom',
  },
  backgroundRepeat: 'no-repeat',
  height: '100%',
  minHeight: '-webkit-fill-available',
  overflow: 'hidden',
  backgroundImage: {
    xs: `url(${AuthBackgroundXSNew})`,
    lg: `url(${AuthBackgroundNew})`
  },
}))

const StyledFab = styled(Fab)(({ theme }) => theme.unstable_sx({
  position: 'fixed',
  bottom: theme.spacing(3),
  right: theme.spacing(3)
}))

export { StyledFab, StyledLayout }
