import { createSlice } from '@reduxjs/toolkit';
import { IChatAssistantMessage } from '../types/chat-assistant';
import getAssistantResponse from '../query/chat-assistant/getAssistantResponse';

export interface ChatAssistantState {
  chatHistory: IChatAssistantMessage[];
}

const initialState: ChatAssistantState = {
  chatHistory: [],
};

const { actions, reducer } = createSlice({
  name: 'chatAssistant',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      const { payload } = action;

      if (payload.message) {
        state.chatHistory.push({ role: 'user', content: payload.message });
      }
    },
  },
  extraReducers: builder =>
    builder.addCase(getAssistantResponse.fulfilled, (state, action) => {
      state.chatHistory = [
        ...state.chatHistory,
        {
          role: 'assistant',
          content: action.payload,
        },
      ];
    }),
});

export const { addMessage } = actions;

export default reducer;
