import { styled } from "@mui/material/styles";

import IconButton from "@mui/material/IconButton";
import Typography, { TypographyProps } from "@mui/material/Typography";
// @ts-ignore
interface IExtendTypographyProps extends TypographyProps {
  component: string
}
const StyledCloseBtn = styled(IconButton)(({ theme }) => theme.unstable_sx({
  position: 'absolute',
  right: {
    md: 40
  },
  left: {
    xs: 0,
    md: 'auto'
  },
  top: '50%',
  transform: 'translateY(calc(-50% - 4px))'
}))

const StyledSubtitle = styled(Typography)<IExtendTypographyProps>(({ theme }) => theme.unstable_sx({
  fontSize: {
    xs: '.875rem',
    md: '1.125rem'
  },
  fontWeight: 'normal',
  lineHeight: 'normal'
}))

export { StyledCloseBtn, StyledSubtitle }
