import React, { FunctionComponent, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import debounce from 'lodash/debounce';

import Grid from '@mui/material/Unstable_Grid2';

import { outputQuery } from 'helpers/decorators';

import { useAppDispatch } from 'store/hooks';
import usersTeam from 'store/query/agency/usersTeam';

import CustomAutocomplete from '../../CustomFields/CustomAutocomplete';
import { BootstrapInput } from '../../CustomFields';
import { byFilterTeamStatus, LIMITS } from 'models/consts';

interface IFilterTeam {
  setPage: (id: number) => void;
}

const FilterTeam: FunctionComponent<IFilterTeam> = ({ setPage }) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const params: any = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    dispatch(usersTeam({ filter: params, limit: LIMITS.team, offset: 0 }))
      .unwrap()
      .then(() => setPage(0));
  }, [dispatch, searchParams, setPage]);

  const { control, getValues } = useFormContext();

  const onSearch = (searchTerm: any) => {
    const normalizedSearchParams = { ...searchTerm };
    for (const key in normalizedSearchParams) {
      if (!normalizedSearchParams[key] && normalizedSearchParams[key] !== false) {
        delete normalizedSearchParams[key];
      }
    }
    setSearchParams(normalizedSearchParams);
  };

  const debouncedSearch = debounce(onSearch, 500);

  const handleChange = (value: any, field: string) => {
    const { filterByEmailOrName, filterByEmail, filterByStatus, filterByRole, property, type } =
      getValues();
    const filter = {
      filterByEmailOrName,
      filterByEmail,
      filterByRole,
      filterByStatus,
      property,
      type,
      [field]: value,
    };

    debouncedSearch(outputQuery(filter));
  };

  const getDefaultStatusValue = () => {
    const filterValue = searchParams.get('filterByStatus');
    if (filterValue && byFilterTeamStatus?.length) {
      return byFilterTeamStatus.find(
        item => item.id === filterValue || item.id?.toString() === filterValue,
      );
    }
    return undefined;
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={3}>
        <Controller
          control={control}
          name="filterByEmailOrName"
          defaultValue={searchParams.get('filterByEmailOrName') || undefined}
          render={({ field: { ref, ...field } }) => (
            <BootstrapInput
              {...field}
              inputRef={ref}
              onChange={(event: any) => {
                handleChange(event.target.value, 'filterByEmailOrName');
                field.onChange(event);
              }}
              placeholder="Filter by Email or Name"
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={3}>
        <Controller
          control={control}
          defaultValue={getDefaultStatusValue}
          name="filterByStatus"
          render={({ field }) => (
            <CustomAutocomplete
              autocompleteProps={{
                multiple: undefined,
              }}
              field={field}
              options={byFilterTeamStatus}
              placeholder="Filter by Status"
              handleChange={value => handleChange(value.id, 'filterByStatus')}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default FilterTeam;
