import { createSlice } from '@reduxjs/toolkit';
import checkMatching from 'store/query/order/checkMatching';
import { ITimelineBasis } from 'models/inner-models';
import {ISharedLink} from "../types/order";

export interface OrderCreationState {
  timeLine: ITimelineBasis | null;
  matchingScore?: number;
  isLoading: boolean;
  isLoaded: boolean;
  sharedLinks?: ISharedLink[];
  isSearching: boolean;
}

const initialState: OrderCreationState = {
  timeLine: null,
  isLoading: false,
  isLoaded: false,
  sharedLinks: undefined,
  isSearching: false,
}

const { reducer } = createSlice({
  name: 'order-creation',
  initialState,
  reducers: {},
  extraReducers: (builder) => builder
    .addCase(checkMatching.pending, (state) => {
      state.matchingScore = undefined;
      state.isSearching = true;
    })
    .addCase(checkMatching.rejected, (state) => {
      state.isSearching = false;
    })
    .addCase(checkMatching.fulfilled, (state, action) => {
      const {meta, payload} = action;
      const { allHours, availabilityWeek, endDateOrder, flexible, startDateOrder } = meta.arg
      state.matchingScore = payload.matching;
      state.timeLine = {
        allHours,
        startDate: startDateOrder,
        endDate: endDateOrder,
        flexibleHours: flexible,
        availabilityWeek,
      }
      state.isSearching = false;
    })

});

export default reducer;
