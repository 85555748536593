import React, { FunctionComponent, useMemo } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import { StateMachineProvider } from "little-state-machine";
import { Capacitor } from '@capacitor/core';

import Box from "@mui/material/Box";

import {
  CustomerRegistrationStepper,
  ConsultantRegistrationStepper,
  AgencyRegistrationStepper,
  AgencyConsultantRegistrationStepper,
} from "models/steppers";

import { StyledContainer, StyledFluid, StyledRightSide, StyledRoot, StyledWrap } from './styled'

import Header from "components/Header/Header";
import Sidebar from "components/Sidebar";
import ROUTES from "router/constants";
import { useAppSelector } from 'store/hooks';
import { getBasicConsultantSelector } from 'store/selectors/getConsultantSelector';
import { IBasicConsultant } from 'store/types/consultant';
import { getMeSelector } from 'store/selectors/getUserSelector';

const FullscreenLayout: FunctionComponent = () => {
  const location = useLocation();
  const me = useAppSelector(getMeSelector);
  const consultant = useAppSelector(getBasicConsultantSelector(me?.id)) as IBasicConsultant;

const getMobilePlatform = Capacitor.getPlatform()


  const steps = useMemo(() => {
    switch (true) {
      case location.pathname?.includes(ROUTES.ORDER_CREATION):
        return CustomerRegistrationStepper;
      case location.pathname?.includes(ROUTES.AGENCY_CREATION):
        return AgencyRegistrationStepper;

      default:
        return consultant?.agencyId ? AgencyConsultantRegistrationStepper : ConsultantRegistrationStepper;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <StateMachineProvider>
      <Box 
        sx={{
          display: 'flex', 
          flexDirection: 'column', 
          height: '100%',
          ...(getMobilePlatform === 'android' && {
            position: 'fixed',
            width: '100%', 
            overflow: 'scroll'
          }),
        }}>
        <Header isCreationPage={true} />
        <StyledRoot>
          <StyledFluid>
            <StyledWrap>
              <StyledContainer>
                <Sidebar steps={steps} />
                <StyledRightSide>
                  <Outlet />
                </StyledRightSide>
              </StyledContainer>
            </StyledWrap>
          </StyledFluid>
        </StyledRoot>
      </Box>
    </StateMachineProvider>
  );
};

export default FullscreenLayout;
